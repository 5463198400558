import {useState} from 'react';
import ContentModal from '../../organisms/ContentModal';
import {useVenueReport} from '../../../contexts/VenueReportContext';

import StatArea, {DefaultStatsLebel} from '../../organisms/StatArea';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';

const ValueStr = styled.div`
  font-size: 60px;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 27px;
`;

export default function VenueReportDevices() {
  const venueReport = useVenueReport();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!venueReport.state.data) {
    return (
        <>
          <StatArea
              labelWithInfo={
                <LabelWithInfo
                    text={'ROOMS ACTIVE'}
                    infoPosition={InfoLinePosition.RightBottom}
                >
                  <div></div>
                </LabelWithInfo>
              }
              delta={null}
              button={null}
          >
            <ValueStr>
              <HappyNumberLabel text={'0'}/>
            </ValueStr>
            <DefaultStatsLebel text={'rooms/floors'}/>
          </StatArea>
        </>
    );
  }

  let data = venueReport.state.data.rooms || [];

  return (
      <>
        <StatArea
            labelWithInfo={
              <LabelWithInfo
                  text={'ROOMS ACTIVE'}
                  infoPosition={InfoLinePosition.RightBottom}
              >
                <div>
                  You can see the active rooms/floors that issued playlists in
                  the selected period. Tap 'See more' to view the list.
                </div>
              </LabelWithInfo>
            }
            delta={null}
            button={
              <button className="light-button" onClick={openModal}>
                See more
              </button>
            }
        >
          <ValueStr>
            <HappyNumberLabel text={data.length.toLocaleString('en-US')}/>
          </ValueStr>
          <DefaultStatsLebel
              text={data.length != 1 ? `rooms/floors` : `room/floor`}/>
        </StatArea>

        <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
          <div className="fixed-header">
            <h3>ROOMS ACTIVE</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                  <span>ROOM or FLOOR</span>
                </div>
                <div className="scroll-header-column last">
                  <div className="dummy-label">
                    {data.map((item, i) => {
                      return (
                          <div
                              key={i}
                              style={{
                                top: i * -1,
                              }}
                          >
                            {item.value}
                          </div>
                      );
                    })}
                  </div>
                  <span>TRACKS</span>
                </div>
              </div>
            </div>
          </div>

          <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
            <div
                className={`scroll-content ${
                    data.length ? 'has-items' : 'no-items'
                }`}
            >
              <table className="scroll-table">
                <tbody>
                {data.map((item, i) => {
                  if (item.room) {
                    let room = item.room;
                    return (
                        <tr key={i} className="tr">
                          <td className="right-border">
                            {room.name}
                          </td>
                          <td className="last right-border">{item.value}</td>
                        </tr>
                    );
                  } else if (item.device) {
                    let device = item.device;
                    return (
                        <tr key={i} className="tr">
                          <td className="right-border">
                            {device.floor_name ||
                                'NO NAME'} ({device.customer_id ||
                              ''})
                            {' / '}
                            {device.device_name}
                          </td>
                          <td className="last right-border">{item.value}</td>
                        </tr>
                    );
                  } else {
                    return (<tr key={i} className="tr">
                      <td className="right-border">

                      </td>
                      <td className="last right-border">{item.value}</td>
                    </tr>);
                  }
                })}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </ContentModal>
      </>
  );
}
