import ContentHeader from '../../organisms/ContentHeader';
import './style.scss';

export default function TermsOfUsePage() {
  return (
      <>
        <ContentHeader
            title={'Terms Of Use'}
            subtitle={''}
            rightTopElem={null}
            style={{
              marginBottom: 12,
              paddingBottom: 4,
            }}
        />
        <div className="static-page-content privacy-policy terms">
          <section>
            <p>
              This Terms of Use (“Agreement”) apply to KUVO dashboard
              service(“Services”). This Agreement is by and between <b>AlphaTheta
              Corporation a company registered in Japan whose company address is
              6F Yokohama i-Mark Place, 4-4-5 Minatomirai, Nishi-ku, Yokohama,
              Kanagawa 220-0012 Japan ("AlphaTheta")</b> and the organization or
              individual agreeing to these terms (“Recipient”) .This Agreement
              governs access to and use of the Services.:
              <br/>
              <br/>
              <ol class={'abc'}>
                <li>
                  <span>A.</span>
                  AlphaTheta owns and operates a service named KUVO whereby
                  AlphaTheta collects Data (as defined hereunder) as part of
                  KUVO’s service;
                </li>
                <li><span>B.</span>Recipient is in the business of distributing
                  copyright fee which it collects to appropriate rights holders;
                </li>
                <li><span>C.</span>AlphaTheta is willing to provide Data to
                  Recipient and Recipient desires to obtain the Data from
                  AlphaTheta in accordance with the terms and conditions herein
                  below;
                </li>
              </ol>
            </p>
            <p>
              NOW THEREFORE, in consideration of the mutual covenants and
              conditions contained herein the Parties hereby agree as follows:
            </p>

          </section>
          <section>
            <h3>Service and Fee.</h3>

            <p>
              <ol class={'num'}>
                <li>AlphaTheta will provide Recipient the Data as set forth
                  in Appendix 1 during the term of this Agreement subject to the
                  terms and conditions contained herein for Recipient to utilize
                  the Data in its distribution of copyright fee to rights
                  holders (the “Purpose”) in the country where Recipient
                  operates its business (the “Service”). AlphaTheta may at any
                  time discontinue the Service by providing Recipient thirty
                  (30) days notice of its intent to discontinue the Service.
                </li>
                <li>In consideration for the Service, Recipient shall pay
                  the fee (“Fee”) to AlphaTheta as set forth in Appendix 2 in
                  the manner designated by AlphaTheta. AlphaTheta may at any
                  time change the Fee by providing Recipient thirty (30) days
                  notice of such change. If Recipient does not agree to such
                  change in Fee, the Recipient may terminate this Agreement by
                  providing AlphaTheta thirty (30) days written notice
                  specifying the reason for termination.
                </li>
              </ol>
            </p>
          </section>
          <section>
            <h3>Data</h3>

            <p>
              <ol className={'num'}>
                <li>The Data provided under the Service consists solely of
                  the tracks being played through the system designated by
                  AlphaTheta for Service, and therefore may not include the data
                  of all of the tracks being played at a particular club.
                </li>
                <li>AlphaTheta may request the Recipient to return or
                  destroy part of the Data at any time during the term of this
                  Agreement and for whatever reason, and the Recipient shall
                  return or destroy the requested part of the Data.
                </li>
                <li>Recipient shall not contact clubs or DJs directly or
                  indirectly regarding the Data obtained through the Service
                  without AlphaTheta’s prior written permission. For the
                  avoidance of doubt Recipient may contact clubs or DJs matters
                  relating to the Recipients dealings with the club or DJ as
                  licensee or member of the Recipient.
                </li>
              </ol>
            </p>
          </section>
          <section>
            <h3>No warranty</h3>

            <p>
              <ol className={'num'}>
                <li>THE DATA IS PROVIDED “AS IS” AND ALPHATHETA OFFERS AND
                  RECIPIENT RECEIVES NO WARRANTIES OF ANY KIND, EXPRESS OR
                  IMPLIED, BY STATUTE, COMMUNICATION OR CONDUCT WITH RECIPIENT,
                  OR OTHERWISE. ALPHATHETA SPECIFICALLY DISCLAIMS ANY IMPLIED
                  WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE. WITHOUT
                  LIMITATION OF THE ABOVE, ALPHATHETA PROVIDES NO WARRANTY THAT
                  DATA IS ERROR-FREE OR WILL BE PROVIDED WITHOUT INTERRUPTION,
                  AND PROVIDES NO WARRANTY REGARDING ITS USE OR THE RESULTS
                  THEREFROM INCLUDING, WITHOUT LIMITATION, ITS CORRECTNESS,
                  ACCURACY OR RELIABILITY.
                </li>
                <li>
                  AlphaTheta shall not be responsible for any damages which
                  may result from Recipient’s use of the Data and/or the
                  Service, or discontinuance of the Service, and Recipient shall
                  be solely responsible for such damages.
                </li>
                <li>
                  Limitation of Liability. In no event will AlphaTheta or its
                  subsidiaries be liable in connection with this Agreement or
                  its subject matter, under any theory of liability, for any
                  indirect, incidental, special, consequential or punitive
                  damages, or damages for lost profits, revenue, business,
                  savings, data, use, or cost of substitute procurement, even if
                  advised of the possibility of such damages or if such damages
                  are foreseeable. In no event will AlphaTheta's liability for
                  all damages exceed the amounts actually paid by Recipient to
                  AlphaTheta or its subsidiaries for the Service. The parties
                  acknowledge that the liability limits and risk allocation in
                  this Agreement are reflected in the Service price and are
                  essential elements of the bargain between the parties, without
                  which AlphaTheta would not have provided the Service or
                  entered into this Agreement.
                </li>
              </ol>
            </p>
          </section>
          <section>
            <h3>Ownership</h3>

            <p>
              <ol className={'num'}>
                <li>AlphaTheta grants Recipient only the limited rights that are
                  reasonably necessary for Recipient to use the Services.
                </li>
                <li>
                  AlphaTheta or its licensor retains all right, title and
                  interest in and to all patent, copyright, trade mark, trade
                  secret and other intellectual property rights in the Services,
                  Beta Services and Documentation, and any derivative works
                  thereof. Recipient do not acquire any other rights, express or
                  implied, beyond the limited license set forth in this
                  Agreement.
                </li>
              </ol>
            </p>
          </section>
          <section>
            <h3>Indemnification</h3>

            <p>
              <ol className={'num'}>
                <li>Recipient agrees to defend, indemnify and hold harmless
                  AlphaTheta from and against any and all claims, losses,
                  liabilities, damages, settlements, expenses, and costs
                  (including, without limitation, reasonable attorney’s fees and
                  court
                  costs) arising out of or related to:
                  <br/><br/>
                  <ul>
                    <li>
                      <span>a)</span>Recipient’s breach of any of the terms
                      under this Agreement;
                    </li>
                    <li>
                      <span>b)</span>complaints, allegations, lawsuits, or
                      threat of law suits against AlphaTheta by DJs and/or
                      rights holders (including, but not limited to song writers
                      and composers) that they did not receive from Recipient
                      the copyright distribution which they should have received
                      by using the Data.

                    </li>
                  </ul>
                  provided that the amount of such indemnity is reduced to a
                  final judgment of a court of competent jurisdiction or a
                  settlement approved in writing by the Recipient (such
                  approval not to be unreasonably withheld). AlphaTheta
                  agrees to notify Recipient promptly of any such claim, and
                  to furnish Recipient with all information and documents
                  pertaining to such claim and Recipient may assume control
                  of the defense or settlement of such claim.
                </li>
              </ol>
            </p>
          </section>
          <section>
            <h3>Term and Termination</h3>
            <p>
              <ol className={'num'}>
                <li><span className={'subtitle'}>Term</span>
                  This Agreement shall become effective as of the Effective Date
                  and shall continue in full force and effect for one years
                  unless earlier terminated pursuant to this Section 1.1. This
                  Agreement will renew automatically for additional one-year
                  terms unless one party provides written notice of its intent
                  not to renew upon at least thirty (30) days before expiration
                  of then-current terms.
                </li>
                <li><span className={'subtitle'}>Termination for Cause</span>
                  <ol className={'num2'}>
                    <li>AlphaTheta may terminate this Agreement if Recipient
                      materially breaches this Agreement and fails to cure the
                      breach to AlphaTheta’s satisfaction within thirty (30)
                      days after receiving written notice.
                    </li>
                    <li>Either Party may terminate this Agreement if the other
                      Party (i) begins dissolution or liquidation proceedings,
                      or ceases to carry on its business, (ii) has a receiver or
                      similar officer appointed and not discharged within ninety
                      (90) days, (iii) makes an assignment for the benefit of
                      creditors, or another arrangement of similar import, (iv)
                      has proceedings under any bankruptcy or insolvency law
                      commenced against it, which are not discontinued within
                      ninety (90) days.
                    </li>
                  </ol>
                </li>
                <li><span
                    className={'subtitle'}>Termination for Convenience</span>

                  AlphaTheta may terminate this Agreement for convenience upon
                  thirty (30) days prior written notice to Recipient.
                </li>
                <li><span className={'subtitle'}>Effects of Termination</span>
                  <ol className={'num2'}>
                    <li>Upon termination of this Agreement, Recipient shall
                      cease to use the Data in any way, and at AlphaTheta’s
                      option, return or destroy downloaded Data (in case
                      AlphaTheta elects for destruction of Data, Recipient must
                      certify the destruction thereof in writing) within
                      fourteen (14) days of the termination. Notwithstanding the
                      foregoing, the Recipient may, by obtaining prior consent
                      of AlphaTheta, continue to use the Data for a period of
                      three (3) months post termination of this Agreement solely
                      for the Purpose in the case that the termination is caused
                      by AlphaTheta subject to Section 6.3.
                    </li>
                    <li>Sections 1.2 (Fees), 3 (No Warranty), 4
                      (Indemnification), 5.4 (Effects of Termination),
                      6(Confidentiality), 7 (Limitation of Liability) and 8
                      (General Provisions) will survive this Agreement’s
                      expiration or termination. Except as otherwise provided
                      herein, termination will not waive or release either
                      Party’s liability for breaching any obligations under this
                      Agreement. Neither Party will be liable for damages of any
                      kind solely for terminating this Agreement according to
                      its terms.
                    </li>
                  </ol>
                </li>
              </ol>
            </p>
          </section>
          <section>
            <h3>Confidentiality </h3>
            <p>
              <ol className={'num'}>
                <li>
                  <span className={'subtitle'}>Confidential Information</span>
                  Recipient acknowledges that as part of the Service, Recipient
                  receives Confidential Information from AlphaTheta.
                  “Confidential Information” shall mean any information
                  including, without limitation, the Data, technical and
                  economic information, commercialization, marketing and
                  research strategies, trade secrets and know-how, which is
                  confidential and proprietary in nature for AlphaTheta. The
                  Data contains the data set forth in Appendix 1 and contains
                  personally identifiable information.
                </li>
                <li>
                  <span className={'subtitle'}>Exclusions</span>
                  Confidential Information does not include information that (i)
                  becomes part of the public domain (other than through a breach
                  of this Agreement); (ii) was rightfully known by Recipient
                  prior to receipt from AlphaTheta; (iii) comes into Recipient’s
                  possession through lawful means from a third party with no
                  obligation to maintain its confidentiality; (iv) Recipient can
                  show was independently developed by it without use of or
                  reference to the Confidential Information. Recipient has the
                  burden of proof as to any such exception.
                </li>
                <li>
                  <span className={'subtitle'}>Restrictions on Use and Disclosure</span>
                  Recipient shall not disclose Confidential Information to any
                  third party or use it except for the Purpose. To avoid
                  disclosure of the Confidential Information, the Recipient
                  shall use at least the degree of care as it uses with respect
                  to protecting its own proprietary information of like
                  importance, but no less than the reasonable degree of care and
                  limit the dissemination thereof to only those of its employees
                  and officers who need access in order to achieve the Purpose
                  and who are bound by written confidentiality obligations at
                  least as restrictive as this Section 6. However, for the
                  personally identifiable information contained in the Data,
                  Recipient shall use its highest degree of care in avoiding
                  disclosure.
                </li>
                <li>
                  <span className={'subtitle'}>Compelled Disclosure</span>
                  If Recipient is compelled by order of a court, regulatory
                  agency or other governmental body to disclose any of
                  AlphaTheta’s Confidential Information, the Recipient shall
                  promptly notify the AlphaTheta and cooperate, at AlphaTheta’s
                  expense, in opposing or seeking to limit the order. If
                  AlphaTheta declines to oppose the order, or the effort is
                  ultimately unsuccessful, Recipient may disclose Confidential
                  Information only to the extent required to comply with the
                  order.
                </li>
                <li>
                  <span className={'subtitle'}>Injunctive Relief</span>
                  Recipient agrees that its breach of this Section 6 may result
                  in irreparable harm to AlphaTheta for which money damages
                  would be inadequate. Accordingly, AlphaTheta will be entitled
                  to seek immediate equitable and other provisional relief,
                  including without limitation specific performance of this
                  Agreement and a temporary restraining order and/or preliminary
                  and /or permanent injunction, as a remedy for such breach, in
                  addition to all other remedies available to such party at law
                  or in equity and without prejudice to any such other remedies.
                </li>
              </ol>
            </p>
          </section>

          <section>
            <h3>General Provisions</h3>
            <p>
              <ol className={'num'}>
                <li>
                  <span className={'subtitle'}>Relationship of Parties</span>
                  Nothing in this Agreement creates any relationship between the
                  Parties other than that of independent contractors. Neither
                  Party will hold itself out as an agent, subsidiary, or
                  affiliate of the other Party or has any authority to bind the
                  other Party to any third party obligations.
                </li>
                <li>
                  <span className={'subtitle'}>Entire Agreement and Modifications</span>
                  This Agreement constitutes the entire understanding between
                  the Parties with respect to its subject matter and supersedes
                  all prior and cotemporaneous oral and written agreements,
                  memoranda, understandings, undertakings, representations and
                  warranties. <br/>
                  <br/>
                  No alteration, modification, or amendment to this Agreement
                  shall be effective unless so altered, modified, or amended in
                  writing and signed by the duly authorized representative of
                  each Party.
                </li>
                <li>
                  <span className={'subtitle'}>Waiver</span>
                  No failure, delay or forbearance on the part of any Party in
                  exercising any right or power under this Agreement shall
                  operate as a waiver thereof and no waiver by any Party of any
                  breach of any term or condition in this Agreement by the other
                  Party shall constitute a continuing waiver or prejudice the
                  rights of the non-breaching Party in respect of any other or
                  subsequent breach of any term or condition in this Agreement.
                </li>
                <li>
                  <span className={'subtitle'}>Assignment</span>
                  Recipient may not assign or transfer the Agreement or any
                  rights or obligations under the Agreement without the written
                  consent of AlphaTheta. AlphaTheta may not assign the Agreement
                  without providing notice to Recipient, except AlphaTheta may
                  assign the Agreement or any rights or obligations under the
                  Agreement to an Affiliate or in connection with a merger,
                  acquisition, corporate reorganization, or sale of all or
                  substantially all of its assets without providing notice. Any
                  other attempt to transfer or assign is void.
                </li>
                <li>
                  <span className={'subtitle'}>Force Majeure</span>
                  Neither Party will be considered in breach or default under
                  this Agreement for any delay or failure to perform any or all
                  of its obligations resulting from extraordinary causes beyond
                  that party’s control, such as acts of God, natural disasters,
                  war or civil disruption, terrorism, public utility failures,
                  operation of law, fire, explosion, any strike, lockout or
                  other industrial action.
                </li>
                <li>
                  <span className={'subtitle'}>Severability</span>
                  Any provision of this Agreement held unenforceable or illegal
                  by a court of competent jurisdiction will be severable from
                  the remaining Agreement, which will continue in full force and
                  effect.
                </li>
                <li>
                  <span className={'subtitle'}>Arbitration</span>
                  Any dispute, controversy, or difference which may arise
                  between the Parties out of or in relation to or in connection
                  with this Agreement, or any breach hereof, shall be settled
                  amicably between the Parties, but in the event no amicable
                  settlement can be reached, shall be finally settled by
                  arbitration in Tokyo, Japan in accordance with the Commercial
                  Arbitration Rules of the Japan Commercial Arbitration
                  Association. The award of arbitration shall be final and
                  binding upon both parties and shall have the reasoning
                  therein.
                </li>
                <li>
                  <span className={'subtitle'}>Governing Law</span>
                  This Agreement shall be governed and construed by and under
                  the laws of Japan excluding the conflict of laws.
                </li>
              </ol>
            </p>
          </section>
        </div>
      </>
  )
      ;
}
