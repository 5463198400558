import {useState, useMemo} from 'react';
import {getSummaryLocations} from '../../../api';

import clone from 'clone';
import {useSummaryReport} from '../../../contexts/SummaryReportContext';
import styled from 'styled-components';

import StatArea, {DefaultStatsLebel} from '../../organisms/StatArea';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import ContentModal from '../../organisms/ContentModal';
import SimpleBar from 'simplebar-react';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';

const ValueStr = styled.div`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;

  b {
    font-size: 80px;
    line-height: 80px;
  }
`;

export default function SummaryReportLocation() {
  const summaryReport = useSummaryReport();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemsData, setItemsData] = useState({
    query: {
      type: 'monthly',
      year: 0,
      month: 0,
    },
    items: [],
  });
  const [detailFetched, setDetailFetched] = useState(false);

  const [no_data, last, current, denominator, diff] = useMemo(() => {
    if (!summaryReport.state.data) {
      return [true, 0, 0, 0, 0];
    }

    let data = summaryReport.state.data.location;

    let last = (data.previous && data.previous.numerator) || 0;
    let current = (data.current && data.current.numerator) || 0;
    let denominator = (data.current && data.current.denominator) || 0;

    let diff = 0;
    if (last != 0) {
      diff = Math.round((current / last - 1.0) * 100);
    }

    return [false, last, current, denominator, diff];
  }, [summaryReport.state]);

  function openModal() {
    setIsOpen(true);

    let currentQuery = summaryReport.state.fetched_query;
    if (
        itemsData.query.year != currentQuery.year ||
        itemsData.query.month != currentQuery.month
    ) {
      setDetailFetched(false);
      (async () => {
        try {
          let items = await getSummaryLocations(currentQuery);
          setItemsData({
            query: clone(currentQuery),
            items: items,
          });
          console.log(items);
        } catch (e) {
          console.error(e);
        }
        setDetailFetched(true);
      })();
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (no_data) {
    return (
        <>
          <StatArea
              labelWithInfo={
                <LabelWithInfo
                    text={'LOCATION'}
                    infoPosition={InfoLinePosition.RightTop}
                >
                  <div></div>
                </LabelWithInfo>
              }
              delta={0}
              button={null}
          >
            <ValueStr>
              <b>
                <HappyNumberLabel text={'0'}/>
              </b>{' '}
              / <HappyNumberLabel text={'0'}/>
            </ValueStr>

            <DefaultStatsLebel text={'AREAS'}/>
          </StatArea>
        </>
    );
  }

  console.log(`Location ${current} / ${denominator}`);

  return (
      <>
        <StatArea
            labelWithInfo={
              <LabelWithInfo
                  text={'LOCATION'}
                  infoPosition={InfoLinePosition.RightTop}
              >
                <div>
                  You can see the number of active areas of your submitted
                  licensed
                  venues. Tap ‘See more’ to view the area information.
                </div>
              </LabelWithInfo>
            }
            delta={diff}
            button={
              <button className="light-button" onClick={openModal}>
                See more
              </button>
            }
        >
          <ValueStr>
            <b>
              <HappyNumberLabel text={current.toLocaleString('en-US')}/>
            </b>{' '}
            / <HappyNumberLabel text={denominator.toLocaleString('en-US')}/>
          </ValueStr>

          <DefaultStatsLebel text={window.getUnitLabel('areas', current)}/>
        </StatArea>
        <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
          <div className="fixed-header">
            <h3>TOP 10 AREAS</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                  <span>LOCATION</span>
                </div>
              </div>
            </div>
          </div>
          <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
            <div
                className={`scroll-content ${
                    !detailFetched || itemsData.items.length
                        ? 'has-items'
                        : 'no-items'
                }`}
            >
              <table className="scroll-table">
                <tbody>
                {itemsData.items.map((item, i) => {
                  let areas = [];
                  if (item.area_name) {
                    areas.push(item.area_name);
                  }
                  if (item.country_name) {
                    areas.push(item.country_name);
                  }
                  return (
                      <tr key={i} className="tr">
                        <th>{(i + 1 + '').padStart(2, '0')}</th>
                        <td>{areas.join(', ')}</td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </ContentModal>
      </>
  );
}
