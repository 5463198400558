import EventTargetPolyfill from './EventTarget';
import {useEffect, useRef} from 'react';

let instance = null;

//  this.dispatchEvent(new Event("login"));

export default class AnimationManager extends EventTargetPolyfill {
  static getSharedInstance() {
    if (!instance) {
      instance = new AnimationManager();
      instance.init();
    }
    return instance;
  }

  constructor() {
    super();
    this.targets = new Map();
    this.cursor = 0;
    this.t0 = 0;
    this.requestId = 0;
  }

  init() {
    this.targets = new Map();

    this.t0 = Date.now();
    //this.requestId = requestAnimationFrame((timestamp) => {
    //  this.animate(timestamp);
    //});
  }

  animate(timestamp) {
    let now = Date.now();
    let delta = now - this.t0;
    this.t0 = now;
    for (let callback of this.targets.values()) {
      callback(timestamp, delta);
    }
    if (this.targets.size) {
      this.requestId = requestAnimationFrame((timestamp) => {
        this.animate(timestamp);
      });
    } else {
      this.requestId = 0;
      console.log('All animation ended');
    }
  }

  add(callback) {
    this.cursor++;
    this.targets.set(this.cursor, callback);
    //console.log(`Add: ${this.cursor}`);
    if (!this.requestId) {
      this.requestId = requestAnimationFrame((timestamp) => {
        this.animate(timestamp);
      });
    }
    return this.cursor;
  }

  remove(id) {
    this.targets.delete(id);
  }
}

export const useAnimationFrame = (isRunning, callback = () => {}) => {
  let manager = AnimationManager.getSharedInstance();

  const reqIdRef = useRef(0);
  useEffect(() => {
    if (reqIdRef.current) {
      manager.remove(reqIdRef.current);
      reqIdRef.current = 0;
    }
    if (isRunning && callback) {
      reqIdRef.current = manager.add(callback);
    }
    return () => {
      if (reqIdRef.current) {
        manager.remove(reqIdRef.current);
        reqIdRef.current = 0;
      }
    };
  }, [isRunning, callback]);
};
