import {useState, useEffect} from 'react';
import styled from 'styled-components';

import {useNavigate, useLocation, Navigate, Link} from 'react-router-dom';
import {useAuth} from '../../../contexts/UserContext';
import SvgLogo from '../../organisms/SideNav/SvgLogo';
import './style.scss';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';

const Page = styled.div`
  background-color: #f8f8f8;
  height: 100vh;
  overflow: auto;
`;

const LoginWrap = styled.div`
  width: 480px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 200px;
`;

const InputField = styled.input`
  display: block;
  width: 100%;
  font-size: 18px;
  padding: 8px 15px !important;
  background-color: #fff;
  border-radius: 4px;
`;

const ErrorMessage = styled.div`
  border: 6px solid #cb0e0e;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  padding: 20px 25px;
  font-weight: 500;
  margin-bottom: 60px;

  a {
    color: #cb0e0e;
    text-decoration: underline;
  }
`;

const ForgotLinkArea = styled.div`
  margin-top: 14px;
  text-align: right;
  height: 40px;

  a {
    color: #525252;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
  }
`;

const KeepMeLogin = styled.div`
  text-align: center;
  margin-top: 25px;

  input {
    display: none;

    &:checked {
      & + label {
        &:before {
        }
      }
    }
  }

  label {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin-left: 7px;
    padding-left: 28px;

    &:before {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg id='長方形_167' data-name='長方形 167' fill='%23fff' stroke='%23d9d9d9' stroke-width='1'%3E%3Crect width='20' height='20' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='19' height='19' fill='none'/%3E%3C/g%3E%3C/svg%3E%0A");
      display: block;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
`;

const DontHaveOne = styled.div`
  width: 390px;
  border: 1px solid #d9d9d9;
  margin: 65px auto 0;
  text-align: center;
  padding: 20px 0;

  p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  a {
    color: #000000;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
  }
`;

const FooterLinkArea = styled.div`
  margin-top: 80px;
  display: flex;
  gap: 20px;
  padding-top: 20px;
  border-top: 1px solid rgb(82, 82, 82);
  align-items: center;

  a {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;

    &:last-child {
      margin-left: auto;
    }
  }
`;

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [errorElm, setErrorElm] = useState('');
  const [errorFieldSet, setErrorFieldSet] = useState(new Set());

  let from =
      (location.state && location.state.from && location.state.from.pathname) ||
      '/';

  let defaultValues = (location.state && location.state.initial_params) || {
    cmo_code: '',
    email: '',
  };
  let defaultError = (location.state && location.state.error) || null;
  console.log(defaultValues, defaultError);

  useEffect(() => {
    document.title = 'Login | DASHBOARD';
    if (defaultError) {
      setErrorByCode(defaultError);

      try {
        let st = window.history.state;
        delete st.usr.error;
        window.history.replaceState(st, document.title);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  if (auth.isLoggedIn()) {
    return <Navigate to={from} state={{from: location}} replace={true}/>;
  }

  function setErrorByCode(code) {
    switch (code) {
      default:
        setErrorElm(<span>Unknown error. Please retry later.</span>);
        break;
      case 401023: // パターンD
        setErrorElm(
            <span>
            This ID is not valid.
              <br/>
              Please try again with the correct KUVO ID.
          </span>,
        );
        setErrorFieldSet(new Set(['cmo_code']));
        break;
      case 401022: // パターンA
        setErrorElm(
            <span>
            Invalid login or password.
          </span>,
        );
        setErrorFieldSet(new Set(['email', 'password']));

        break;
      case 401025: // パターンC

        setErrorElm(
            <span>
            Your Pioneer DJ account is not available for this service. Please enter a valid e-mail and password.
          </span>,
        );
        setErrorFieldSet(new Set(['email', 'password']));

        break;

      case 401021: // パターンB
      case 401024: // パターンE
      case 401026: // パターンF

        setErrorElm(
            <span>
            Your Pioneer DJ account is not available for this service. Please
            log in with a valid account.
            <br/>
            Do you not have Pioneer DJ account with a valid e-mail address yet?{' '}
              <a
                  href="https://www.pioneerdj.com/en/account/register/"
                  target="_blank"
                  rel="noopener"
              >
              Create a new one.
            </a>
          </span>,
        );
        setErrorFieldSet(new Set(['email']));
        break;
      case 401031:
        setErrorElm(
            <span>
            Your invitation is no longer valid.
            <br/>
            Please contact your authorizer to have a new invitation.
          </span>,
        );
        break;
      case 401032:
        setErrorElm(
            <span>
            No Pioneer DJ account.
            <br/>
            Please create Pioneer DJ account to join your team. <br/>
            <a
                href="https://www.pioneerdj.com/en/account/register/"
                target="_blank"
                rel="noopener"
            >
              Sign up
            </a>
          </span>,
        );
        break;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let cmo_code = formData.get('cmo_code');
    let email = formData.get('email');
    let password = formData.get('password');
    let keep = !!formData.get('keep');

    auth.signin(cmo_code, email, password, keep, (err) => {
      setErrorElm('');
      setErrorFieldSet(new Set());
      if (err) {
        const status = (err.response && err.response.status) || 500;
        const code =
            (err.response && err.response.data && err.response.data.code) ||
            500;

        setErrorByCode(code);

        console.error(err.response, status, code);
      } else {
        //  navigate(from, { replace: true });
      }
    });
  }

  return (
      <Page>
        <LoginWrap>
          <SvgLogo
              style={{
                top: 0,
                display: 'block',
                margin: '0 auto 80',
                height: 91,
                width: 215,
              }}
          />

          {errorElm ? <ErrorMessage>{errorElm}</ErrorMessage> : ''}

          <form onSubmit={handleSubmit}>
            <LabelWithInfo
                text={'KUVO ID'}
                infoPosition={InfoLinePosition.RightTop}
                labelclassname="login-field-label"
                style={{
                  marginBottom: 8,
                }}
            >
              <div>
                KUVO ID is a unique five-digit code per organization to log in
                to
                the dashboard. The code is issued and sent from the AlphaTheta
                rep.
              </div>
            </LabelWithInfo>

            <InputField
                name="cmo_code"
                type="text"
                required
                autoComplete="off"
                defaultValue={defaultValues.cmo_code || ''}
                className={errorFieldSet.has('cmo_code') ? 'error' : 'normal'}
            />

            <LabelWithInfo
                text={'Pioneer DJ account'}
                infoPosition={InfoLinePosition.RightTop}
                labelclassname="login-field-label"
                style={{
                  marginTop: 50,
                  marginBottom: 8,
                }}
            >
              <div>
                Pioneer DJ account for this service is only valid with a
                pre-registered e-mail address. Please enter your Pioneer DJ
                account
                with the registered e-mail address.
              </div>
            </LabelWithInfo>

            <InputField
                name="email"
                type="email"
                className={errorFieldSet.has('email') ? 'error' : 'normal'}
                required
                defaultValue={defaultValues.email || ''}
                autoComplete="email"
            />

            <LabelWithInfo
                text={'Password'}
                infoPosition={InfoLinePosition.RightTop}
                labelclassname="login-field-label"
                style={{
                  marginTop: 20,
                  marginBottom: 8,
                }}
            >
              <div>
                Please enter your Pioneer DJ account password with the
                registered
                e-mail address.
              </div>
            </LabelWithInfo>

            <InputField
                name="password"
                type="password"
                className={errorFieldSet.has('password') ? 'error' : 'normal'}
                required
                autoComplete="current-password"
            />

            <ForgotLinkArea>
              <a
                  href="https://www.pioneerdj.com/en/account/login/"
                  target="_blank"
                  rel="noopener"
              >
                *Forgot your password?
              </a>
            </ForgotLinkArea>

            <input
                type="submit"
                className="dark-button"
                value="LOG IN"
                style={{
                  width: 200,
                  textAlign: 'center',
                  display: 'block',
                  margin: '21px  auto 0',
                }}
            />
            <KeepMeLogin>
              <input
                  id="keep_me_logged_in"
                  name="keep"
                  type="checkbox"
                  defaultChecked
              />
              <label htmlFor="keep_me_logged_in">Keep me logged in</label>
            </KeepMeLogin>

            <DontHaveOne>
              <p>Don't have one?</p>
              <a
                  href="https://www.pioneerdj.com/en/account/register/"
                  target="_blank"
                  rel="noopener"
              >
                Create Pioneer DJ Account.
              </a>
            </DontHaveOne>
            <FooterLinkArea>
              <a
                  href="https://www.pioneerdj.com/company/privacy-policy/"
                  target="_blank"
                  rel="noopener"
              >
                Privacy policy
              </a>
              <Link to="/terms-of-use/">Terms of use</Link>
              <a href="https://alphatheta.com/" target="_blank" rel="noopener">
                &copy; AlphaTheta Corporation
              </a>
            </FooterLinkArea>
          </form>
        </LoginWrap>
      </Page>
  );
}
