import {useRef, useEffect} from 'react';
import styled from 'styled-components';

const HeaderWrap = styled.div`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 19px;
  padding-bottom: 20px;
  padding-top: 35px;
  margin-bottom: 42px;

  @media print {
    border-left: none;
    margin-bottom: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 0px;
  }
`;

const TopArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
`;

const TitlesArea = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
`;

const TitleArea = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-right: 17px;
`;

const SubTitleArea = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
`;

const SubTitleInnerArea = styled.div``;

const RightTopArea = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 20px;

  @media print {
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
  color: #525252;
`;

const SubTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  color: #525252;
  letter-spacing: 0.06em;
`;

export default function ContentHeader({
  title = '',
  subtitle = '',
  rightTopElem = '',
  children,
  ...props
}) {
  const subTitle = useRef();
  const subTitleArea = useRef();
  const subTitleInnterArea = useRef();

  useEffect(() => {
    const cb = async () => {
      if (subTitle.current) {
        subTitle.current.style.fontSize = '30px';

        let f = 30;
        do {
          let st = subTitle.current.clientHeight;
          //          console.log(st);
          if (st > 45) {
            f -= 0.5;
            subTitle.current.style.fontSize = f + 'px';

            continue;
          }
          break;
        } while (f > 5);
      }
    };
    window.addEventListener('resize', cb);
    cb();
    return () => {
      window.removeEventListener('resize', cb);
    };
  }, [subtitle]);

  return (
      <HeaderWrap {...props}>
        <TopArea>
          <TitlesArea>
            <TitleArea>
              {
                title ? (<Title>{title}</Title>) : (<Title>&nbsp;</Title>)
              }
            </TitleArea>
            <SubTitleArea ref={subTitleArea}>
              <SubTitleInnerArea ref={subTitleInnterArea}>
                <SubTitle ref={subTitle}>{subtitle}</SubTitle>
              </SubTitleInnerArea>
            </SubTitleArea>
          </TitlesArea>
          <RightTopArea>{rightTopElem}</RightTopArea>
        </TopArea>
        <div>{children}</div>
      </HeaderWrap>
  );
}
