import * as React from 'react';

function SvgDlbtn(props) {
  return (
      <svg
          id="dlbtn_svg__\u30EC\u30A4\u30E4\u30FC_1"
          xmlns="http://www.w3.org/2000/svg"
          x={0}
          y={0}
          viewBox="0 0 20 20"
          xmlSpace="preserve"
          {...props}
      >
        <style>{'.dlbtn_svg__st0{fill:#525252}'}</style>
        <path
            className="dlbtn_svg__st0"
            d="M13.21 1.5H2.5v17h14.86V5.84L13.21 1.5zm-.18 1.26l3.17 3.32h-3.17V2.76zM3.5 17.5v-15h8.53v4.58h4.33V17.5H3.5z"
        />
        <path
            className="dlbtn_svg__st0"
            d="M5.91 7.12h4.58v1H5.91zM5.91 9.65h8.13v1H5.91zM5.91 12.18h8.13v1H5.91z"
        />
      </svg>
  );
}

export default SvgDlbtn;
