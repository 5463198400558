import {
  Link,
  useMatch,
  useResolvedPath,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import {useAuth} from '../../../contexts/UserContext';
import SvgLogo from './SvgLogo';
import './sidenav.scss';
import DBDPullDown from '../../atoms/DBDPullDown';

const SideNavWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 190px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  @media print {
    display: none;
  }
`;

const TopArea = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  .DBDPullDown {
    .dropdown {
      .dropdown-toggle {
        height: 30px !important;
        line-height: 30px !important;
        padding: 0 22px 0 18px !important;

        &::after {
          top: 11px;
        }
      }
    }
  }
`;

const BottomArea = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 80px;
`;

const LogoArea = styled.div`
  height: 121px;
`;

const SideHR = styled.div`
  width: 120px;
  height: 1px;
  margin: 0 auto;
  background-color: #dfdfdf;
`;

const PhotoArea = styled.a`
  width: 112px;
  height: 112px;
  margin: 20px auto 20px;
  position: relative;
  display: block;
`;

const PhotoFront = styled.div`
  position: relative;
  z-index: 2;
  width: 112px;
  height: 112px;
  background-image: ${({url}) => (url ? 'url(' + url + ')' : 'none')};
  background-size: cover;
  border-radius: 50%;
`;

const TierName = styled.div`
  height: 21px;
  background-color: #525252;
  display: inline-block;
  color: #fff;
  line-height: 21px;
  padding: 0 5px;
  border-radius: 2px;
  font-size: 10px;
  font-weight: bold;
  margin: 20px auto 15px;

  &.tier2 {
    background-color: #017EE1;
  }
`;

const UserName = styled.div`
  margin-top: 20px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
`;

const CMOName = styled.div`
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 20px;
`;

const PermissionLevel = styled.div`
  margin-top: 8px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 20px;
`;

const BigNavLinks = styled.div`
  width: 100%;
  text-align: left;
  margin-top: 20px;
`;

const SmallNavLinks = styled.div`
  width: 100%;
  text-align: left;
  padding-left: 36px;
`;

const DataOptionLabel = styled.div`
  margin-top: 27px;
  padding: 0 20px;
  font-size: 10px;
  font-weight: bold;
  line-height: 13px;
  margin-bottom: 5px;
  opacity: 1;
`;

function SideNavProfilieArea() {
  let auth = useAuth();

  if (!auth.isLoggedIn() || !auth.state.user) {
    return '';
  }

  const user = auth.state.user;
  let lastName = user.last_name && user.last_name[0];
  if (lastName) {
    lastName += '.';
  }

  return (
      <>
        <TierName className={`tier${user.cmo.tier}`}>
          {user.cmo.tier_str}
        </TierName>
        <CMOName>{user.cmo.name}</CMOName>
        <SideHR/>
        <PhotoArea
            className={
              user.image_url
                  ? 'sidenav-image-area has-image'
                  : 'sidenav-image-area no-image'
            }
            href="https://www.pioneerdj.com/en-us/account/profile/"
            target="_blank"
            rel="noopener"
        >
          {user.image_url ? (
              <PhotoFront url={user.image_url} className="photo"/>
          ) : (
              ''
          )}
        </PhotoArea>
        <UserName>
          {user.first_name} {lastName}
        </UserName>
        <PermissionLevel>{user.permission_level_str}</PermissionLevel>
      </>
  );
}

function LogOutLink() {
  let auth = useAuth();

  const onClickHandler = (e) => {
    e.preventDefault();
    auth.signout();
    setTimeout(() => {
      if (window.location.pathname != window.PUBLIC_URL + '/login') {
        window.location.href = window.PUBLIC_URL + '/login';
      }
    }, 300);
    return false;
  };

  return (
      <div style={{marginTop: 50}}>
        <a href="#" onClick={onClickHandler} className={'light-button sidenav'}
        >
          <span>LOG OUT</span>
        </a>
      </div>
  );
}

function CustomLink({children, to, disabled = false, isCenter = false}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({path: resolved.pathname, end: false});

  if (disabled) {
    return <div>{children}</div>;
  }

  return (
      <div style={{
        textAlign: isCenter ? 'center' : 'left',
      }}>
        <Link className={match ? 'active-link' : 'link'} style={{
          textDecoration: 'none',
        }} to={to}>
          {children}
        </Link>
      </div>
  );
}

export default function SideNav() {
  let auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  let isLoggedIn = auth.isLoggedIn() && !!auth.state.user;

  return (
      <SideNavWrapper className="side-nav">
        <TopArea>
          <LogoArea>
            <Link to={isLoggedIn ? '/' : '/login'}>
              <SvgLogo/>
            </Link>
          </LogoArea>
          <SideHR/>

          {isLoggedIn ? (
              <>
                <SideNavProfilieArea/>
                <SideHR/>

                <BigNavLinks>
                  <CustomLink to="/" isCenter={true}>
                    <span className="bignav sidenav">REPORT</span>
                  </CustomLink>
                </BigNavLinks>

                <DataOptionLabel>DATA OPTION</DataOptionLabel>
                <DBDPullDown
                    value={auth.getDataType()}
                    onChange={(v) => {
                      if (auth.getDataType() != v) {
                        auth.changeDataType(v);
                      }
                    }}
                    items={[
                      {
                        value: 'raw',
                        label: 'RAW',
                      },
                      {
                        value: 'cleansed',
                        label: 'CLEANSED',
                        disabled: !auth.isPremiumCMO(),
                      },
                      {
                        value: 'enriched',
                        label: 'ENRICHED',
                        disabled: !auth.isPremiumCMO(),
                      },
                    ]}
                    style={{
                      marginBottom: 27,
                    }}
                ></DBDPullDown>


                <SideHR/>


                {isLoggedIn ? (
                    <BigNavLinks>
                      <CustomLink to="/settings/" isCenter={true}>
                        <span className="bignav sidenav">SETTINGS</span>
                      </CustomLink>
                    </BigNavLinks>
                ) : (
                    ''
                )}
              </>
          ) : (
              ''
          )}
        </TopArea>
        <BottomArea>
          <SmallNavLinks>

            <CustomLink to="/faq/">
              <span className="smallnav">FAQ</span>
            </CustomLink>

            <div>
              <a
                  href="https://www.pioneerdj.com/company/privacy-policy/"
                  target="_blank"
                  rel="noopener"
                  className="link"
              >
                <span className="smallnav">PRIVACY POLICY</span>
              </a>
            </div>

            <CustomLink to="/terms-of-use/">
              <span className="smallnav">TERMS OF USE</span>
            </CustomLink>

            {isLoggedIn ? (
                <CustomLink to="/contact/">
                  <span className="smallnav">CONTACT</span>
                </CustomLink>
            ) : (
                ''
            )}

            {isLoggedIn ? <LogOutLink/> : ''}

            <div style={{marginTop: 101}}>
              <a href="https://alphatheta.com/" target="_blank" rel="noopener">
                <span className="smallestnav">&copy; AlphaTheta Corporation</span>
              </a>
            </div>
          </SmallNavLinks>
        </BottomArea>
      </SideNavWrapper>
  );
}
