import {useState} from 'react';
import './style.scss';
import ContentModal from '../ContentModal';
import {Link} from 'react-router-dom';

export default function NeedLicenseButton() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
      <div className="NeedLicenseButton">
        <button className="light-button open-button" onClick={openModal}>
          See what's hidden
        </button>

        <ContentModal
            closeModal={closeModal}
            isOpen={modalIsOpen}
            needlicense={1}
        >
          <div className="normal-header">
            <h3>HOW CAN WE SEE MORE DATA?</h3>
          </div>
          <div className="thumbnails">
            <div className="thumbnail t1"></div>
            <div className="thumbnail t2"></div>
          </div>

          <div className="description">
            Would you like to track down more data? Data Dashboard will filter
            your licensed venues in the secure server and provide further
            analytics such as SUMMARY PER VENUE, ACTIVE VENUES, OFFLINE DEVICES.
          </div>
          <div className="actions">
            <Link to={'/contact'} className="dark-button">
              CONTACT US
            </Link>
            <p>to know more details or try the feature.</p>
          </div>
        </ContentModal>
      </div>
  );
}
