import React, {createContext, useEffect, useReducer, useContext} from 'react';
import {getVenueSummary} from '../../api';
import clone from 'clone';
import moment from 'moment';
import {Link, useNavigate} from 'react-router-dom';
import SvgLogo from '../../components/organisms/SideNav/SvgLogo';
import {useAuth} from '../UserContext';

const VenueReportContext = createContext();

const initialState = {
  club_id: 0,
  query: {
    type: 'monthly',
    year: 0,
    month: 0,
    data: 'raw',
  },
  data: null,
  fetched_query: null,
  permission: true,
};

function reducer(state, action) {
  console.log(state, action);
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        club_id: action.club_id,
        query: {
          ...state.query,
          ...action.query,
        },
        data: null,
        permission: true,
      };
    }
    case 'set_403': {
      const newState = {
        ...state,
        permission: false,
      };
      return newState;
    }
    case 'set_summary_data': {
      const newState = {
        ...state,
        data: action.data,
        fetched_query: action.query,
        permission: true,
      };
      if (
          newState.data &&
          newState.data.offline_rooms &&
          newState.data.offline_rooms.items &&
          newState.data.offline_rooms.items.length
      ) {
        newState.data.offline_rooms.items.reverse();

        let now = Date.now();
        let day = 1000 * 3600 * 24;
        let year = day * 365;
        newState.data.offline_rooms.items.forEach((item) => {
          let m = moment(item.value);
          let t = m.toDate().getTime();
          let d = now - t;
          if (t < 1) {
            item.text = 'Never';
          } else {
            item.text = m.format('YYYY/MM/DD');
          }
        });
      }
      return newState;
    }
    default:
      throw new Error(`${action.type} is not defined.`);
  }
}

export const VenueReportContextProvider = ({api, children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  let doQuery = async (club_id, query) => {

    if (!query.data) {
      query.data = 'raw';
    }

    if (
        state.club_id == club_id &&
        state.fetched_query &&
        state.data &&
        state.fetched_query.year == query.year &&
        state.fetched_query.month == query.month &&
        state.fetched_query.data == query.data
    ) {
      return;
    }

    dispatch({
      type: 'init',
      club_id,
      query,
    });

    try {
      const data = await getVenueSummary(club_id, query);

      if (data?.room_graphs) {
        if (data.room_graphs.hours) {
          data.room_graphs.rooms = data.room_graphs.hours.map(r => {
            switch (r.type) {
              default:
              case 'total':
                return {
                  type: 'total',
                  name: 'TOTAL',
                  detail_name: 'TOTAL',
                  id: 0,
                };
              case 'room':
                return {
                  type: 'room',
                  name: r.room.name,
                  room: r.room,
                  detail_name: r.room.name,
                  id: r.id,
                };
              case 'device':
                return {
                  type: 'device',
                  name: r.device.floor_name || `${r.device.customer_id}`,
                  detail_name: `${r.device.name ||
                  'NO NAME'} (${r.device.customer_id}) / ${r.device.device_name}`,
                  device: r.device,
                  id: r.id,
                };
            }
          });
        }
      }

      if (data && data.graphs) {
        const keys = ['hours', 'tracks'];
        for (let key of keys) {
          let gdata = data.graphs[key];
          if (!gdata) {
            gdata = [];
            data.graphs[key] = gdata;
          }
          let total = {
            device_id: 0,
            customer_id: null,
            device_name: null,
            name: 'TOTAL',
            values: [],
          };
          let dateValueMap = new Map();
          gdata.forEach((d) => {
            let values = d.values || [];
            values.forEach((v) => {
              let entry = dateValueMap.get(v.date);
              if (!entry) {
                entry = {
                  date: v.date,
                  value: 0,
                };
                dateValueMap.set(v.date, entry);
              }
              entry.value += v.value;
            });
          });
          total.values = [...dateValueMap.values()];
          gdata.unshift(total);
        }
        if (data.graphs.hours) {
          data.graphs.devices = data.graphs.hours.map((d) => {
            return {
              device_id: d.device_id,
              customer_id: d.customer_id,
              device_name: d.device_name,
              name: d.name,
            };
          });
        }
      }

      dispatch({
        type: 'set_summary_data',
        data,
        query,
      });
    } catch (e) {
      if (e && e.response && e.response.status == 403) {
        dispatch({
          type: 'set_403',
        });
      }
      console.error(e);
    }
  };

  const value = {state, dispatch, doQuery};

  return (
      <VenueReportContext.Provider value={value}>
        {children}
      </VenueReportContext.Provider>
  );
};

export const useVenueReport = () => {
  return useContext(VenueReportContext);
};

export const RequirePermission = ({children}) => {
  let venueReport = useVenueReport();
  const navigate = useNavigate();
  const auth = useAuth();

  const onLink = (e) => {
    e.preventDefault();
    //document.getElementById("root").classList.remove("state-403");
    navigate('/');
    return false;
  };
  if (!auth.isPremiumCMO() || !venueReport.state.permission) {
    //document.getElementById("root").classList.add("state-403");
    return (
        <div className="forbidden-error">
          <div className="logo">
            <Link to="/">
              <SvgLogo/>
            </Link>
          </div>
          <div className="description">
            <h1>You don’t have permission to access this page.</h1>
            <p>Your account is not authorized to view this content.</p>
            <button className="light-button" onClick={onLink}>
              Go back Top
            </button>
          </div>
        </div>
    );
  }
  //document.getElementById("root").classList.remove("state-403");

  return children;
};

export default VenueReportContext;
