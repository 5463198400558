import React, {useState, useEffect} from 'react';

import './style.scss';

export const InfoLinePosition = {
  RightTop: 'RightTop',
  RightBottom: 'RightBottom',
};

export default function LabelWithInfo({
  text = '',
  labelclassname = '',
  children,
  infoPosition = InfoLinePosition.RightBottom,
  inline = false,
  ...props
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  useEffect(() => {
    if (!referenceElement) {
      return;
    }
    const show = () => {
      referenceElement && referenceElement.classList.add('show');
    };

    const hide = () => {
      referenceElement && referenceElement.classList.remove('show');
    };

    const showEvents = ['mouseenter'];
    const hideEvents = ['mouseleave'];

    showEvents.forEach((event) => {
      referenceElement.addEventListener(event, show);
      popperElement.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
      referenceElement.addEventListener(event, hide);
      popperElement.addEventListener(event, hide);
    });

    return () => {
      if (!referenceElement) {
        return;
      }
      showEvents.forEach((event) => {
        referenceElement.removeEventListener(event, show);
        popperElement.removeEventListener(event, show);
      });
      hideEvents.forEach((event) => {
        referenceElement.removeEventListener(event, hide);
        popperElement.removeEventListener(event, hide);
      });
    };
  }, [referenceElement, popperElement]);

  return (
      <div className={`LabelWithInfo ${inline ? 'inline' : ''}`} {...props}>
        <h4>
          <span className={labelclassname}>{text}</span>

          <div className="info-icon" ref={setReferenceElement}>
            <div className={'info-popper ' + infoPosition}
                 ref={setPopperElement}>
              {children}
            </div>
          </div>
        </h4>
      </div>
  );
}
