import {useState} from 'react';
import {getSummaryHours} from '../../../api';
import clone from 'clone';
import {useSummaryReport} from '../../../contexts/SummaryReportContext';

import StatArea from '../../organisms/StatArea';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import ContentModal from '../../organisms/ContentModal';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';

const ValueArea = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;

  & > div {
    margin-right: 25px;

    &:last-child {
      margin-right: 0px;
    }

    text-align: center;

    b {
      display: block;
      font-size: 60px;
      font-weight: bold;
      line-height: 60px;
    }

    i {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      margin-top: 20px;
    }
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '500px',
    width: '500px',
  },
};

export default function SummaryReportHours() {
  const summaryReport = useSummaryReport();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemsData, setItemsData] = useState({
    query: {
      type: 'monthly',
      year: 0,
      month: 0,
    },
    items: [],
  });
  const [detailFetched, setDetailFetched] = useState(false);

  function openModal() {
    setIsOpen(true);

    let currentQuery = summaryReport.state.fetched_query;
    if (
        itemsData.query.year != currentQuery.year ||
        itemsData.query.month != currentQuery.month
    ) {
      setDetailFetched(false);
      (async () => {
        try {
          let items = await getSummaryHours(currentQuery);
          setItemsData({
            query: clone(currentQuery),
            items: items,
          });
          console.log(items);
        } catch (e) {
          console.error(e);
        }
        setDetailFetched(true);
      })();
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!summaryReport.state.data) {
    return (
        <>
          <StatArea
              labelWithInfo={
                <LabelWithInfo
                    text={'HOURS PLAYED'}
                    infoPosition={InfoLinePosition.RightTop}
                >
                  <div></div>
                </LabelWithInfo>
              }
              delta={0}
              button={null}
          >
            <ValueArea>
              <div>
                <b>
                  <HappyNumberLabel text={'0'}/>
                </b>
                <i>H</i>
              </div>
              <div>
                <b>
                  <HappyNumberLabel text={'0'}/>
                </b>
                <i>M</i>
              </div>
              <div>
                <b>
                  <HappyNumberLabel text={'0'}/>
                </b>
                <i>S</i>
              </div>
            </ValueArea>
          </StatArea>
        </>
    );
  }

  let data = summaryReport.state.data.hours;

  let last = (data.previous && data.previous.numerator) || 0;
  let current = (data.current && data.current.numerator) || 0;

  let diff = 0;
  if (last != 0) {
    diff = Math.round((current / last - 1.0) * 100);
  }

  let labels = ['H', 'M', 'S'];

  let h = Math.floor(current / 3600);
  let m = Math.floor((current - h * 3600) / 60);
  let s = current % 60;

  if (h >= 1000) {
    // 4桁以上の場合はDHMにしたい
    labels = ['D', 'H', 'M'];

    const d = 3600 * 24;
    h = Math.floor(current / d);
    m = Math.floor((current - h * d) / 3600);
    s = Math.floor((current % 3600) / 60);
  }

  return (
      <>
        <StatArea
            labelWithInfo={
              <LabelWithInfo
                  text={'HOURS PLAYED'}
                  infoPosition={InfoLinePosition.RightTop}
              >
                <div>
                  You can see the total amount of durations played in your
                  territory
                  in the selected period. Tap ‘See more’ to see the most spun.
                </div>
              </LabelWithInfo>
            }
            delta={diff}
            button={
              <button className="light-button" onClick={openModal}>
                See more
              </button>
            }
        >
          <ValueArea>
            <div>
              <b>
                <HappyNumberLabel text={h.toLocaleString('en-US')}/>
              </b>
              <i>{labels[0]}</i>
            </div>
            <div>
              <b>
                <HappyNumberLabel text={m.toLocaleString('en-US')}/>
              </b>
              <i>{labels[1]}</i>
            </div>
            <div>
              <b>
                <HappyNumberLabel text={s.toLocaleString('en-US')}/>
              </b>
              <i>{labels[2]}</i>
            </div>
          </ValueArea>
        </StatArea>

        <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
          <div className="fixed-header">
            <h3>TOP 10 TRACKS PLAYED (Durations)</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                  <span>TRACK / ARTIST</span>
                </div>
                <div className="scroll-header-column last">
                  <div className="dummy-label">
                    {itemsData.items.map((item, i) => {
                      let _h = Math.floor(item.value / 3600);
                      let _m = Math.floor((item.value - _h * 3600) / 60);
                      return (
                          <div
                              key={i}
                              style={{
                                top: i * -1,
                              }}
                          >
                            {_h}
                            {':'}
                            {(_m + '').padStart(2, '0')}
                          </div>
                      );
                    })}
                  </div>
                  <span>HOURS (H:M)</span>
                </div>
              </div>
            </div>
          </div>

          <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
            <div
                className={`scroll-content ${
                    !detailFetched || itemsData.items.length
                        ? 'has-items'
                        : 'no-items'
                }`}
            >
              <table className="scroll-table">
                <tbody>
                {itemsData.items.map((item, i) => {
                  let names = [];
                  if (item.track.title) {
                    names.push(item.track.title);
                  }
                  if (item.track.artist) {
                    names.push(item.track.artist);
                  }

                  let _h = Math.floor(item.value / 3600);
                  let _m = Math.floor((item.value - _h * 3600) / 60);
                  return (
                      <tr key={i} className="tr">
                        <th>{(i + 1 + '').padStart(2, '0')}</th>
                        <td className="right-border">{names.join(' / ')}</td>
                        <td className="last">
                          {_h}
                          {':'}
                          {(_m + '').padStart(2, '0')}
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </ContentModal>
      </>
  );
}
