import {useState} from 'react';

import styled from 'styled-components';

import {useSummaryReport} from '../../../contexts/SummaryReportContext';
import BarGraph from '../../organisms/BarGraph';
import DBDPullDown from '../../atoms/DBDPullDown';
import moment from 'moment';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import {useAuth} from '../../../contexts/UserContext';

const GraphWrap = styled.div`
  position: relative;
`;

function DataGraph({type}) {
  const summaryReport = useSummaryReport();

  let values = [];
  if (summaryReport.state.data) {
    const query = summaryReport.state.fetched_query;

    const lastDay = parseInt(
        moment.utc(`${query.year}-${(query.month + '').padStart(2, '0')}-01`).
            add(1, 'month').
            add(-1, 'day').
            format('D'),
        10,
    );

    const data = summaryReport.state.data.graphs[type] || [];
    const dayMap = new Map();
    data.forEach((row) => {
      dayMap.set(row.date, row);
    });

    let m = moment.utc(
        `${query.year}-${(query.month + '').padStart(2, '0')}-01`,
    );

    for (let i = 1; i <= lastDay; i++) {
      const dt = m.format('YYYY-MM-DD');
      let row = dayMap.get(dt);
      values.push({
        date: m.toDate(),
        value: (row && row.value) || 0,
        na: !row,
      });
      m.add(1, 'day');
    }
  }

  return (
      <div>
        <BarGraph values={values} unit={type}/>
      </div>
  );
}

export default function SummaryReportGraph() {
  const [type, setType] = useState('tracks');
  const auth = useAuth();

  const onTypePullDownChange = (newValue) => {
    console.log(newValue);
    setType(newValue);
  };

  let pullDownItems = [
    {
      value: 'tracks',
      label: 'TRACKS',
    },
    {
      value: 'hours',
      label: 'HOURS',
    },
  ];
  if (auth.hasClubLicense()) {
    pullDownItems.push({
      value: 'venues',
      label: 'VENUES',
    });
  }

  return (
      <>
        <GraphWrap>
          <DataGraph type={type}/>
          <DBDPullDown
              value={type}
              onChange={onTypePullDownChange}
              items={pullDownItems}
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
              }}
          ></DBDPullDown>
          <LabelWithInfo
              text={'PLAYED'}
              infoPosition={InfoLinePosition.RightBottom}
              style={{
                position: 'absolute',
                left: 20,
                top: 20,
              }}
          >
            <div>
              You can see the activity chart in the selected period. Change the
              data source from the pulldown menu on the right top of the graph.
            </div>
          </LabelWithInfo>
        </GraphWrap>
      </>
  );
}

/*

      */
