import {useParams, Navigate, useNavigate} from 'react-router-dom';
import {openInvitation} from '../../../api';
import {useEffect} from 'react';
import LoginManager from '../../../libs/LoginManager';

export default function AgentLoginPage() {
  const {code} = useParams();
  useEffect(async () => {
    if (!code) {
      return;
    }

    LoginManager.getSharedInstance().keepLogin = false;
    LoginManager.getSharedInstance().setAccessToken(0, code);
    window.location.replace(window.PUBLIC_URL);
  }, []);

  if (!code) {
    return <Navigate to="/login/" state={{replace: true}}/>;
  }

  return <div></div>;
}
