import {useParams, Navigate, useNavigate} from 'react-router-dom';
import {openInvitation} from '../../../api';
import {useEffect} from 'react';

export default function InvitationPage() {
  const {code} = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    if (!code) {
      return;
    }

    let params = {};
    let error = 0;

    try {
      let res = await openInvitation({code});
      if (res.error) {
        error = res.error;
        delete res.error;
      }
      params = {
        ...res,
      };
    } catch (err) {
      const code =
          (err.response && err.response.data && err.response.data.code) || 500;
      if (code == 401031) {
        error = code;
      }
      console.error(code);
    }
    navigate('/login/', {
      state: {
        initial_params: params,
        error,
      },
      replace: true,
    });
  }, []);

  if (!code) {
    return <Navigate to="/login/" state={{replace: true}}/>;
  }

  return <div></div>;
}
