import {useState} from 'react';
import {requestVenueDownload, getFileStatus} from '../../../api';
import {useVenueReport} from '../../../contexts/VenueReportContext';
import SvgDlbtn from '../../atoms/SvgDlbtn';
import DownloadModal from '../../organisms/DownloadModal';

export default function VenueDownloadButton({
  vanueName,
}) {
  const venueReport = useVenueReport();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function onRequestDownload(format) {

    window.incrementLoading();

    (async () => {
      try {
        const dlkey = await requestVenueDownload(venueReport.state.club_id, {
          ...venueReport.state.fetched_query,
          format,
        });

        console.log(dlkey);

        let url = null;
        while (1) {
          await new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
          const status = await getFileStatus({dlkey});
          if (status.preparation_state == 'preparing') {
            console.log('waiting...');
            continue;
          }
          if (status.preparation_state == 'error') {
            window.decrementLoading();
            alert('Error!');
            return;
          }
          if (!status.url) {
            window.decrementLoading();
            alert('Unknown error!');
            return;
          }
          url = status.url;
          break;
        }
        if (url) {
          window.location = url;
        }
      } catch (e) {
        console.error(e);
      }
      setIsOpen(false);
      window.decrementLoading();
    })();
  }

  return (
      <>
        <button className="dark-button dlbtn" onClick={openModal}>
        <span>
          DOWNLOAD REPORT
          <SvgDlbtn/>
        </span>
        </button>
        <DownloadModal
            title={'DOWNLOAD REPORT  (' + vanueName + ')'}
            query={venueReport.state.fetched_query || venueReport.state.query}
            isOpen={modalIsOpen}
            onClose={closeModal}
            onRequestDownload={onRequestDownload}
        />
      </>
  );
}
