import {postPrivateInquiry} from '../../../api';
import {useState, useEffect} from 'react';
import ContentHeader from '../../organisms/ContentHeader';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import DBDPullDown from '../../atoms/DBDPullDown';
import './style.scss';

export default function ContactPage() {
  const [category, setCategory] = useState('none');
  const [errorElm, setErrorElm] = useState('');
  const [categoryError, setCategoryError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Contact | DASHBOARD';
  }, []);

  function onCategoryPullDownChange(newCategory) {
    console.log(newCategory);
    setCategory(newCategory);
    if (newCategory != 'none') {
      setCategoryError(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let description = formData.get('description');
    if (category == 'none') {
      setErrorElm(<span>Please select a category.</span>);
      setCategoryError(true);
      return;
    }

    setErrorElm('');
    setCategoryError(false);
    (async () => {
      try {
        /*
        await new Promise((resolve) => {
          setTimeout(resolve, 3000);
        });
        */
        await postPrivateInquiry({
          description,
          category,
        });
        navigate('/contact/submitted');
      } catch (e) {
        console.error(e);
        setErrorElm(
            <span>
            Submission Error.
            <br/>
            Your request has not been completed yet. Please go back to the
            previous page and try again.
          </span>,
        );
      }
    })();
  }

  return (
      <div>
        <ContentHeader
            title={'CONTACT'}
            subtitle={''}
            rightTopElem={null}
            style={{
              marginBottom: 12,
              paddingBottom: 4,
            }}
        />
        <div className="static-page-content contact">
          <section className="header-section">
            <h3>HOW CAN WE HELP YOU?</h3>

            <div className="d1">
              For all inquiries, please contact us using the form below.
            </div>

            <Link to={'/faq'} className="light-button">
              FAQ
            </Link>

            <div className="d2">You might find answers right away.</div>
          </section>

          <section className="form-section">
            <div className="inner">
              {errorElm ? <div className="error">{errorElm}</div> : ''}

              <form onSubmit={handleSubmit}>
                <h4>CATEGORY</h4>
                <div className="description">
                  Select a category to help us find the right solution for you.
                </div>
                <DBDPullDown
                    value={category}
                    onChange={onCategoryPullDownChange}
                    items={[
                      {
                        value: 'none',
                        label: 'Select One',
                        hidden: true,
                      },
                      {
                        value: 'Change Account Information',
                        label: 'Change Account Information',
                      },
                      {
                        value: 'Submit Venue List',
                        label: 'Submit Venue List',
                      },
                      {
                        value: 'Update Venue List',
                        label: 'Update Venue List',
                      },
                      {
                        value: 'Error',
                        label: 'Error',
                      },
                      {
                        value: 'Membership',
                        label: 'Membership',
                      },
                      {
                        value: 'Others',
                        label: 'Others',
                      },
                    ]}
                    fullwidth={1}
                    error={categoryError ? 1 : 0}
                ></DBDPullDown>
                <h4>DESCRIPTION</h4>
                <div className="description">
                  Please describe the details of your request.
                </div>
                <textarea
                    name="description"
                    required={true}
                    placeholder={'Tell us'}
                />
                <input type="submit" className="dark-button" value="SUBMIT"/>
              </form>
            </div>
          </section>
        </div>
      </div>
  );
}
