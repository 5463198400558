import {useState} from 'react';
import ContentModal from '../../organisms/ContentModal';
import {useVenueReport} from '../../../contexts/VenueReportContext';

import moment from 'moment';

import {useAuth} from '../../../contexts/UserContext';
import StatArea, {DefaultStatsLebel} from '../../organisms/StatArea';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';

const ValueStr = styled.div`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;

  b {
    font-size: 60px;
    line-height: 60px;
  }
`;

const DateLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: 21px;
  top: calc(21px + 1em + 5px);
`;

export default function VenueReportOffline() {
  const venueReport = useVenueReport();
  const auth = useAuth();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!auth.hasClubLicense()) {
    return <div>Cannot display</div>;
  }

  if (!venueReport.state.data || !venueReport.state.data.offline_rooms) {
    return (
        <>
          <div
              style={{
                position: 'relative',
              }}
          >
            <StatArea
                labelWithInfo={
                  <LabelWithInfo
                      text={'ACTIVE LAST 30 DAYS'}
                      infoPosition={InfoLinePosition.RightBottom}
                  >
                    <div></div>
                  </LabelWithInfo>
                }
                delta={null}
                button={null}
                style={{
                  backgroundColor: 'transparent',
                }}
            >
              <ValueStr>
                <b>
                  <HappyNumberLabel text="0"/>
                </b>{' '}
                / <HappyNumberLabel text="0"/>
              </ValueStr>

              <DefaultStatsLebel text={`rooms/floors`}/>
            </StatArea>
          </div>
        </>
    );
  }

  let data = venueReport.state.data.offline_rooms || {};

  let numerator = data.numerator || 0;
  let denominator = data.denominator || 0;
  numerator = denominator - numerator;
  let beforeM = moment(data.before);
  let beforeDate = beforeM.toDate();

  return (
      <>
        <div
            style={{
              position: 'relative',
            }}
        >
          <StatArea
              labelWithInfo={
                <LabelWithInfo
                    text={'ACTIVE LAST 30 DAYS'}
                    infoPosition={InfoLinePosition.RightBottom}
                >
                  <div>
                    You can monitor the active rooms/floors being online/offline
                    in the last 30 days. Tap 'See more' to view the list.
                  </div>
                </LabelWithInfo>
              }
              delta={null}
              button={
                <button className="light-button" onClick={openModal}>
                  See more
                </button>
              }
              style={{
                backgroundColor: 'transparent',
              }}
          >
            <ValueStr>
              <b>
                <HappyNumberLabel text={numerator.toLocaleString('en-US')}/>
              </b>{' '}
              / <HappyNumberLabel text={denominator.toLocaleString('en-US')}/>
            </ValueStr>

            <DefaultStatsLebel
                text={denominator != 1 ? `rooms/floors` : `room/floor`}/>
          </StatArea>

          <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
            <div className="fixed-header">
              <h3>ACTIVE LAST 30 DAYS</h3>
              <div className="scroll-header">
                <div className="scroll-header-columns">
                  <div className="scroll-header-column">
                    <span>ROOM or FLOOR</span>
                  </div>
                  <div
                      className="scroll-header-column last"
                      style={{
                        marginRight: 0,
                      }}
                  >
                    <div className="dummy-label">
                      {data.items.map((item, i) => {
                        return (
                            <div
                                key={i}
                                style={{
                                  top: i * -1,
                                }}
                            >
                              {item.text}
                            </div>
                        );
                      })}
                    </div>
                    <span>LAST ONLINE</span>
                  </div>
                </div>
              </div>
            </div>

            <SimpleBar forceVisible="y" autoHide={false}
                       className="scroll-area">
              <div
                  className={`scroll-content ${
                      data.items.length ? 'has-items' : 'no-items'
                  }`}
              >
                <table className="scroll-table">
                  <tbody>
                  {data.items.map((item, i) => {
                    const m = moment(item.value);
                    const dt = m.toDate();
                    return (
                        <tr key={i} className={beforeDate <= dt ? 'on' : 'off'}>
                          <td className="right-border">
                            {item.room.name}
                          </td>
                          <td className="last">
                            <span className={`on-off-text ${beforeDate <= dt
                                ? 'on'
                                : 'off'}`}>
                            {item.text}
                              </span>

                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
          </ContentModal>
        </div>
      </>
  );
}
