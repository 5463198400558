import {useState} from 'react';
import {requestSummaryDownload, getFileStatus} from '../../../api';
import {useSummaryReport} from '../../../contexts/SummaryReportContext';
import SvgDlbtn from '../../atoms/SvgDlbtn';
import DownloadModal from '../../organisms/DownloadModal';
import {useAuth} from '../../../contexts/UserContext';

export default function SummaryDownloadButton() {
  const summaryReport = useSummaryReport();

  const [modalIsOpen, setIsOpen] = useState(false);
  const {hasFullContributorPermission} = useAuth();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function onRequestDownload(format) {
    window.incrementLoading();

    (async () => {
      try {
        const dlkey = await requestSummaryDownload({
          ...summaryReport.state.fetched_query,
          format,
        });

        console.log(dlkey);

        let url = null;
        while (1) {
          await new Promise((resolve) => {
            setTimeout(resolve, 2000);
          });
          const status = await getFileStatus({dlkey});
          if (status.preparation_state == 'preparing') {
            console.log('waiting...');
            continue;
          }
          if (status.preparation_state == 'error') {
            window.decrementLoading();
            alert('Error!');
            return;
          }
          if (!status.url) {
            window.decrementLoading();
            alert('Unknown error!');
            return;
          }
          url = status.url;
          break;
        }
        if (url) {
          window.location = url;
        }
      } catch (e) {
        console.error(e);
      }
      setIsOpen(false);
      window.decrementLoading();
    })();
  }

  if (!hasFullContributorPermission()) {
    return (
        <>
          <button className="dark-button dlbtn disabled" disabled={true}>
          <span>
            DOWNLOAD REPORT
            <SvgDlbtn/>
          </span>
          </button>
        </>
    );
  }

  return (
      <>
        <button className="dark-button dlbtn" onClick={openModal}>
        <span>
          DOWNLOAD REPORT
          <SvgDlbtn/>
        </span>
        </button>
        <DownloadModal
            title={'DOWNLOAD REPORT'}
            query={summaryReport.state.fetched_query ||
                summaryReport.state.query}
            isOpen={modalIsOpen}
            onClose={closeModal}
            onRequestDownload={onRequestDownload}
        />
      </>
  );
}
