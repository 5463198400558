import {useState, useRef, useEffect} from 'react';
import {Routes, Route, Navigate, Outlet, useLocation} from 'react-router-dom';
import {
  UserContextProvider,
  RequireAuth,
  RequireAuthorizerAuth,
  useAuth,
} from '../contexts/UserContext';
import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';
import LoginPage from '../components/pages/LoginPage';
import SideNav from '../components/organisms/SideNav';
import DashboardPage from '../components/pages/DashboardPage';
import VenueReportPage from '../components/pages/VenueReportPage';
import ContactPage from '../components/pages/ContactPage';
import ContactSubmittedPage from '../components/pages/ContactPage/submitted';
import FAQPage from '../components/pages/FAQPage';
import TermsOfUsePage from '../components/pages/TermsOfUsePage';
import AnimationManager from '../libs/AnimationManager';
import SettingsPage from '../components/pages/SettingsPage';
import PermissionsPage from '../components/pages/SettingsPage/PermissionsPage';
import InvitationPage from '../components/pages/InvitationPage';
import AgentLoginPage from '../components/pages/AgentLoginPage';
import {CustomDialogContextProvider} from '../contexts/CustomDialogContext';
import CustomDialog from '../components/organisms/CustomDialog';
import NotFoundPage from '../components/pages/NotFoundPage';

const DataTypeUnit = {
  areas: {
    singular: 'AREA',
    plural: 'AREAS',
  },
  tracks: {
    singular: 'TRACK',
    plural: 'TRACKS',
  },
  hours: {
    singular: 'HOUR',
    plural: 'HOURS',
  },
  venues: {
    singular: 'VENUE',
    plural: 'VENUES',
  },
};

const LayoutWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  height: 100vh;
  overflow: auto;
  overflow-y: scroll;

  @media print {
    min-height: unset;
    max-height: unset;
    height: unset;
    overflow: visible;
    overflow-y: visible;
  }
`;

const Wrapper = styled.div`
  display: block;
  min-height: 100vh;
  position: relative;

  @media print {
    min-height: unset;
  }
`;

const ContentWrap = styled.div`
  margin: 0 0 0 189px;
  min-width: 771px;
  min-height: 100vh;

  @media print {
    margin-left: 0;
    min-height: unset;
  }
`;

const Content = styled.div`
  max-width: calc(1900px - 189px);
  margin: 0 auto;
  min-height: 100vh;
  position: relative;

  @media print {
    max-width: unset;
    min-height: unset;
  }
`;

const ContentInner = styled.div`
  padding: 0px 40px 80px 0;

  @media print {
    padding: 0px 0px 0px 0;
  }
`;

const PageTopButton = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 42px;
  height: 42px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='レイヤー_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 42 42' style='enable-background:new 0 0 42 42;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23525252;%7D%0A%3C/style%3E%3Cpath class='st0' d='M27.6,23.09l-5.68-5.68c-0.39-0.39-1.03-0.39-1.42,0c0,0,0,0,0,0c0,0,0,0,0,0l-5.68,5.68 c-0.39,0.39-0.39,1.03,0,1.42c0.39,0.39,1.03,0.39,1.42,0l4.97-4.97l4.97,4.97c0.39,0.39,1.03,0.39,1.42,0 C27.99,24.12,27.99,23.48,27.6,23.09z'/%3E%3C/svg%3E%0A");
  filter: drop-shadow(6px 6px 12px rgba(0, 0, 0, 0.1));
  transition: opacity 0.15s, background-color 0.15s;
  opacity: 0;
  pointer-events: none;

  &.scroll {
    opacity: 1;
    pointer-events: initial;

    &:hover {
      background-color: #525252;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='レイヤー_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 42 42' style='enable-background:new 0 0 42 42;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ffffff;%7D%0A%3C/style%3E%3Cpath class='st0' d='M27.6,23.09l-5.68-5.68c-0.39-0.39-1.03-0.39-1.42,0c0,0,0,0,0,0c0,0,0,0,0,0l-5.68,5.68 c-0.39,0.39-0.39,1.03,0,1.42c0.39,0.39,1.03,0.39,1.42,0l4.97-4.97l4.97,4.97c0.39,0.39,1.03,0.39,1.42,0 C27.99,24.12,27.99,23.48,27.6,23.09z'/%3E%3C/svg%3E%0A");
    }
  }
`;

if (/cmo-stg/.test(`${window.location.host}`)) {
  window.location.href = 'https://staging.kuvo.com/cmo/';
} else if (/cmo/.test(`${window.location.host}`)) {
  window.location.href = 'https://kuvo.com/cmo/';
}

export default function App() {
  AnimationManager.getSharedInstance();
  const loadingStateRef = useRef({
    count: 0,
  });
  const [showLoading, setShowLoading] = useState(false);

  window.incrementLoading = () => {
    loadingStateRef.current.count++;
    setShowLoading(0 < loadingStateRef.current.count);
  };
  window.decrementLoading = () => {
    loadingStateRef.current.count = Math.max(
        loadingStateRef.current.count - 1,
        0,
    );
    setShowLoading(0 < loadingStateRef.current.count);
  };
  window.clearLoading = () => {
    loadingStateRef.current.count = 0;
    setShowLoading(0 < loadingStateRef.current.count);
  };

  window.getUnitLabel = (type, value) => {
    let unit = DataTypeUnit[type] || null;
    if (!unit) {
      return '';
    }
    if (value == 1) {
      return unit.singular;
    }
    return unit.plural;
  };

  return (
      <UserContextProvider>
        <CustomDialogContextProvider>
          <LoadingOverlay
              active={showLoading}
              spinner={
                <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 90 90"
                    enableBackground="new 0 0 90 90"
                >
                  <linearGradient
                      id="SVGID_1_"
                      gradientUnits="userSpaceOnUse"
                      x1="45"
                      y1="6.73"
                      x2="45"
                      y2="89.5"
                  >
                    <stop offset="0" style={{stopColor: '#BDDAF0'}}/>
                    <stop offset="1" style={{stopColor: '#007DE1'}}/>
                  </linearGradient>
                  <path
                      fill="url(#SVGID_1_)"
                      d="M45,0.5C20.42,0.5,0.5,20.42,0.5,45c0,24.58,19.92,44.5,44.5,44.5c24.58,0,44.5-19.92,44.5-44.5
	C89.5,20.42,69.58,0.5,45,0.5z M45,74.67c-16.38,0-29.67-13.28-29.67-29.67S28.62,15.33,45,15.33S74.67,28.62,74.67,45
	S61.38,74.67,45,74.67z"
                  />
                </svg>
              }
              text="LOADING"
              styles={{
                wrapper: {
                  minHeight: '100vh',
                  backgroundColor: 'transparent',
                },
              }}
          >
            <Routes>
              <Route path="/login" element={<LoginPage/>}/>
              <Route path="/invitation/:code" element={<InvitationPage/>}/>
              <Route path="/agent-login/:code" element={<AgentLoginPage/>}/>

              <Route element={<WeakLoggedInLayout/>}>
                <Route path="/terms-of-use" element={<TermsOfUsePage/>}/>
                <Route path="/faq" element={<FAQPage/>}/>
              </Route>

              <Route
                  element={
                    <RequireAuth>
                      <LoggedInLayout/>
                    </RequireAuth>
                  }
              >
                <Route
                    path="/venues/:club_id"
                    element={<VenueReportPage/>}
                />
                <Route path="/" element={<DashboardPage/>}/>

                <Route path="/settings" element={<SettingsPage/>}/>
                <Route
                    path="/settings/permissions"
                    element={
                      <RequireAuthorizerAuth fallback="/settings">
                        <PermissionsPage/>
                      </RequireAuthorizerAuth>
                    }
                />

                <Route
                    path="/contact/submitted"
                    element={<ContactSubmittedPage/>}
                />
                <Route path="/contact" element={<ContactPage/>}/>
              </Route>

              <Route element={<WeakLoggedInLayout/>}>
                <Route path="*" element={<NotFoundPage/>}/>
              </Route>
            </Routes>
          </LoadingOverlay>
          <CustomDialog/>
        </CustomDialogContextProvider>
      </UserContextProvider>
  );
}

function LayoutWrapperElm({children}) {
  const ref = useRef();
  const auth = useAuth();
  const {pathname, key} = useLocation();
  //console.log(pathname);
  const [hasScrollButton, setHasScrollButton] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }

    setHasScrollButton(
        /faq/.test(pathname) ||
        /terms-of-use/.test(pathname),
    );
    setShowScrollButton(false);
  }, [pathname]);

  const onScroll = function(e) {
    setShowScrollButton(hasScrollButton && !!e.target.scrollTop);
  };

  const onScrollTopClicked = function(e) {
    ref.current.scrollTop = 0;
    setShowScrollButton(false);
  };

  return (
      <LayoutWrapper ref={ref} className="layout-wrapper" onScroll={onScroll}>
        {children}
        <PageTopButton
            onClick={onScrollTopClicked}
            className={`${showScrollButton ? 'scroll' : ''}`}
        />
      </LayoutWrapper>
  );
}

function WeakLoggedInLayout() {
  return (
      <LayoutWrapperElm>
        <Wrapper>
          <SideNav/>
          <ContentWrap className="content-wrap">
            <Content className="main-content">
              <ContentInner>
                <Outlet/>
              </ContentInner>
            </Content>
          </ContentWrap>
        </Wrapper>
      </LayoutWrapperElm>
  );
}

function LoggedInLayout() {
  return (
      <LayoutWrapperElm>
        <Wrapper>
          <SideNav/>
          <ContentWrap className="content-wrap">
            <Content className="main-content">
              <ContentInner>
                <Outlet/>
              </ContentInner>
            </Content>
          </ContentWrap>
        </Wrapper>
      </LayoutWrapperElm>
  );
}
