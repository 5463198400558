import {fetchDelete, fetchGet, fetchPost} from './utils.js';
import LoginManager from '../libs/LoginManager';

const loginManager = LoginManager.getSharedInstance();

export async function login({cmo_code, email, password}) {
  const res = await fetchPost({
    path: '/api/v1/auth/login',
    data: {
      cmo_code,
      email,
      password,
    },
    params: {},
  });
  return res || null;
}

export async function openInvitation({code}) {
  const res = await fetchGet({
    path: '/api/v1/auth/open-invitation',
    params: {
      code,
    },
  });
  return res || null;
}

async function wrapRequireAuthAPI(fn, opts) {
  let res = null;
  try {
    console.log(opts);
    window.incrementLoading();
    res = await fn({
      ...opts,
      bearer: loginManager.accessToken,
    });

    if (res && res.info && res.info.token) {
      loginManager.setAccessToken(
          res.info.token.expires_in,
          res.info.token.access_token,
      );
    }
  } catch (e) {
    window.decrementLoading();
    if (e && e.response && e.response.status && e.response.status == 401) {
      console.log('Invalid token');
      loginManager.invalidTokenDetected();
      return null;
    }
    throw e;
  }
  window.decrementLoading();
  return res;
}

export async function getProfile() {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/users/me/profile',
  });
  return res || null;
}

export async function getMyVenues() {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/users/me/venues',
  });
  return (res && res.items) || [];
}

export async function getSummary({type, year, month, data = 'raw'}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/reports/summary',
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.summary) || null;
}

export async function getSummaryLocations({type, year, month, data = 'raw'}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/reports/summary/locations',
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.items) || [];
}

export async function getSummaryTracks({type, year, month, data = 'raw'}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/reports/summary/tracks',
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.items) || [];
}

export async function getSummaryHours({type, year, month, data = 'raw'}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/reports/summary/hours',
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.items) || [];
}

export async function getSummaryActiveVenues({
  type,
  year,
  month,
  data = 'raw',
}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/reports/summary/active-venues',
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.items) || [];
}

export async function getSummaryOfflineVenues({
  type,
  year,
  month,
  data = 'raw',
}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/reports/summary/offline-venues',
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.items) || [];
}

export async function requestSummaryDownload({type, year, month, format}) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: '/api/v1/reports/summary/request-download',
    data: {
      type,
      year,
      month,
      format,
    },
  });
  return (res && res.dlkey) || null;
}

export async function getFileStatus({dlkey}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: '/api/v1/files/status',
    params: {
      dlkey,
    },
  });
  return res || null;
}

export async function getVenueSummary(
    club_id, {type, year, month, data = 'raw'}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: `/api/v1/reports/venues/${club_id}`,
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.summary) || null;
}

export async function getVenueTracks(
    club_id, {type, year, month, data = 'raw'}) {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: `/api/v1/reports/venues/${club_id}/tracks`,
    params: {
      type,
      year,
      month,
      data,
    },
  });
  return (res && res.items) || [];
}

export async function requestVenueDownload(
    club_id,
    {type, year, month, format},
) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: `/api/v1/reports/venues/${club_id}/request-download`,
    data: {
      type,
      year,
      month,
      format,
    },
  });
  return (res && res.dlkey) || null;
}

export async function postPrivateInquiry({description, category}) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: `/api/v1/contacts/private`,
    data: {
      description,
      category,
    },
  });
  return (res && res.result) || false;
}

export async function getMembers() {
  let res = await wrapRequireAuthAPI(fetchGet, {
    path: `/api/v1/admin/members`,
  });
  return res || {};
}

export async function changeUserPermission({gigya_uid, permission_level}) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: `/api/v1/users/${encodeURIComponent(gigya_uid)}/change-permission`,
    data: {
      permission_level,
    },
  });
  return (res && res.result) || false;
}

export async function removeMember({gigya_uid}) {
  let res = await wrapRequireAuthAPI(fetchDelete, {
    path: `/api/v1/users/${encodeURIComponent(gigya_uid)}`,
  });
  return (res && res.result) || false;
}

export async function inviteMember({email, permission_level}) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: `/api/v1/admin/invitations/new`,
    data: {
      email,
      permission_level,
    },
  });
  return (res && res.result) || false;
}

export async function cancelInvitation({datainvitation_id}) {
  let res = await wrapRequireAuthAPI(fetchDelete, {
    path: `/api/v1/admin/invitations/${datainvitation_id}`,
  });
  return (res && res.result) || false;
}

export async function resendInvitation({datainvitation_id}) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: `/api/v1/admin/invitations/${datainvitation_id}/resend`,
  });
  return (res && res.result) || false;
}

export async function transferAutority({gigya_uid}) {
  let res = await wrapRequireAuthAPI(fetchPost, {
    path: `/api/v1/admin/transfer`,
    data: {
      gigya_uid,
    },
  });
  return (res && res.result) || false;
}

export async function getAllFAQs() {
  const res = await fetchPost({
    path: 'https://clubedit-api.kuvo.com/api/v1/faqs/search',
    data: {
      categories: ['cmo'],
    },
    params: {},
  });
  return res && res.faqs || [];
}