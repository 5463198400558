import {useState, useEffect} from 'react';
import {getSummaryActiveVenues} from '../../../api';
import clone from 'clone';
import {Link} from 'react-router-dom';

import {useSummaryReport} from '../../../contexts/SummaryReportContext';

import {useAuth} from '../../../contexts/UserContext';
import StatArea, {DefaultStatsLebel} from '../../organisms/StatArea';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import NeedLicenseButton from '../../organisms/NeedLicenseButton';
import styled from 'styled-components';
import ContentModal from '../../organisms/ContentModal';
import SimpleBar from 'simplebar-react';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';

const ValueStr = styled.div`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;

  b {
    font-size: 80px;
    line-height: 80px;
  }
`;

export default function SummaryReportVenues() {
  const summaryReport = useSummaryReport();
  const auth = useAuth();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemsData, setItemsData] = useState({
    query: {
      type: 'monthly',
      year: 0,
      month: 0,
    },
    items: [],
  });
  const [detailFetched, setDetailFetched] = useState(false);
  const [displayedItems, setDisplayedItems] = useState([]);

  function openModal() {
    setIsOpen(true);

    let currentQuery = summaryReport.state.fetched_query;
    if (
        itemsData.query.year != currentQuery.year ||
        itemsData.query.month != currentQuery.month
    ) {
      setDetailFetched(false);
      (async () => {
        try {
          let items = await getSummaryActiveVenues(currentQuery);
          setItemsData({
            query: clone(currentQuery),
            items: items,
          });
          console.log(items);
        } catch (e) {
          console.error(e);
        }
        setDetailFetched(true);
      })();
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setDisplayedItems([]);
    displayNext();
  }, [itemsData]);

  function displayNext() {
    setDisplayedItems((prev) => {
      if (prev.length < itemsData.items.length) {
        let n = prev.concat(
            itemsData.items.slice(prev.length, prev.length + 10),
        );

        return n;
      }
      return prev.slice();
    });
  }

  if (!auth.hasClubLicense()) {
    return (
        <>
          <div
              style={{
                position: 'relative',
              }}
          >
            <StatArea
                labelWithInfo={
                  <LabelWithInfo
                      text={'ACTIVE VENUES'}
                      infoPosition={InfoLinePosition.RightTop}
                  >
                    <div>
                      You can see the number of active venues in your territory.
                      (To
                      see this data, you need to submit a list.)
                    </div>
                  </LabelWithInfo>
                }
                delta={null}
                button={
                  <button
                      className="light-button"
                      style={{
                        opacity: 0.06,
                        pointerEvents: 'none',
                      }}
                  >
                    See more
                  </button>
                }
            >
              <ValueStr
                  style={{
                    opacity: 0.06,
                  }}
              >
                <b>000</b> / 000
              </ValueStr>
              <DefaultStatsLebel
                  style={{
                    opacity: 0.06,
                  }}
                  text={`VENUES`}
              />
            </StatArea>
            <NeedLicenseButton/>
          </div>
        </>
    );
  }

  if (!summaryReport.state.data) {
    return (
        <>
          <StatArea
              labelWithInfo={
                <LabelWithInfo
                    text={'ACTIVE VENUES'}
                    infoPosition={InfoLinePosition.RightTop}
                >
                  <div></div>
                </LabelWithInfo>
              }
              delta={0}
              button={null}
          >
            <ValueStr>
              <b>
                <HappyNumberLabel text={'0'}/>
              </b>{' '}
              / <HappyNumberLabel text={'0'}/>
            </ValueStr>
            <DefaultStatsLebel text={'VENUES'}/>
          </StatArea>
        </>
    );
  }

  let data = summaryReport.state.data.venues || {};

  let last = (data.previous && data.previous.numerator) || 0;
  let current = (data.current && data.current.numerator) || 0;
  let denominator = (data.current && data.current.denominator) || 0;

  let diff = 0;
  if (last != 0) {
    diff = Math.round((current / last - 1.0) * 100);
  }

  return (
      <>
        <StatArea
            labelWithInfo={
              <LabelWithInfo
                  text={'ACTIVE VENUES'}
                  infoPosition={InfoLinePosition.RightTop}
              >
                <div>
                  You can see the number of active venues in your territory. Tap
                  ‘See more’ to see top vibrant venues.
                </div>
              </LabelWithInfo>
            }
            delta={diff}
            button={
              <button className="light-button" onClick={openModal}>
                See more
              </button>
            }
        >
          <ValueStr>
            <b>
              <HappyNumberLabel text={current.toLocaleString('en-US')}/>
            </b>{' '}
            / <HappyNumberLabel text={denominator.toLocaleString('en-US')}/>
          </ValueStr>
          <DefaultStatsLebel text={`VENUE${current != 1 ? 'S' : ''}`}/>
        </StatArea>

        <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
          <div className="fixed-header">
            <h3>TOP ACTIVE VENUES</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                  <span>VENUES</span>
                </div>
                <div
                    className="scroll-header-column last"
                    style={{
                      marginRight: 121,
                    }}
                >
                  <div className="dummy-label">
                    {displayedItems.map((item, i) => {
                      return (
                          <div
                              key={i}
                              style={{
                                top: i * -1,
                              }}
                          >
                            {item.value}
                          </div>
                      );
                    })}
                  </div>
                  <span>TRACKS</span>
                </div>
              </div>
            </div>
          </div>

          <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
            <div
                className={`scroll-content ${
                    !detailFetched || displayedItems.length
                        ? 'has-items'
                        : 'no-items'
                }`}
            >
              <table className="scroll-table">
                <tbody>
                {displayedItems.map((item, i) => {
                  return (
                      <tr key={i} className="tr">
                        <th>{(i + 1 + '').padStart(2, '0')}</th>
                        <td className="right-border">{item.venue.name}</td>
                        <td className="last right-border">{item.value}</td>
                        <td className="last button">
                          <Link
                              to={
                                  '/venues/' +
                                  item.venue.club_id +
                                  window.location.search
                              }
                              className="dark-button"
                          >
                            REPORT
                          </Link>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>

              {displayedItems.length < itemsData.items.length ? (
                  <div className="button-area">
                    <button
                        className="light-button"
                        onClick={() => {
                          displayNext();
                        }}
                    >
                      See more
                    </button>
                  </div>
              ) : (
                  ''
              )}
            </div>
          </SimpleBar>
        </ContentModal>
      </>
  );
}
