import {createPortal} from 'react-dom';
import './style.scss';

const ContentModal = ({children, closeModal, isOpen, ...props}) => {
  if (!isOpen) {
    return '';
  }

  return createPortal(
      <div className="ContentModal" {...props}>
        <div className="bg" onClick={closeModal}
             onMouseOver={console.log}></div>
        <div className="panel">
          <button className="close-btn" onClick={closeModal}></button>

          <div className="panel-content">{children}</div>
        </div>
      </div>,
      document.getElementsByTagName('body')[0],
  );
};

export default ContentModal;
