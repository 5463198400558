import {useEffect, useRef, useState} from 'react';
import ContentHeader from '../../organisms/ContentHeader';
import './style.scss';
import {useAuth} from '../../../contexts/UserContext';
import {Link, useLocation, useResolvedPath} from 'react-router-dom';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';

function PageHeader() {
  return (
      <ContentHeader
          title={'SETTINGS'}
          subtitle={''}
          rightTopElem={null}
          style={{
            marginBottom: 12,
            paddingBottom: 4,
          }}
      />
  );
}

const useInterval = (callback, delay = null) => {
  const savedCallback = useRef(() => {});
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => savedCallback.current(), delay || 0);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [delay]);
};

function useScroll() {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  let elms = document.getElementsByClassName('layout-wrapper');
  let target = elms.length ? elms[0] : null;

  useEffect(() => {
    target && target.removeEventListener('scroll', handleScroll);
    target &&
    target.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    handleScroll();

    return () => {
      target && target.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    if (target) {
      setScrollTop(target.scrollTop);
      setScrollLeft(target.scrollLeft);
    }
  }

  return {targetScrollLeft: scrollLeft, targetScrollTop: scrollTop};
}

export function SettingsMenu() {
  const {pathname, key} = useLocation();
  const auth = useAuth();
  const {targetScrollTop} = useScroll();

  const isAuthorizor =
      auth.state &&
      auth.state.user &&
      auth.state.user.permission_level &&
      auth.state.user.permission_level == 5;

  return (
      <div
          className="settings-menu"
          style={{
            marginTop: targetScrollTop,
          }}
      >
        <div>
          <Link
              className={/settings(\/|)$/.test(pathname)
                  ? 'active-link'
                  : 'link'}
              to={'/settings'}
          >
            <span className="bignav">SETTINGS</span>
          </Link>
        </div>
        <div>
          <a
              href="https://www.pioneerdj.com/en-us/account/profile/"
              target="_blank"
              rel="noopener"
              className="link external-link"
          >
            <span className="bignav">MY ACCOUNT</span>
          </a>
        </div>

        <div>
          <span className={'bignav-disabled'}>PERMISSIONS</span>
        </div>
      </div>
  );
  /*
    <div>
      {isAuthorizor ? (
          <Link
              className={
                /settings\/permissions(\/|)$/.test(pathname)
                    ? 'active-link'
                    : 'link'
              }
              to={'/settings/permissions'}
          >
            <span className={'bignav'}>PERMISSIONS</span>
          </Link>
      ) : (
          <span className={'bignav-disabled'}>PERMISSIONS</span>
      )}
    </div>
   */
}

export default function SettingsPage() {
  const auth = useAuth();
  const user = auth.state.user;
  if (!user) {
    return '';
  }
  const isAuthorizor =
      auth.state &&
      auth.state.user &&
      auth.state.user.permission_level &&
      auth.state.user.permission_level == 5;

  return (
      <>
        <PageHeader/>
        <div className="settings">
          <div className="settings-contents settings-top">
            <div className="settings-contents-inner">
              <section className="profile-area">
                <div className="profile-area-content">
                  <a
                      className={
                        user.image_url
                            ? 'image-area has-image'
                            : 'image-area no-image'
                      }
                      href="https://www.pioneerdj.com/en-us/account/profile/"
                      target="_blank"
                      rel="noopener"
                  >
                    {user.image_url ? (
                        <div
                            style={{
                              backgroundImage: `url(${user.image_url})`,
                            }}
                            className="photo"
                        />
                    ) : (
                        <div
                            className="nullable-photo"
                        />
                    )}
                  </a>
                  <div className="info-area">
                    <div className="info-name">
                      <div className="info-label">Name</div>
                      <div className="name">
                        {user.first_name} {user.last_name}
                      </div>
                    </div>
                    <div className="info-other">
                      <div className="info-other-contents">
                        <div className="info-cmo">
                          <div className="info-label">Organization</div>
                          <div className="cmo">{user.cmo.name}</div>
                        </div>
                        <div className="info-permission">
                          <div className="info-label">Permissions</div>
                          <div className="permission">
                            {user.permission_level_str}
                          </div>
                        </div>
                        <div className="info-cmocode">
                          <div className="info-label">KUVO ID</div>
                          <div className="cmo_code">
                            {user.cmo.cmo_code}
                          </div>
                        </div>
                        <div className="info-tier">
                          <LabelWithInfo
                              text={'Membership'}
                              infoPosition={InfoLinePosition.RightTop}
                              style={{
                                marginBottom: 0,
                              }}
                              labelclassname="info-label"
                          >
                            <div>
                              <p>Membership plan is currently unavailable.</p>
                              <p>
                                Please contact your rep for the details.
                              </p>
                            </div>
                          </LabelWithInfo>
                          <div className="tier">
                            {user.cmo.tier_str}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="settings-contents-section">
                <div className="settings-content my-account">
                  <h3>MY ACCOUNT</h3>
                  <div className="description">
                    Change your Pioneer DJ account profile, e-mail and password.
                    (It will direct you to pioneerdj.com )
                  </div>
                  <div className="button-area">
                    <a
                        href="https://www.pioneerdj.com/en-us/account/profile/"
                        target="_blank"
                        rel="noopener"
                        className="light-button"
                    >
                      <span className="external-link">Pioneer DJ account</span>
                    </a>
                  </div>
                </div>

                <div
                    className={`settings-content permissions ${
                        (1 || !isAuthorizor) ? 'disabled' : ''
                    }`}
                >
                  <h3>PERMISSIONS (Authorizer only)</h3>
                  <div className="description">
                    An authorizer can invite and manage teams to the dashboard.
                    Assign permissions to limit access relevant to your team
                    member’s role.
                  </div>

                  <div className="button-area">
                    {(1 || !isAuthorizor) ? (
                        <span className="light-button disabled">Edit</span>
                    ) : (
                        <Link to={'/settings/permissions'}
                              className="light-button">
                          Edit
                        </Link>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <SettingsMenu/>
        </div>
      </>
  );
}
