import React, {createContext, useEffect, useReducer, useContext} from 'react';
import {useLocation, Navigate, useSearchParams} from 'react-router-dom';
import {login, getProfile, getMyVenues} from '../../api';
import LoginManager from '../../libs/LoginManager';

const loginManager = LoginManager.getSharedInstance();
const UserContext = createContext();

const initialState = {
  user: null,
  venues: [],
};

function reducer(state, action) {
  console.log(state, action);
  switch (action.type) {
    case 'signin': {
      return {
        ...state,
        user: action.user,
        venues: action.venues,
      };
    }
    case 'signout': {
      return {
        ...state,
        user: null,
      };
    }
    default:
      throw new Error(`${action.type} is not defined.`);
  }
}

export const UserContextProvider = ({api, children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {pathname, key} = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateDataTypeIfNeeded = () => {

    if (state.user?.cmo) {
      if (state.user.cmo.tier == 2) {
        let dataTypeInURL = searchParams.get('data');
        if (dataTypeInURL && dataTypeInURL != 'raw') {
          let params = {};
          [...searchParams.keys()].forEach(k => {
            params[k] = searchParams.get(k);
          });
          delete params['data'];
          setSearchParams(params, {
            replace: true,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (pathname && /^\/invitation\//i.test(pathname)) {
      loginManager.removeAccessToken();
      dispatch({
        type: 'signout',
      });
    } else {
      if (loginManager.isLoggedIn()) {
        (async () => {
          try {
            window.incrementLoading();

            let [profile, venues] = await Promise.all([
              getProfile(),
              getMyVenues(),
            ]);

            window.decrementLoading();
            dispatch({
              type: 'signin',
              user: profile && profile.user,
              venues,
            });
            updateDataTypeIfNeeded();
          } catch (e) {
            console.error(e);
          }
        })();
      }
    }

    const handler = () => {
      dispatch({
        type: 'signout',
      });
    };
    loginManager.addEventListener('invalid_token', handler);
    return () => {
      loginManager.removeEventListener('invalid_token', handler);
    };
  }, []);

  let signin = async (cmo_code, email, password, keep, callback) => {
    try {
      window.incrementLoading();
      let result = await login({cmo_code, email, password});
      loginManager.setKeepLogin(!!keep);
      loginManager.setAccessToken(result.expires_in, result.access_token);
      let [profile, venues] = await Promise.all([getProfile(), getMyVenues()]);

      dispatch({
        type: 'signin',
        user: profile && profile.user,
        venues,
      });
      updateDataTypeIfNeeded();
      window.clearLoading();
      callback();
    } catch (e) {
      window.clearLoading();
      callback(e);
    }
  };

  let signout = (callback) => {
    loginManager.removeAccessToken();
    dispatch({
      type: 'signout',
    });
    callback && callback();
  };

  let isLoggedIn = () => {
    return loginManager.isLoggedIn();
  };

  let hasClubLicense = () => {
    return (
        (state &&
            state.user &&
            state.user.cmo &&
            state.user.cmo.tier == 2 &&
            !!state.user.cmo.club_license_status) ||
        false
    );
  };

  let hasFullContributorPermission = () => {
    return (state && state.user && state.user.permission_level >= 4) || false;
  };

  let isPremiumCMO = () => {
    return state?.user?.cmo?.tier == 2 || false;
  };

  let changeDataType = (newType) => {
    let params = {};
    [...searchParams.keys()].forEach(k => {
      params[k] = searchParams.get(k);
    });
    params.data = newType;
    setSearchParams(params, {
      replace: false,
    });
  };

  let getDataType = () => {
    let data_type = 'raw';
    if (state.user?.cmo?.tier == 2) {
      let dataTypeInURL = searchParams.get('data');
      if (dataTypeInURL &&
          (dataTypeInURL == 'raw' ||
              dataTypeInURL == 'cleansed' ||
              dataTypeInURL == 'enriched')
      ) {
        data_type = dataTypeInURL;
      } else {
        data_type = 'enriched';
      }
    }
    return data_type;
  };

  const value = {
    state,
    dispatch,
    signin,
    signout,
    isLoggedIn,
    hasClubLicense,
    hasFullContributorPermission,
    isPremiumCMO,
    changeDataType,
    getDataType,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};

export const RequireAuth = ({children}) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login/" state={{from: location}}/>;
  }

  return children;
};

export const RequireAuthorizerAuth = ({children, fallback}) => {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isLoggedIn()) {
    return <Navigate to="/login/" state={{from: location}}/>;
  }

  if (!auth.state.user) {
    return '';
  }

  if (auth.state.user.permission_level != 5) {
    return <Navigate to={fallback}/>;
  }

  return children;
};

export default UserContext;
