import {useState} from 'react';
import {getSummaryOfflineVenues} from '../../../api';
import clone from 'clone';
import {Link} from 'react-router-dom';
import {useSummaryReport} from '../../../contexts/SummaryReportContext';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import NeedLicenseButton from '../../organisms/NeedLicenseButton';
import ContentModal from '../../organisms/ContentModal';
import SimpleBar from 'simplebar-react';
import moment from 'moment';

import {useAuth} from '../../../contexts/UserContext';
import styled from 'styled-components';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';
import DBDPullDown from '../../atoms/DBDPullDown';

const Wrap = styled.div`
  height: 113px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  text-align: center;
  position: relative;

  @media print {
    height: 83px;
    h4 {
      margin-top: -15px;
    }

    .light-button {
      display: none;
    }
  }
`;

const DateLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  left: 21px;
  top: calc(34px + 1em + 5px);
  @media print {
    top: calc(34px + 1em + 5px - 15px);
  }
`;

const ValueArea = styled.div`
  text-align: center;
  display: inline-block;
  height: 111px;
  line-height: 111px;
  @media print {
    height: 81px;
    line-height: 81px;
  }

  & > * {
    font-weight: bold;
    font-size: 40px;

    .suffix {
      margin-left: 6px;
      font-size: 14px;
    }
  }
`;

const SortArea = styled.div`
  height: 10px;
  position: absolute;
  top: 35px;
  right: 38px;
  pointer-events: initial;
`;

export default function SummaryReportOffline() {
  const summaryReport = useSummaryReport();
  const auth = useAuth();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemsData, setItemsData] = useState({
    query: {
      type: 'monthly',
      year: 0,
      month: 0,
    },
    items: [],
    itemsDigits: 0,
  });
  const [detailFetched, setDetailFetched] = useState(false);
  const [asc, setAsc] = useState(false);

  function openModal() {
    setIsOpen(true);

    let currentQuery = summaryReport.state.fetched_query;
    if (
        itemsData.query.year != currentQuery.year ||
        itemsData.query.month != currentQuery.month
    ) {
      setDetailFetched(false);
      (async () => {
        try {
          let items = await getSummaryOfflineVenues(currentQuery);
          if (items && items.length) {
            items.forEach((item, i) => {
              let m = moment(item.value);
              let t = m.toDate().getTime();
              item.t = t || (0.00001 * i);

              if (t < 1000) {
                item.text = `Never`;
                item.never = true;
              } else {
                item.text = m.format('YYYY/MM/DD');
                item.never = false;
              }
            });
          }

          setItemsData({
            query: clone(currentQuery),
            items: items,
            itemsDigits: Math.floor(Math.log10(Math.max(1, items.length))) + 1,
          });
          //console.log(items);
        } catch (e) {
          console.error(e);
        }
        setDetailFetched(true);
      })();
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!auth.hasClubLicense()) {
    return (
        <Wrap>
          <ValueArea
              style={{
                opacity: 0.06,
              }}
          >
            <div>
              00 / 00
              <span>ROOMS or FLOORS</span>
            </div>
          </ValueArea>
          <LabelWithInfo
              text={'OFFLINE BEFORE'}
              infoPosition={InfoLinePosition.RightTop}
              style={{
                position: 'absolute',
                left: 20,
                top: 34,
              }}
          >
            <div>
              You can see venues that have been offline for more than 30 days
              before today. (To see this data, you need to submit a list.)
            </div>
          </LabelWithInfo>

          <DateLabel>{moment().
              add(-30, 'days').
              format('YYYY/MM/DD')}</DateLabel>

          <button
              className="light-button"
              style={{
                position: 'absolute',
                right: 20,
                top: '50%',
                marginTop: -20,
                opacity: 0.06,
                pointerEvents: 'none',
              }}
          >
            See all
          </button>
          <NeedLicenseButton/>
        </Wrap>
    );
  }

  if (!summaryReport.state.data || !summaryReport.state.data.offline) {
    return (
        <Wrap>
          <ValueArea>
            <div>
              <HappyNumberLabel text={'0'}/> / <HappyNumberLabel text={'0'}/>
              <span className="suffix">ROOMS or FLOORS</span>
            </div>
          </ValueArea>
          <LabelWithInfo
              text={'OFFLINE BEFORE'}
              infoPosition={InfoLinePosition.RightTop}
              style={{
                position: 'absolute',
                left: 20,
                top: 34,
              }}
          >
            <div></div>
          </LabelWithInfo>
        </Wrap>
    );
  }

  let data = summaryReport.state.data.offline || {};

  let numerator = data.numerator || 0;
  let denominator = data.denominator || 0;
  let beforeM = moment(data.before);

  let items = itemsData.items || [];

  if (asc) {
    items.sort((a, b) => a.t - b.t);
  } else {
    items.sort((a, b) => b.t - a.t);
  }

  return (
      <Wrap>
        <ValueArea>
          <div>
            <HappyNumberLabel text={numerator.toLocaleString('en-US')}/> /{' '}
            <HappyNumberLabel text={denominator.toLocaleString('en-US')}/>
            <span className="suffix">ROOM{numerator != 1
                ? 'S'
                : ''} or FLOOR{numerator != 1 ? 'S' : ''}</span>
          </div>
        </ValueArea>
        <LabelWithInfo
            text={'OFFLINE BEFORE'}
            infoPosition={InfoLinePosition.RightTop}
            style={{
              position: 'absolute',
              left: 20,
              top: 34,
            }}
        >
          <div>
            You can see venues that have been offline for more than 30 days
            before today. Tap ‘View list’ to view the list.
          </div>
        </LabelWithInfo>

        <DateLabel>{beforeM.format('YYYY/MM/DD')}</DateLabel>

        <button
            className="light-button"
            onClick={openModal}
            style={{
              position: 'absolute',
              right: 20,
              top: '50%',
              marginTop: -20,
            }}
        >
          View list
        </button>
        <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
          <div className="fixed-header">
            <h3>VENUES WITH OFFLINE ROOM/FLOOR (Over 30 days)</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                  <span>VENUES</span>
                </div>
                <div
                    className="scroll-header-column last"
                    style={{
                      marginRight: 121,
                    }}
                >
                  <div className="dummy-label">
                    {items.map((item, i) => {
                      return (
                          <div
                              key={i}
                              style={{
                                top: i * -1,
                              }}
                          >
                            {item.text}
                          </div>
                      );
                    })}
                  </div>
                  <span>LAST ONLINE</span>
                </div>
              </div>
            </div>

            <SortArea>
              <DBDPullDown
                  value={asc}
                  onChange={(v) => {
                    setAsc(!!v);
                  }}
                  titleLabel="Sort"
                  items={[
                    {
                      value: 1,
                      label: 'Ascending',
                    },
                    {
                      value: 0,
                      label: 'Descending',
                    },
                  ]}
                  sort={1}
              ></DBDPullDown>
            </SortArea>
          </div>
          <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
            <div
                className={`scroll-content ${
                    !detailFetched || items.length ? 'has-items' : 'no-items'
                }`}
            >
              <table className="scroll-table">
                <tbody>
                {items.map((item, i) => {
                  return (
                      <tr key={i} className="tr">
                        <th>{(i + 1 + '').padStart(itemsData.itemsDigits,
                            '0')}</th>
                        <td className="right-border">{item.venue.name}</td>
                        <td className={`last right-border ${item.never
                            ? 'never'
                            : ''}`}>{item.text}</td>
                        <td className="last button">
                          <Link
                              to={
                                  '/venues/' +
                                  item.venue.club_id +
                                  window.location.search
                              }
                              className="dark-button"
                          >
                            REPORT
                          </Link>
                        </td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </ContentModal>
      </Wrap>
  );
}
