import {useEffect, useState, memo, useMemo, useRef, useCallback} from 'react';
import styled from 'styled-components';

import moment from 'moment';
import './style.scss';
import {useAnimationFrame} from '../../../libs/AnimationManager';

const Wrap = styled.div`
  background-color: #f2f2f2;
  height: 390px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  position: relative;

  @media print {
    background-color: transparent;
  }
`;

const GraphArea = styled.div`
  height: 290px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
`;

const BarArea = styled.div`
  height: 220px;
  width: calc(100% - 100px - 40px);
  min-width: 590px;
  margin: 0 50px 0 auto;
  z-index: 2;
  position: relative;
  //  background-color: pink;
`;

const LabelArea = styled.div`
  height: 50px;
  width: calc(100% - 100px - 40px);
  min-width: 590px;
  margin: 0 50px 0 auto;
  position: relative;
`;

const LabelWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 50px;
  text-align: center;
  padding-top: 10px;
`;

const LabelDay = styled.div`
  font-size: 14px;
  line-height: 14px;
`;

const LabelDoW = styled.div`
  margin-top: 7px;
  font-size: 12px;
  line-height: 12px;
`;

const BackgroundArea = styled.div`
  height: 221px;
  width: calc(100% - 100px);
  min-width: 630px;
  z-index: 0;
  position: absolute;
  right: 30px;
  bottom: 69px;
  border-bottom: 1px solid #D7D7D7;
  border-top: 1px solid #D7D7D7;
  transition: height 0.3s ease-out;

  &::after {
    content: '';
    display: block;
    width: 100%;;
    height: 1px;
    background-color: #D7D7D7;
    position: absolute;
    top: 50%;
  }

  .bar-label {
    position: absolute;
    right: calc(100% + 10px);
    font-size: 14px;
    color: #7B7B7B;
    top: 0;
    margin-top: -0.8em;
    opacity: 0;
    //    transition: opacity 0.15s linear;

    &.visible {
      opacity: 1;
    }

    &.middle {
      top: 50%;
    }

    &.bottom {
      top: 100%;
    }
  }
`;

const GRAPH_BAR_HEIGHT = 220;

function valuesHash(values) {
  let h = 0;
  if (values && Array.isArray(values)) {
    h = values.reduce((p, c) => {
      let tmp = (31 * p + c.date.getTime()) >>> 0;
      return (31 * tmp + c.value) >>> 0;
    }, h);
  }
  return h;
}

function boundsStr(boundNum, unit) {

  if (unit === 'hours') {
    let h = Math.floor(boundNum);
    if (h < 1000) {
      return `${h}h`;
    }
    let num = Math.floor(h / 10) / 100;
    if (num >= 100) {
      num = Math.floor(num);
    } else if (num >= 10) {
      num = Math.floor(num * 10) / 10;
    }
    return `${num}k h`;
  } else {
    if (boundNum < 1000) {
      return `${boundNum}`;
    }
    let num = Math.floor(boundNum / 10) / 100;
    if (num >= 100) {
      num = Math.floor(num);
    } else if (num >= 10) {
      num = Math.floor(num * 10) / 10;
    }
    return `${num}k`;
  }
}

const GraphBar = ({
  x,
  width,
  zoom,
  value,
  index,
  onMouseEnter,
  onMouseLeave,
}) => {
  const height = value * GRAPH_BAR_HEIGHT;
  return (
      <rect
          onMouseEnter={() => {
            onMouseEnter(index);
          }}
          onMouseLeave={() => {
            onMouseLeave(index);
          }}
          fill="url(#Gradient1)"
          x={x}
          y={GRAPH_BAR_HEIGHT - height * zoom}
          width={width}
          height={height * zoom}
      ></rect>
  );
};

const GraphLabel = ({x, date, index, onMouseEnter, onMouseLeave}) => {
  const [d, dow, downum] = useMemo(() => {
    let m = moment.utc(date);
    return [m.format('D'), m.format('dd')[0], parseInt(m.format('d'), 10)];
  }, [date]);

  return (
      <LabelWrap
          style={{
            left: x,
            fontWeight: downum == 0 || downum == 6 ? 'bold' : 'normal',
          }}
          onMouseEnter={() => {
            onMouseEnter(index);
          }}
          onMouseLeave={() => {
            onMouseLeave(index);
          }}
      >
        <LabelDay>{d}</LabelDay>
        <LabelDoW>{dow}</LabelDoW>
      </LabelWrap>
  );
};

const AnimatedGraph = memo(({displayState, unit, bounds}) => {
  //const [zoom, setZoom] = useState(0);
  const [zoomCursor, setZoomCursor] = useState(
      1,
  );
  //console.log(zoomCursor);
  const areaRef = useRef();
  const timestampRef = useRef(0);
  const [areaWidth, setAreaWidth] = useState(
      Math.min(Math.max(960, window.innerWidth), 1900) - 189 - 40 - 80 - 60,
  );
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const animate = useCallback(
      (timestamp) => {
        if (!timestampRef.current) {
          timestampRef.current = timestamp;
          return;
        }
        let delta = Math.min(timestamp - timestampRef.current, 50);
        timestampRef.current = timestamp;
        switch (displayState.state) {
          default:
            //        setZoom(0);
            setZoomCursor(0);
            break;
          case 'shown': {
            //          setZoom(1);
            setZoomCursor(1);
          }
            break;
          case 'showing': {
            setZoomCursor((prev) => Math.min(1, prev + delta / 400));
            //setZoom((prev) => Math.min(1, prev + delta / 200));
          }
            break;
          case 'hiding': {

            setZoomCursor((prev) => Math.max(0, prev - delta / 300));
            //setZoom((prev) => Math.max(0, prev - delta / 200));
          }
        }
      },
      [displayState],
  );
  //useState(() => {
  //console.log(displayState.state);
  //}, [displayState.state]);

  useAnimationFrame(
      1/*displayState.state == "showing" || displayState.state == "hiding"*/,
      animate,
  );

  const zoom = useMemo(() => {
    //console.log(zoomCursor);
    return 1 - (1 - zoomCursor) * (1 - zoomCursor);
  }, [zoomCursor]);

  const [itemWidth, itemOffset, itemWidthP, itemOffsetP] = useMemo(() => {
    if (!displayState.values.length || !areaWidth) {
      return [1, 1, 1, 1];
    }

    const minWidth = 590;
    const vWidth = (areaWidth / displayState.values.length) * 31;
    const vD = vWidth / minWidth;

    return [
      10 * vD,
      (10 + 9.3) * vD,
      ((10 / minWidth) * 100 * 31) / displayState.values.length,
      ((19.3 / minWidth) * 100 * 31) / displayState.values.length,
    ];
  }, [areaWidth, displayState.values]);

  useEffect(() => {
    let cb = () => {
      setTimeout(() => {
        if (!areaRef.current || !areaRef.current.clientWidth) {
          return;
        }
        setAreaWidth(areaRef.current.clientWidth);
      }, 100);
    };
    window.addEventListener('resize', cb);
    cb();
    return () => {
      window.removeEventListener('resize', cb);
    };
  }, []);

  const onMouseEnter = (index) => {
    setHoveredIndex(index);
    //  console.log(index, "enter", value);
  };
  const onMouseLeave = (index) => {
    //    console.log(index, "out");
    setHoveredIndex(-1);
  };

  const previewElm = useMemo(() => {
    if (0 > hoveredIndex) {
      return '';
    }
    const value =
        (displayState.values &&
            displayState.values.length &&
            displayState.values.length > hoveredIndex &&
            displayState.values[hoveredIndex]) ||
        null;
    if (!value) {
      return '';
    }

    let displayValue = value.value || 0;
    let unitLabel = window.getUnitLabel(unit, value.value || 0).toLowerCase();
    if (unit == 'hours') {
      let _h = Math.floor(displayValue / 3600);
      let _m = Math.floor((displayValue - _h * 3600) / 60);

      displayValue = `${(_h + '').padStart(2, '0')}h ${(_m + '').padStart(
          2,
          '0',
      )}m`;
      unitLabel = '';
    } else {
      displayValue = displayValue.toLocaleString('en-US');
    }

    if (value.na) {
      //      unitLabel = "";
      //      displayValue = "N/A";
    }

    return (
        <>
          <div className="preview-content">
            <div className="preview-date">
              {moment(value.date).utc().format('YYYY-MM-DD ddd').toUpperCase()}
            </div>
            <div className="preview-value">
              <b>{displayValue}</b>
              <span className="unit">{unitLabel}</span>
            </div>
          </div>
        </>
    );
  }, [hoveredIndex, displayState, unit]);

  return (
      <>
        <BackgroundArea className="background-area"
                        style={{
                          height: GRAPH_BAR_HEIGHT * bounds.maxBoundPos + 1,
                        }}
        >
          {
            bounds.maxBound > 1 ? (
                <div className={`bar-label top ${displayState.state == 'shown'
                    ? 'visible'
                    : ''}`}>{boundsStr(bounds.maxBound, unit)}</div>
            ) : ''
          }
          {
            bounds.maxBound > 1 ? (
                <div
                    className={`bar-label middle ${displayState.state == 'shown'
                        ? 'visible'
                        : ''}`}>{boundsStr(bounds.maxBound / 2, unit)}</div>
            ) : ''
          }
          {
            bounds.maxBound > 1 ? (
                <div
                    className={`bar-label bottom ${displayState.state == 'shown'
                        ? 'visible'
                        : ''}`}>0</div>
            ) : ''
          }
        </BackgroundArea>
        <div
            className={'preview-box ' + (15 > hoveredIndex ? 'start' : 'end')}
            style={{
              left:
                  hoveredIndex >= 0
                      ? Math.round(itemWidth / 2 + hoveredIndex * itemOffset + 50)
                      : 0,
              display: hoveredIndex >= 0 ? 'block' : 'none',
            }}
        >
          {previewElm}
        </div>
        <div
            className="preview-bar"
            style={{
              left:
                  hoveredIndex >= 0
                      ? Math.round(itemWidth / 2 + hoveredIndex * itemOffset + 50)
                      : 0,
              display: hoveredIndex >= 0 ? 'block' : 'none',
            }}
        ></div>
        <BarArea ref={areaRef} className="bar-area">
          <svg width="100%" height={GRAPH_BAR_HEIGHT} version="1.1">
            <defs>
              <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
                <stop stopColor="#007DE1" offset="0%"/>
                <stop stopColor="#BDDAF0" offset="100%"/>
              </linearGradient>
            </defs>
            {displayState.values.map((value, i) => {
              return (
                  <GraphBar
                      index={i}
                      key={i}
                      x={`${itemOffsetP * i}%`}
                      width={`${itemWidthP}%`}
                      zoom={zoom * bounds.yZoom}
                      value={value.v}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                  />
              );
            })}
          </svg>
        </BarArea>
        <LabelArea className="label-area">
          {displayState.values.map((value, i) => {
            return (
                <GraphLabel
                    index={i}
                    key={i}
                    //x={40 + itemWidth / 2 + i * itemOffset - 10}
                    x={`calc(${itemOffsetP * i + itemWidthP / 2}% - 10px)`}
                    date={value.date}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            );
          })}
        </LabelArea>
      </>
  );
});

const BarGraphCore = memo(({values, hash, unit, bounds}) => {
  // hidden => showing => shown => hiding
  let [displayState, setDisplayState] = useState({
    state: 'initial',
    values: [],
  });

  //console.log(displayState);

  const transition = useRef({
    working: false,
    from: 0,
    to: 0,
  });

  function startTransiton(nextHash) {
    //console.log("current", displayState, nextHash);
    switch (displayState.state) {
      default:
        break;
      case 'initial':
      case 'hidden': {
        if (nextHash == 0) {
          //console.log("set", "shown");
          setDisplayState({
            state: 'shown',
            values: values,
          });
        } else {
          transition.current.working = true;
          transition.current.to = nextHash;
          //setCurrentValues(values);
          //console.log("set", "showing");
          setDisplayState({
            state: 'showing',
            values,
          });
          setTimeout(() => {
            transition.current.working = false;
            //console.log("set", "shown");
            setDisplayState((prev) => {
              return {
                ...prev,
                state: 'shown',
              };
            });

            if (transition.current.to == hash) {
              return;
            }
            startTransiton(hash);
          }, 500);
        }
      }
        break;
      case 'shown': {
        if (!valuesHash(displayState.values)) {
          //console.log("set", "hidden");
          setDisplayState((prev) => {
            return {
              ...prev,
              state: 'hidden',
            };
          });
        } else {
          transition.current.working = true;
          transition.current.to = nextHash;
          //console.log("set", "hiding");

          setDisplayState((prev) => {
            return {
              ...prev,
              state: 'hiding',
            };
          });

          setTimeout(() => {
            transition.current.working = false;
            //console.log("set", "hidden");
            setDisplayState((prev) => {
              return {
                ...prev,
                state: 'hidden',
              };
            });
          }, 500);
        }
      }
        break;
    }
  }

  useEffect(() => {
    //console.log(displayState, currentValues);
    if (displayState.state == 'hidden') {
      setTimeout(() => {
        startTransiton(hash);
      }, 10);
    }
  }, [displayState]);

  useEffect(() => {
    if (transition.current.working) {
      return;
    }
    startTransiton(hash);
  }, [hash]);

  //  console.log(values, hash);
  return (
      <Wrap className="bargraph-wrap">
        <GraphArea>
          <AnimatedGraph displayState={displayState} unit={unit}
                         bounds={bounds}/>
        </GraphArea>
      </Wrap>
  );
});

function NiceScale(lowerBound, upperBound, _maxTicks) {

  let maxTicks = _maxTicks || 10;
  let tickSpacing;
  let range;
  let niceLowerBound;
  let niceUpperBound;

  calculate();

  this.setMaxTicks = function(_maxTicks) {
    maxTicks = _maxTicks;
    calculate();
  };

  this.getNiceUpperBound = function() {
    return niceUpperBound;
  };

  this.getNiceLowerBound = function() {
    return niceLowerBound;
  };

  this.getTickSpacing = function() {
    return tickSpacing;
  };

  function setMinMaxPoints(min, max) {
    lowerBound = min;
    upperBound =
        max;
    calculate();
  }

  function calculate() {
    range = niceNum(upperBound - lowerBound, false);
    tickSpacing = niceNum(range / (maxTicks - 1), true);
    niceLowerBound = Math.floor(lowerBound / tickSpacing) * tickSpacing;
    niceUpperBound = Math.ceil(upperBound / tickSpacing) * tickSpacing;
  }

  function niceNum(range, round) {
    const exponent = Math.floor(Math.log10(range));
    const fraction = range / Math.pow(10, exponent);
    let niceFraction;

    if (round) {
      if (fraction < 1.5) niceFraction = 1;
      else if (fraction < 3) niceFraction = 2;
      else if (fraction < 7) niceFraction = 5;
      else niceFraction = 10;
    } else {
      if (fraction <= 1) niceFraction = 1;
      else if (fraction <= 2) niceFraction = 2;
      else if (fraction <= 5) niceFraction = 5;
      else niceFraction = 10;
    }

    return niceFraction * Math.pow(10, exponent);
  }
}

export default function BarGraph({values, unit}) {
  //values.length && (values[0].value = 1020);

  let [currentValues, setCurrentValues] = useState([]);
  let [currentHash, setCurrentHash] = useState(0);
  let [currentBounds, setCurrentBounds] = useState({
    maxValue: 0,
    yZoom: 1,
    maxBound: 1,
    maxBoundPos: 1,
  });

  let hash = valuesHash(values);

  let maxValue = 0;
  values.forEach((s) => {
    maxValue = Math.max(maxValue, s.value);
    s.v = 0;
  });
  if (maxValue) {
    values.forEach((s) => {
      s.v = s.value / maxValue;
    });
  }

  useEffect(() => {
    setCurrentValues(values);
    setCurrentHash(hash);

    let yZoom = 1;
    let isTime = unit === 'hours';
    let maxValue = (values || []).reduce((p, c) => {
      if (isTime) {
        return Math.max(p, c.value / 3600);
      }
      return Math.max(p, c.value);
    }, 0);
//    maxValue = 301;
    let maxBound = maxValue;
    let maxBoundPos = 1;
    let v = maxValue;
    if (v > 1000) {
      v = myRound(v / 1000) * 1000;
    } else if (v > 100) {
      v = myRound(v / 100) * 100;
    } else if (v > 25) {
      v = myRound(v / 10) * 10;
    } else if (v > 13) {
      v = 20;
    } else if (v > 8) {
      v = 10;
    } else if (v > 0) {
      if (isTime) {
        // 8時間以下。小数点もありえる
        if (v >= 1) {
          // 1時間以上
          v = Math.max(2, myRound(v));
        } else {
          // 0分以上1時間未満
          v = 2;
        }
      } else {
        if (v % 2 === 1) {
          v += 1;
        }
      }
    }
    if (v) {
      const s = new NiceScale(0, v, 40);
      maxBound = s.getNiceUpperBound();

    }
    if (maxBound > maxValue) {
      // グラフの上限を下げる
      yZoom = maxValue / maxBound;
      maxBoundPos = 1;
    } else if (maxBound < maxValue) {
      // バーを下げる
      yZoom = 1;
      maxBoundPos = maxBound / maxValue;
    } else if (maxValue == 0) {
      yZoom = 1;
      maxBound = 1;
      maxBoundPos = 1;
    }

    console.log({
      maxValue,
      yZoom,
      maxBound,
      maxBoundPos,
    });
    setCurrentBounds({
      maxValue,
      yZoom,
      maxBound,
      maxBoundPos,
    });
//    const scale = new NiceScale()
  }, [hash]);

  return <BarGraphCore values={currentValues} hash={currentHash} unit={unit}
                       bounds={currentBounds}/>;
}

function myRound(x) {
  return (Math.floor(x * 10) % 10) >= 3 ? Math.ceil(x) : Math.floor(x);
}