import {useState, useRef, useEffect} from 'react';
import {useAuth} from '../../../contexts/UserContext';
import ContentModal from '../../organisms/ContentModal';
import {Link} from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import {useNavigate} from 'react-router-dom';
import './style.scss';

export default function VenueListModal({closeModal, isOpen}) {
  const auth = useAuth();
  const queryRef = useRef();
  const timerRef = useRef(null);
  const [query, setQuery] = useState('');
  const [query2, setQuery2] = useState('');
  const [venues, setVenues] = useState([]);
  const navigate = useNavigate();

  const onQueryChange = function() {
    setQuery(queryRef.current.value.trim());
  };

  const onQueryClearClicked = function() {
    queryRef.current.value = '';
    setQuery('');
    setQuery2('');
  };

  useEffect(() => {
    setQuery('');
    setQuery2('');
  }, [isOpen]);

  useEffect(() => {
    setVenues(auth.state.venues);
  }, [auth.state]);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    let currentQuery = (query || '').trim().toLowerCase();
    timerRef.current = setTimeout(() => {
      setQuery2(currentQuery);
    }, 200);
  }, [query]);

  useEffect(() => {
    if (!query2) {
      setVenues(auth.state.venues || []);
    } else {
      setVenues(
          (auth.state.venues || []).filter((v) => {
            //console.log(v, query2);
            return v.name.toLowerCase().indexOf(query2) != -1;
          }),
      );
    }
  }, [query2]);

  const onLink = (club_id) => {
    navigate('/venues/' + club_id + window.location.search);
    closeModal();
    return false;
  };

  return (
      <ContentModal closeModal={closeModal} isOpen={isOpen}>
        <div className="fixed-header venues-list">
          <h3>VENUES</h3>
          <div className="scroll-header">
            <div className="scroll-header-columns">
              <div className="scroll-header-column">
                <span>VENUES ( A to Z )</span>
              </div>
            </div>
          </div>
          <div className="venues-list-serch-box">
            <input
                ref={queryRef}
                type="text"
                name="venuelist-query"
                placeholder="SEARCH"
                onChange={onQueryChange}
            ></input>
            <button onClick={onQueryClearClicked}></button>
          </div>
        </div>

        <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
          <div className={`scroll-content ${venues.length
              ? 'has-items'
              : 'no-items'}`}>
            <table className="scroll-table">
              <tbody>
              {venues.map((venue, i) => {
                return (
                    <tr key={i} className="tr">
                      <td>{venue.name}</td>
                      <td className="last button">
                        <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              onLink(venue.club_id);
                              return false;
                            }}
                            className="dark-button"
                        >
                          REPORT
                        </a>
                      </td>
                    </tr>
                );
              })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
      </ContentModal>
  );
}
