import {useState} from 'react';
import {getVenueTracks} from '../../../api';
import ContentModal from '../../organisms/ContentModal';
import clone from 'clone';
import {useVenueReport} from '../../../contexts/VenueReportContext';
import StatArea, {DefaultStatsLebel} from '../../organisms/StatArea';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import SimpleBar from 'simplebar-react';
import styled from 'styled-components';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';

const ValueStr = styled.div`
  font-size: 60px;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 27px;
`;

export default function VenueReportTracks() {
  const venueReport = useVenueReport();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemsData, setItemsData] = useState({
    club_id: 0,
    query: {
      type: 'monthly',
      year: 0,
      month: 0,
      data: '',
    },
    items: [],
  });
  const [detailFetched, setDetailFetched] = useState(false);

  function openModal() {
    setIsOpen(true);

    let currentQuery = venueReport.state.fetched_query;
    if (
        itemsData.club_id != venueReport.state.club_id ||
        itemsData.query.year != currentQuery.year ||
        itemsData.query.month != currentQuery.month ||
        itemsData.query.data != currentQuery.data
    ) {
      setDetailFetched(false);
      (async () => {
        try {
          let items = await getVenueTracks(
              venueReport.state.club_id,
              currentQuery,
          );
          setItemsData({
            club_id: venueReport.state.club_id,
            query: clone(currentQuery),
            items: items,
          });
          console.log(items);
        } catch (e) {
          console.error(e);
        }
        setDetailFetched(true);
      })();
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (!venueReport.state.data) {
    return (
        <>
          <StatArea
              labelWithInfo={
                <LabelWithInfo
                    text={'TRACKS REPORTED'}
                    infoPosition={InfoLinePosition.RightBottom}
                >
                  <div></div>
                </LabelWithInfo>
              }
              delta={0}
              button={null}
          >
            <ValueStr>
              <HappyNumberLabel text={'0'}/>
            </ValueStr>
            <DefaultStatsLebel text={`tracks`}/>
          </StatArea>
        </>
    );
  }

  let data = venueReport.state.data.tracks;

  let last = (data.previous && data.previous.numerator) || 0;
  let current = (data.current && data.current.numerator) || 0;

  let diff = 0;
  if (last != 0) {
    diff = Math.round((current / last - 1.0) * 100);
  }

  return (
      <>
        <StatArea
            labelWithInfo={
              <LabelWithInfo
                  text={'TRACKS REPORTED'}
                  infoPosition={InfoLinePosition.RightBottom}
              >
                <div>
                  You can see the total number of tracks played in the venue in
                  the
                  selected period. Tap ‘See more’ to view the top songs.
                </div>
              </LabelWithInfo>
            }
            delta={diff}
            button={
              <button className="light-button" onClick={openModal}>
                See more
              </button>
            }
        >
          <ValueStr>
            <HappyNumberLabel text={current.toLocaleString('en-US')}/>
          </ValueStr>
          <DefaultStatsLebel text={`track${current != 1 ? 's' : ''}`}/>
        </StatArea>

        <ContentModal closeModal={closeModal} isOpen={modalIsOpen}>
          <div className="fixed-header">
            <h3>TOP 10 TRACKS PLAYED</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                  <span>TRACK / ARTIST</span>
                </div>
                <div className="scroll-header-column last">
                  <div className="dummy-label">
                    {itemsData.items.map((item, i) => {
                      return (
                          <div
                              key={i}
                              style={{
                                top: i * -1,
                              }}
                          >
                            {item.value}
                          </div>
                      );
                    })}
                  </div>
                  <span>PLAYED</span>
                </div>
              </div>
            </div>
          </div>
          <SimpleBar forceVisible="y" autoHide={false} className="scroll-area">
            <div
                className={`scroll-content ${
                    !detailFetched || itemsData.items.length
                        ? 'has-items'
                        : 'no-items'
                }`}
            >
              <table className="scroll-table">
                <tbody>
                {itemsData.items.map((item, i) => {
                  let names = [];
                  if (item.track.title) {
                    names.push(item.track.title);
                  }
                  if (item.track.artist) {
                    names.push(item.track.artist);
                  }
                  return (
                      <tr key={i} className="tr">
                        <th>{(i + 1 + '').padStart(2, '0')}</th>
                        <td className="right-border">{names.join(' / ')}</td>
                        <td className="last">{item.value}</td>
                      </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </SimpleBar>
        </ContentModal>
      </>
  );
}
