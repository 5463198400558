import React from 'react';
import ReactDOM from 'react-dom';
import './destyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import 'simplebar/dist/simplebar.min.css';

import App from './routings/App';
import {BrowserRouter} from 'react-router-dom';

ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App/>
    </BrowserRouter>,
    document.getElementById('root'),
);
