import {useState, useRef, useEffect, useMemo} from 'react';

import {useSearchParams, useParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import clone from 'clone';

import {
  VenueReportContextProvider,
  useVenueReport,
  RequirePermission,
} from '../../../contexts/VenueReportContext';

import ContentHeader from '../../organisms/ContentHeader';
import VenueReportDevices from './VenueReportDevices';
import VenueReportTracks from './VenueReportTracks';
import VenueReportOffline from './VenueReportOffline';
import VenueReportGraph from './VenueReportGraph';
import VenueReportDisplayMenu from './VenueReportDisplayMenu';
import VenueDownloadButton from './VenueDeownloadButton';
import ReportQuerySelector from '../../organisms/ReportQuerySelector';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import {useAuth} from '../../../contexts/UserContext';

const SelectorWrap = styled.div`
  display: flex;
`;

export const SelectorItem = styled.div`
  margin-right: 20px;
`;

const StatsBlockWrap = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 40px;
  @media print {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

const StatsBlock = styled.div`
  flex: 1 1 33%;
  text-align: center;
  //margin-right: 40px;

  //&:last-child {
  //  margin-right: 0px;
  //}
  @media screen and (max-width: 1100px) {
    flex: 1 1 calc(50% - 20px);
  }

  @media print {
    margin-right: 10px;
  }
`;

function getValidYearMonthRange() {
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;

  return {
    min: {
      year: 2017,
      month: 1,
      monthID: 2017 * 12 + 1,
    },
    max: {
      year,
      month,
      monthID: year * 12 + month,
    },
  };
}

function PeriodSelector({query, onChange}) {
  const onQueryChanged = (newQuery) => {
    onChange(clone(newQuery));
  };

  return (
      <SelectorItem
          style={{
            flexShrink: 0,
          }}
      >
        <LabelWithInfo
            text={'PERIOD'}
            infoPosition={InfoLinePosition.RightTop}
            style={{
              marginBottom: 20,
            }}
        >
          <div>Select the report term to display.</div>
        </LabelWithInfo>
        <ReportQuerySelector query={query} onChange={onQueryChanged}/>
      </SelectorItem>
  );
}

let defaultYear = new Date().getFullYear();
let defaultMonth = new Date().getMonth() + 1;
defaultMonth--;
if (defaultMonth == 0) {
  defaultMonth = 12;
  defaultYear--;
}

function VenueReportPageContent({club_id}) {
  const venueReport = useVenueReport();
  const auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = useState({
    type: 'monthly',
    year: defaultYear,
    month: defaultMonth,
    data: auth.getDataType(),
  });

  const changeTimerRef = useRef(null);

  let type = searchParams.get('type') || 'monthly';
  let year = searchParams.get('year') || 0;
  let month = searchParams.get('month') || 0;
  let data = auth.getDataType();

  if (type != 'monthly') {
    type = 'monthly';
  }
  if (year) {
    year = parseInt(year, 10);
    if (!year || year < 2017 || new Date().getFullYear() < year) {
      year = 0;
    }
  }
  if (month) {
    month = parseInt(month, 10);
    if (!month || month < 1 || 12 < month) {
      month = 0;
    }
  }
  let monthID = year * 12 + month;
  let validRange = getValidYearMonthRange();

  if (monthID < validRange.min.monthID) {
    year = defaultYear;
    month = defaultMonth;
  }

  if (query.type != type || query.year != year || query.month != month ||
      query.data != data) {
    setQuery({
      type,
      year,
      month,
      data,
    });
  }

  const updateParams = () => {
    if (
        query.year != searchParams.get('year') ||
        query.month != searchParams.get('month') ||
        query.data != auth.getDataType()
    ) {
      setSearchParams(
          {
            type: query.type,
            year: query.year,
            month: query.month,
            data: query.data,
          },
          {replace: !searchParams.get('year')},
      );
    }
  };
  useEffect(() => {
    updateParams();

    //console.log(venueReport);
    if (changeTimerRef.current) {
      clearTimeout(changeTimerRef.current);
    }
    changeTimerRef.current = setTimeout(() => {
      venueReport.doQuery(club_id, query);
    }, 100);
  }, [club_id, query]);

  useEffect(() => {
    document.title = 'Report | DASHBOARD';
  }, []);

  const onQueryChanged = (newQuery) => {
    console.log('new Query', newQuery);
    setSearchParams(
        {
          type: newQuery.type,
          year: newQuery.year,
          month: newQuery.month,
          data: query.data,
        },
        {replace: false},
    );
    setQuery({
      ...newQuery,
    });
  };

  return (
      <>
        <ContentHeader
            title={'REPORT'}
            subtitle={
                (venueReport.state.data &&
                    venueReport.state.data.venue &&
                    venueReport.state.data.venue.name) ||
                ''
            }
            rightTopElem={
              <VenueDownloadButton
                  vanueName={
                      venueReport.state.data &&
                      venueReport.state.data.venue &&
                      venueReport.state.data.venue.name
                  }
              />
            }
            children={
              <SelectorWrap>
                <PeriodSelector query={query} onChange={onQueryChanged}/>
                <VenueReportDisplayMenu/>
              </SelectorWrap>
            }
        />

        <StatsBlockWrap>
          <StatsBlock>
            <VenueReportDevices/>
          </StatsBlock>
          <StatsBlock>
            <VenueReportTracks/>
          </StatsBlock>
          <StatsBlock>
            <VenueReportOffline/>
          </StatsBlock>
        </StatsBlockWrap>

        <VenueReportGraph/>
      </>
  );
}

export default function VenueReportPage() {
  let {club_id} = useParams();
  const auth = useAuth();
  if (!auth.state?.user?.cmo) {
    return '';
  }

  return (
      <VenueReportContextProvider>
        <RequirePermission>
          <VenueReportPageContent club_id={club_id}/>
        </RequirePermission>
      </VenueReportContextProvider>
  );
}
