import React from 'react';
import {Dropdown} from 'react-bootstrap';
import './style.scss';

export default function DBDPullDown({
  value, items, onChange,
  titleLabel = null,
  defaultLabel = null,
  ...props
}) {
  const currentItem = (items || []).find((item) => item.value == value) || null;

  const onItemClick = (item) => {
    setTimeout(() => {
      onChange && onChange(item.value);
    }, 100);
  };

  return (
      <>
        <div className="DBDPullDown" {...props}>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="front-label">
                {titleLabel ||
                    (currentItem && currentItem.label) ||
                    defaultLabel ||
                    ' '}

                <div className="dummy-label">
                  {items.filter((item) => !item.hidden).map((item, i) => {
                    let classes = ['dummy-item'];
                    return (
                        <div key={i} className={classes.join(' ')}>
                          {item.label}
                        </div>
                    );
                  })}
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {items.filter((item) => !item.hidden).map((item, i) => {
                return (
                    <Dropdown.Item
                        onClick={() => {
                          onItemClick(item);
                        }}
                        key={i}
                        active={currentItem && currentItem.value == item.value}
                        disabled={item.disabled}
                    >
                      {item.label}
                    </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
  );
}
