import React, {createContext, useEffect, useReducer, useContext} from 'react';
import {getSummary} from '../../api';

const SummaryReportContext = createContext();

const initialState = {
  query: {
    type: 'monthly',
    year: 0,
    month: 0,
    data: 'raw',
  },
  data: null,
  fetched_query: null,
};

function reducer(state, action) {
  console.log(state, action);
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
        data: null,
      };
    }
    case 'set_summary_data': {
      const newState = {
        ...state,
        data: action.data,
        fetched_query: action.query,
      };
      return newState;
    }
    default:
      throw new Error(`${action.type} is not defined.`);
  }
}

export const SummaryReportContextProvider = ({api, children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  let doQuery = async (query) => {

    if (!query.data) {
      query.data = 'raw';
    }

    if (
        state.fetched_query &&
        state.data &&
        state.fetched_query.year == query.year &&
        state.fetched_query.month == query.month &&
        state.fetched_query.data == query.data
    ) {
      return;
    }

    let t0 = Date.now();
    dispatch({
      type: 'init',
      query,
    });
    try {
      const data = await getSummary(query);

      let t1 = Date.now();
      let td = t1 - t0;
      const MIN_DELAY = 400;
      if (0 && td < MIN_DELAY) {
        await new Promise((resolve) => {
          setTimeout(resolve, MIN_DELAY - td);
        });
      }

      dispatch({
        type: 'set_summary_data',
        data,
        query,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const value = {state, dispatch, doQuery};

  return (
      <SummaryReportContext.Provider value={value}>
        {children}
      </SummaryReportContext.Provider>
  );
};

export const useSummaryReport = () => {
  return useContext(SummaryReportContext);
};

export default SummaryReportContext;
