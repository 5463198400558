import './SvgLogo.scss';

function SvgLogo(props) {
  let host = window.location.hostname;
  let stg = false;
  if (/local/.test(host) || /stg/.test(host)) {
    stg = true;
    console.log(`Staging mode`);
  }

  return (
      <div className={`logo_svg_wrap${stg ? ' stg' : ''}`}>
        <svg
            id="logo_svg"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 154 65"
            xmlSpace="preserve"
            {...props}
        >
          <g id="gl_565" transform="translate(-130 -257)">
            <g id="gl_564" transform="translate(130 257)">
              <g id="gl_560" transform="translate(119.888 0)">
                <path id="path_183" d="M25.22,5.92c-0.29,0-0.58,0.1-0.79,0.3c-0.24,0.22-0.39,0.51-0.44,0.82l-0.71,4.02
				c0.32,0.22,0.7,0.33,1.09,0.31c0.33,0.01,0.65-0.1,0.91-0.3c0.24-0.19,0.41-0.47,0.46-0.78c0.08-0.3,0.03-0.62-0.13-0.89
				c-0.2-0.23-0.5-0.35-0.8-0.32H24.3l0.2-1.12h0.41c0.61-0.02,0.97-0.38,1.1-1.07c0.06-0.24,0.01-0.5-0.14-0.7
				C25.71,6.01,25.47,5.91,25.22,5.92z"/>
                <path id="path_184" d="M24.52,0.93c-4.69,0-8.48,3.8-8.48,8.48s3.8,8.48,8.48,8.48c4.69,0,8.48-3.8,8.48-8.48
				S29.21,0.93,24.52,0.93L24.52,0.93z M27.56,6.78c-0.06,0.35-0.21,0.68-0.43,0.96c-0.23,0.29-0.53,0.53-0.87,0.69
				c0.37,0.12,0.68,0.37,0.88,0.71c0.18,0.37,0.23,0.78,0.14,1.18c-0.09,0.66-0.42,1.26-0.93,1.69c-0.52,0.41-1.17,0.63-1.84,0.61
				c-0.5,0.02-1-0.12-1.42-0.4l-0.43,2.45h-1.53l1.34-7.63c0.08-0.44,0.27-0.85,0.55-1.2c0.29-0.36,0.66-0.65,1.08-0.84
				c0.42-0.2,0.89-0.3,1.36-0.3c0.62-0.05,1.24,0.15,1.72,0.55C27.55,5.65,27.7,6.23,27.56,6.78z"/>
              </g>
              <g id="gl_563" transform="translate(0 11.225)">
                <g id="gl_561">
                  <path id="path_185" d="M98.33,1.67h5.21c-0.05,0.18-0.11,0.34-0.17,0.49c-3,8.45-6,16.91-9.01,25.36
					c-0.07,0.21-0.15,0.42-0.24,0.62c-0.61,1.89-2.48,3.09-4.45,2.86c-2.05,0.12-3.89-1.24-4.38-3.23c-0.87-2.39-1.72-4.8-2.57-7.2
					c-2.17-6.08-4.34-12.17-6.51-18.25c-0.07-0.19-0.12-0.38-0.2-0.63h5.32c0.19,0.54,0.39,1.11,0.58,1.68
					c2.47,7.31,4.94,14.62,7.42,21.93c0.06,0.21,0.14,0.42,0.24,0.62c0.09,0.22,0.34,0.32,0.56,0.22c0.11-0.05,0.19-0.13,0.23-0.24
					c0.07-0.15,0.13-0.31,0.17-0.47c2.53-7.73,5.07-15.47,7.6-23.21C98.19,2.06,98.25,1.89,98.33,1.67z"/>
                  <path id="path_186" d="M16.04,1.68h4.78V30.8h-4.78V1.68z"/>
                  <path id="path_187" d="M70.98,11.32c0-0.26-0.02-0.52-0.05-0.78l0,0c-0.16-1.32-1.37-2.26-2.69-2.1
					c-1.19,0.15-2.09,1.14-2.12,2.33l0,0l-0.01,8.55c-0.12,4.13-3.57,7.38-7.7,7.26c-2.26-0.07-4.37-1.15-5.74-2.96
					c-0.91-1.32-1.37-2.89-1.31-4.49c0.01-5.6,0.01-11.2,0-16.79V1.69h-4.87v0.58c0,5.62,0,11.23,0,16.85c0,0.61,0.03,1.22,0.1,1.82
					c0.34,3.54,2.36,6.71,5.44,8.5c2.41,1.41,5.22,2.01,8,1.7c2.83-0.17,5.51-1.35,7.55-3.33c2.26-2.22,3.49-5.28,3.4-8.45
					L70.98,11.32z"/>
                  <circle id="楕円形_26" cx="68.5" cy="4.15" r="2.43"/>
                  <path id="path_188" d="M131.54,5.37c-4.59-4.58-11.58-5.74-17.4-2.89l2.07,4.42c5.07-2.55,11.26-0.51,13.81,4.57
					c2.55,5.07,0.51,11.26-4.57,13.81c-5.07,2.55-11.26,0.51-13.81-4.57c-0.5-0.99-0.83-2.06-0.99-3.16l0,0
					c-0.17-1.33-1.4-2.27-2.73-2.1c-1.33,0.17-2.27,1.4-2.1,2.73c0.01,0.04,0.01,0.08,0.02,0.11l0,0c1.22,8.28,8.92,14,17.2,12.78
					c8.28-1.22,14-8.92,12.78-17.2c-0.48-3.22-1.98-6.2-4.28-8.51L131.54,5.37z"/>
                  <circle id="楕円形_27" cx="108.7" cy="11.07" r="2.46"/>
                  <path id="path_189" d="M40.89,30.82l-0.19-0.23c-0.08-0.1-0.14-0.18-0.21-0.26c-2.66-3.12-5.33-6.23-7.99-9.35
					c-1.25-1.46-2.5-2.91-3.73-4.39c-0.45-0.37-0.52-1.04-0.15-1.49c0.05-0.06,0.11-0.12,0.17-0.17c0.08-0.1,0.17-0.2,0.26-0.29
					c3.68-4.16,7.36-8.33,11.04-12.49c0.12-0.13,0.22-0.27,0.38-0.47h-5.99c-2.02,2.3-4.04,4.6-6.04,6.88
					c-1.34,1.53-2.71,3.03-4,4.6c-1.74,2.12-1.71,3.82,0.07,5.91c3.27,3.84,6.58,7.64,9.87,11.46c0.02,0.02,0.04,0.04,0.06,0.07
					l0.2,0.24h0.4c1.79-0.01,3.59,0,5.38-0.01L40.89,30.82L40.89,30.82z"/>
                </g>
                <g id="gl_562" transform="translate(20.174 35.487)">
                  <path id="path_190"
                        d="M16.04,12.16V1.08h7.45v0.91h-6.28v4.17h5.69v0.91h-5.69v5.09L16.04,12.16z"/>
                  <path id="path_191" d="M29.08,12.33c-0.77,0.02-1.53-0.17-2.21-0.55c-0.63-0.36-1.15-0.88-1.5-1.51
					C25,9.59,24.81,8.82,24.83,8.04C24.81,7.26,25,6.49,25.37,5.8c0.35-0.63,0.87-1.16,1.5-1.51c1.38-0.73,3.03-0.73,4.42,0
					c0.63,0.36,1.15,0.88,1.5,1.52c0.72,1.4,0.72,3.07,0,4.48c-0.35,0.63-0.87,1.16-1.5,1.51C30.61,12.16,29.85,12.35,29.08,12.33z
					 M29.08,11.45c0.6,0.02,1.19-0.14,1.7-0.47c0.46-0.31,0.83-0.73,1.06-1.24c0.48-1.09,0.48-2.33,0-3.41
					c-0.7-1.53-2.51-2.19-4.04-1.49c-0.66,0.3-1.19,0.83-1.49,1.49c-0.48,1.09-0.48,2.33,0,3.41c0.23,0.5,0.6,0.93,1.06,1.24
					C27.88,11.31,28.48,11.47,29.08,11.45z"/>
                  <path id="path_192" d="M35.65,12.16V3.85h1.09v1.28h0.08c0.21-0.44,0.57-0.8,1.01-1.01c0.5-0.26,1.06-0.4,1.63-0.39
					c0.09,0,0.2,0,0.31,0.01l0.28,0.01v0.98c-0.05-0.01-0.14-0.02-0.26-0.03c-0.14-0.02-0.27-0.02-0.41-0.02
					c-0.46-0.01-0.92,0.08-1.34,0.27c-0.37,0.16-0.69,0.43-0.93,0.76c-0.23,0.32-0.35,0.71-0.34,1.1v5.36L35.65,12.16z"/>
                  <path id="path_193" d="M57.27,4.54h-1.18C56,4.17,55.84,3.81,55.61,3.49c-0.24-0.33-0.53-0.62-0.86-0.85
					C54.39,2.4,54,2.21,53.58,2.08c-0.46-0.14-0.94-0.2-1.42-0.2c-0.78-0.01-1.54,0.18-2.22,0.55c-0.67,0.37-1.23,0.93-1.6,1.6
					c-0.8,1.64-0.8,3.55,0,5.18c0.37,0.67,0.93,1.23,1.6,1.6c0.68,0.37,1.45,0.55,2.22,0.54c0.48,0,0.96-0.06,1.42-0.2
					c0.42-0.12,0.81-0.31,1.17-0.56c0.33-0.23,0.62-0.52,0.86-0.85C55.84,9.44,56,9.08,56.08,8.7h1.18c-0.1,0.51-0.3,0.99-0.59,1.42
					c-0.29,0.45-0.66,0.83-1.09,1.15c-0.46,0.34-0.98,0.6-1.52,0.77c-0.61,0.19-1.25,0.28-1.89,0.28c-1,0.02-2-0.22-2.88-0.7
					c-0.83-0.46-1.52-1.14-1.97-1.98c-0.95-1.9-0.95-4.13,0-6.03c0.46-0.83,1.14-1.52,1.97-1.98c0.88-0.48,1.88-0.72,2.88-0.7
					c0.64-0.01,1.28,0.09,1.89,0.27c0.55,0.17,1.06,0.43,1.52,0.77c0.43,0.32,0.8,0.71,1.09,1.15C56.97,3.56,57.16,4.04,57.27,4.54z
					"/>
                  <path id="path_194" d="M59.85,1.08h1.36l4.67,9.65H66l4.67-9.65h1.36v11.08h-1.11V3.22h-0.1l-4.35,8.94H65.4l-4.35-8.94h-0.1
					v8.94h-1.11V1.08z"/>
                  <path id="path_195" d="M85.54,6.62c0.03,1.05-0.22,2.09-0.72,3.01c-0.46,0.83-1.14,1.52-1.97,1.98
					c-1.81,0.93-3.96,0.93-5.77,0c-0.83-0.46-1.51-1.15-1.97-1.98c-0.95-1.89-0.95-4.13,0-6.02c0.46-0.83,1.14-1.52,1.97-1.98
					c1.81-0.93,3.96-0.93,5.77,0c0.83,0.46,1.51,1.15,1.97,1.98C85.32,4.54,85.57,5.57,85.54,6.62z M84.4,6.62
					c0.02-0.89-0.17-1.76-0.58-2.55c-0.36-0.68-0.91-1.25-1.58-1.62c-1.42-0.75-3.12-0.75-4.53,0c-0.67,0.38-1.22,0.94-1.58,1.62
					c-0.77,1.61-0.77,3.49,0,5.1c0.36,0.68,0.91,1.24,1.58,1.62c1.42,0.75,3.12,0.75,4.54,0c0.67-0.38,1.22-0.94,1.58-1.62
					C84.23,8.38,84.43,7.5,84.4,6.62z"/>
                  <path id="path_196" d="M94.37,5.68l-1.03,0.25c-0.1-0.25-0.25-0.48-0.43-0.68c-0.2-0.21-0.45-0.38-0.72-0.48
					c-0.9-0.3-1.87-0.22-2.71,0.22c-0.38,0.2-0.62,0.6-0.63,1.03c-0.01,0.34,0.15,0.67,0.43,0.87c0.4,0.27,0.85,0.45,1.33,0.53
					l1.46,0.31c0.67,0.1,1.29,0.38,1.82,0.81c0.4,0.35,0.62,0.86,0.61,1.39c0,0.46-0.16,0.9-0.45,1.25
					c-0.34,0.39-0.77,0.69-1.26,0.86c-0.6,0.22-1.23,0.32-1.87,0.31c-0.81,0.03-1.62-0.16-2.33-0.55c-0.6-0.34-1.02-0.92-1.18-1.59
					l1.08-0.23c0.12,0.46,0.41,0.87,0.81,1.12c0.49,0.27,1.04,0.4,1.6,0.38c0.62,0.03,1.24-0.12,1.78-0.43
					c0.4-0.21,0.65-0.63,0.67-1.08c0-0.33-0.14-0.64-0.39-0.85c-0.34-0.27-0.75-0.44-1.18-0.51L90.2,8.29
					c-0.68-0.1-1.33-0.39-1.86-0.82c-0.73-0.69-0.81-1.82-0.17-2.6c0.32-0.37,0.74-0.65,1.2-0.82c0.55-0.21,1.14-0.31,1.73-0.3
					c0.74-0.04,1.48,0.14,2.12,0.52C93.75,4.6,94.15,5.09,94.37,5.68z"/>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
  );
}

export default SvgLogo;
