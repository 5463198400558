import {useEffect, useState, useMemo, useRef, useLayoutEffect} from 'react';
import {getAllFAQs} from '../../../api';
import ContentHeader from '../../organisms/ContentHeader';

import styled from 'styled-components';

const Page = styled.div`
  border-top: 4px solid #525252;
  position: relative;
  padding: 0 0 0 20px;
`;

const FilterSection = styled.div`
  margin-top: 50px;
  max-width: 700px;
`;

const FilterQueryArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  input {
    display: inline-block;
    flex: 1 0 auto;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    background-color: #fff;
    height: 46px;
    font-size: 16px;
    padding: 0 12px;
  }
`;

const FilterCategoriesArea = styled.div`
  display: none;
  margin-top: 40px;

  .filter-by {
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
  }
`;

const FilterCategories = styled.div`
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  align-items: center;
  //gap: 20px 0;

`;

const FilterCategory = styled.div`
  width: calc(50%);
  //margin-bottom: 20px;
  //background-color: pink;
  //margin-right: 5px;

  margin-bottom: 30px;
  width: calc(25%);

  input {
    display: none;

    &:checked {
      & + label {
        &:before {
          border-width: 3px;
        }

        &:after {
          display: block;
        }
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    //gap: 15px;
    position: relative;


    &:hover {
      &:before {
        border-width: 3px;
      }
    }

    span {
      font-size: 15px;
      font-weight: bold;
      line-height: 1;
    }

    &:before {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      border: 1px solid #ACACAC;
      transition: border-width 0.1s linear;
      margin-right: 15px;
    }

    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.505' height='15.308' viewBox='0 0 17.505 15.308'%3E%3Cpath id='パス_205' data-name='パス 205' d='M3333.988,3356.709l6.226,6.131,9.774-13.192' transform='translate(-3333.287 -3349.053)' fill='none' stroke='%23707070' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-size: 17.505px 15.308px;
      background-position: center;
      background-repeat: no-repeat;
      display: none;
    }
  }

`;

const FilterClearButtonArea = styled.div`
  text-align: right;
  margin-top: 0px;
`;

const FilterClearButton = styled.button`
  height: 40px;
  line-height: 39px;
  border: 1px solid #ACACAC;
  padding: 0 30px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.15s linear, border-color 0.15s linear;

  &:hover {
    background-color: #525252;
    color: #fff;
    border-color: #6A6A6A;
  }
`;

const ResultsSection = styled.div`
  margin-top: 50px;
  border-top: 1px solid #D7D7D7;;
`;

const FAQElm = styled.div`
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid #D7D7D7;
  position: relative;

  a.hash {
    position: relative;
    top: -110px;
  }
`;

const FAQContents = styled.div`
  max-width: 700px;
`;

const FAQQuestion = styled.div`
  //background-color: pink;
  display: flex;
  align-items: start;
  gap: 6px;
  margin-bottom: 30px;

  .q {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
  }

  div {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    white-space: pre-wrap;
    padding-top: 8px;
  }
`;

const Links = styled.div`
  margin-bottom: 30px;

  a {
    &:last-child {
      margin-bottom: 0;
    }

    display: block;
    margin-bottom: 20px;
    color: #525252;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;

    display: flex;
    align-items: center;
    gap: 10px;

    .label {
      flex: 1 1 auto;
    }
  }
`;

const LinkIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.375' height='19.719' viewBox='0 0 11.375 19.719'%3E%3Cpath id='パス_44' data-name='パス 44' d='M1.515,0,0,1.515,8.344,9.859,0,18.2l1.516,1.516,9.859-9.859Z' transform='translate(0 0)' fill='%23525252'/%3E%3C/svg%3E%0A");
  display: block;
  width: 6px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  flex: 0 0 auto;
`;

const NotFoundMessage = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PermalinkButtonArea = styled.div`
  position: relative;
  height: 40px;

  input {
    //display: none;
  }
`;

const PermalinkButton = styled.div`
  width: 40px;
  height: 40px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='長方形_711' data-name='長方形 711' width='20' height='10' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='グループ_967' data-name='グループ 967' transform='translate(-984 -915)'%3E%3Cg id='グループ_916' data-name='グループ 916' transform='translate(994 930)'%3E%3Cg id='グループ_915' data-name='グループ 915' clip-path='url(%23clip-path)'%3E%3Cpath id='パス_354' data-name='パス 354' d='M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5M6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0' fill='%23525252'/%3E%3C/g%3E%3C/g%3E%3Cg id='長方形_33' data-name='長方形 33' transform='translate(984 915)' fill='none' stroke='%23acacac' stroke-width='1'%3E%3Crect width='40' height='40' rx='4' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='39' height='39' rx='3.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  &.copied {
    &::after {
      opacity: 1;
    }
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40' height='40' viewBox='0 0 40 40'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='長方形_711' data-name='長方形 711' width='20' height='10' fill='%23fff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='グループ_968' data-name='グループ 968' transform='translate(-935.5 -2381)'%3E%3Cg id='長方形_33' data-name='長方形 33' transform='translate(935.5 2381)' fill='%23525252' stroke='%23525252' stroke-width='1'%3E%3Crect width='40' height='40' rx='4' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='39' height='39' rx='3.5' fill='none'/%3E%3C/g%3E%3Cg id='グループ_916' data-name='グループ 916' transform='translate(945.5 2396)'%3E%3Cg id='グループ_915' data-name='グループ 915' clip-path='url(%23clip-path)'%3E%3Cpath id='パス_354' data-name='パス 354' d='M1.9,5A3.1,3.1,0,0,1,5,1.9H9V0H5A5,5,0,0,0,5,10H9V8.1H5A3.1,3.1,0,0,1,1.9,5M6,6h8V4H6Zm9-6H11V1.9h4a3.1,3.1,0,1,1,0,6.2H11V10h4A5,5,0,0,0,15,0' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.15s linear;
  }
`;

const CopyedLabel = styled.div`
  position: absolute;
  right: 55px;
  background-color: #525252;
  color: #fff;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  padding: 0 22px;
  font-weight: bold;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.15s linear;

  &.copied {
    opacity: 1;
  }

  &::after {
    content: '';
    background-color: #525252;
    width: 7px;
    height: 7px;
    position: absolute;
    display: block;
    top: calc(50% - 3px);
    right: -4px;
    transform: rotateZ(45deg);
  }
`;

const CATEGORY_MST = [
  {
    value: 'account',
    label: 'Account',
  },
  {
    value: 'cdj-3000',
    label: 'CDJ-3000',
  },
  {
    value: 'nxs-gw',
    label: 'NXS-GW',
  },
  {
    value: 'djm-tour1',
    label: 'DJM-TOUR1',
  },
  {
    value: 'old-kuvo',
    label: 'Old KUVO',
  },
  {
    value: 'troubleshooting',
    label: 'Troubleshooting',
  },
  {
    value: 'playlists',
    label: 'Playlists',
  },
  {
    value: 'how-to-use',
    label: 'How to use',
  },
  {
    value: 'membership',
    label: 'Membership',
  },
  {
    value: 'others',
    label: 'Others',
  },
];

function PageHeader() {
  return (
      <ContentHeader
          title={'FAQ'}
          subtitle={''}
          rightTopElem={null}
          style={{
            marginBottom: 12,
            paddingBottom: 4,
          }}
      />
  );
}

function PermalinkCopyFunction({url}) {

  const timerRef = useRef(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  const doCopy = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    navigator.clipboard.writeText(url);

    setCopied(true);
    timerRef.current = setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
      <PermalinkButtonArea>
        <CopyedLabel className={`${copied ? 'copied' : ''}`}>URL copied to
          clipboard.</CopyedLabel>
        <PermalinkButton className={`${copied ? 'copied' : ''}`}
                         onClick={doCopy}/>
      </PermalinkButtonArea>
  );
}

function FAQItem({faq}) {

  return (<FAQElm
      data-faq_id={faq.faq_id}
      data-hash={faq.faq_id}
  >
    <a name={faq.faq_id} className={'hash'}/>
    <FAQContents>
      <FAQQuestion
      >
        <span className={'q'}>Q.</span>
        <div>{faq.question_en}</div>
      </FAQQuestion>
      <FAQQuestion>
        <span className={'q'}>A.</span>
        <div>{faq.answer_en}</div>
      </FAQQuestion>
      {
        faq.link ? (<Links>
          <a

              href={faq.link}
              target={'_blank'}>
            <LinkIcon/>
            <div className={'label'}>{faq.link_label || faq.link}</div>
          </a>
        </Links>) : ''
      }
      <PermalinkCopyFunction
          url={`${window.location.origin}/cmo/q/${faq.faq_id}`}
      />
    </FAQContents>
  </FAQElm>);
}

export default function FAQPage() {
  const [condition, setCondition] = useState({
    query: '',
    queries: [],
    categories: new Set(),
  });
  const updateTimerRef = useRef(null);
  const formRef = useRef();
  const [FAQs, setFAQs] = useState([]);

  const refreshFAQs = async () => {
    try {
      const faqs = await getAllFAQs();
      faqs.forEach(f => {
        f.category_values = new Set(f.categories.map(c => c.name));
        f.search = f.question_en.toLowerCase() + ' ' +
            f.answer_en.toLowerCase();
      });
      setFAQs(faqs);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    document.title = 'FAQ | DASHBOARD';
    refreshFAQs();
    return () => {
      if (updateTimerRef.current) {
        clearTimeout(updateTimerRef.current);
        updateTimerRef.current = null;
      }
    };
  }, []);

  const faqs = useMemo(() => {
    const selectedCategories = [...condition.categories];
    const items = FAQs.slice().filter(faq => {
      if (selectedCategories.length &&
          selectedCategories.some(v => !faq.category_values.has(v))) {
        // 選んだカテゴリがない
        return false;
      }
      if (!condition.queries.length) {
        return true;
      }
      return !condition.queries.some((q) => {
        if (0 > faq.search.indexOf(q)) {
          return true;
        }
        return false;
      });
    });
    return items;
  }, [FAQs, condition]);

  const resetForm = (e) => {
    if (!formRef.current) {
      return;
    }
    formRef.current.reset();
    setCondition({
      query: '',
      queries: [],
      categories: new Set(),
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('onSubmit');
    updateCondition();
  };

  const onCategoryChange = (e) => {
    console.log('onCategoryChange', e.target.value, e.target.checked);
    updateCondition();
  };

  const updateCondition = () => {
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current);
      updateTimerRef.current = null;
    }
    updateTimerRef.current = setTimeout(() => {
      updateConditionCore();
    }, 100);
  };

  const updateConditionCore = () => {
    if (!formRef.current) {
      return;
    }
    let formData = new FormData(formRef.current);
    const categories = formData.getAll('category');
    const query = formData.get('query').trim() || '';

    //return;
    let newCondition = {
      query,
      queries: condition.queries,
      categories: new Set(categories),
    };

    if (condition?.query != query) {
      let queries = [];
      let q = query.replace(/[\r\n\t]/g, ' ').replace(/[ ]+/g, ' ');
      if (q) {
        queries = q.split(' ').
            map(v => v.trim().toLowerCase()).
            filter(v => !!v);
      }
      newCondition.queries = queries;
    }

    setCondition(newCondition);
  };

  return (
      <>
        <PageHeader/>
        <Page>
          <FilterSection>
            <form onSubmit={onSubmit} ref={formRef}>
              <FilterQueryArea>
                <input type={'text'} name={'query'}
                       defaultValue={condition.query}/>
                <button type={'submit'} className={'dark-button'}>
                  <span>Search</span></button>
              </FilterQueryArea>
              <FilterCategoriesArea>
                <div className={'filter-by'}>Filter by</div>
                <FilterCategories>
                  {
                    CATEGORY_MST.map((c, i) => {

                      return (
                          <FilterCategory
                              key={i}
                              onClick={updateCondition}
                          >
                            <input type={'checkbox'}
                                   value={c.value}
                                   name={'category'}
                                   onChange={onCategoryChange}
                                   id={`category_${c.value}`}
                                   defaultChecked={condition.categories.has(
                                       c.value)}/>
                            <label
                                htmlFor={`category_${c.value}`}><span>{c.label}</span></label>
                          </FilterCategory>
                      );
                    })
                  }
                </FilterCategories>
                <FilterClearButtonArea>
                  <FilterClearButton
                      onClick={resetForm}>Clear</FilterClearButton>
                </FilterClearButtonArea>
              </FilterCategoriesArea>
            </form>
          </FilterSection>
          <ResultsSection>
            {
              faqs.length ?
                  faqs.map(faq => {
                    return (<FAQItem
                            key={faq.faq_id}
                            faq={faq}
                        />
                    );
                  }) : (
                      <NotFoundMessage>No results found.</NotFoundMessage>
                  )
            }
          </ResultsSection>
        </Page>
      </>
  );
}
