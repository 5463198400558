import {Link} from 'react-router-dom';
import ContentHeader from '../../organisms/ContentHeader';

export default function ContactSubmittedPage() {
  return (
      <div>
        <ContentHeader
            title={'CONTACT'}
            subtitle={''}
            rightTopElem={null}
            style={{
              marginBottom: 12,
              paddingBottom: 4,
            }}
        />
        <div className="static-page-content contact">
          <section className="header-section">
            <h3>Request submitted</h3>

            <div className="d1">
              Thank you for reaching us! A member of our team will respond as
              soon
              as possible to your e-mail of Pioneer DJ account.
            </div>

            <Link to={'/'} className="light-button">
              Go back TOP
            </Link>
          </section>
        </div>
      </div>
  );
}
