import styled from 'styled-components';
import ContentHeader from '../../organisms/ContentHeader';

const Content = styled.div`
  max-width: 1000px;
  text-align: center;
  padding-top: 110px;

  h3 {
    font-size: 60px;
    font-weight: bold;
  }

`;

export default function NotFoundPage() {
  return (
      <>
        <ContentHeader
            title={''}
            subtitle={''}
            rightTopElem={null}
            style={{
              marginBottom: 12,
              paddingBottom: 4,
            }}
        />
        <Content>
          <h3>Not found</h3>
        </Content>
      </>
  );
}
