import ContentHeader from '../../../organisms/ContentHeader';
import './style.scss';
import '../style.scss';
import {useAuth} from '../../../../contexts/UserContext';
import {useState, useEffect} from 'react';
import {
  getMembers,
  changeUserPermission,
  inviteMember,
  cancelInvitation,
  resendInvitation,
  removeMember,
  transferAutority,
} from '../../../../api';
import moment from 'moment';
import {SettingsMenu} from '..';
import LabelWithInfo, {InfoLinePosition} from '../../../atoms/LabelWithInfo';
import {
  useCustomDialog,
  ButtonLayout,
} from '../../../../contexts/CustomDialogContext';
import ContentModal from '../../../organisms/ContentModal';
import SimpleBar from 'simplebar-react';

function PageHeader() {
  return (
      <ContentHeader
          title={'SETTINGS'}
          subtitle={''}
          rightTopElem={null}
          style={{
            marginBottom: 12,
            paddingBottom: 4,
          }}
      />
  );
}

export default function PermissionsPage() {
  let auth = useAuth();
  let [memberData, setMemberData] = useState({});
  let [contributors, setContributors] = useState([]);
  const [transferDialogIsOpen, setTransferDialogIsOpen] = useState(false);
  const {customDialog, confirmDialog, alertDialog, errorDialogWithAPIError} =
      useCustomDialog();

  const user = auth.state.user;

  let getItems = async () => {
    try {
      let data = await getMembers();
      console.log(data);

      setContributors(
          (data.members || []).filter(
              (m) => m.gigya_uid != auth.state.user.gigya_uid,
          ),
      );
      setMemberData(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  let onSave = async function(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    let gigya_uid = formData.get('gigya_uid');
    let permission_level = parseInt(formData.get('permission_level'), 10);
    console.log(gigya_uid, permission_level);

    window.incrementLoading();
    try {
      await changeUserPermission({
        gigya_uid,
        permission_level,
      });
      window.decrementLoading();
      await customDialog({
        title: 'Changes have been saved successfully.',
        buttons: ButtonLayout.None,
      });

      await getItems();
    } catch (e) {
      console.error(e);
      window.decrementLoading();
      await errorDialogWithAPIError(e);
    }
  };

  let onInvite = async function(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    let email = formData.get('email');
    let permission_level = parseInt(formData.get('permission_level'), 10);
    console.log(email, permission_level);

    window.incrementLoading();
    try {
      await inviteMember({
        email,
        permission_level,
      });
      window.decrementLoading();
      await alertDialog({
        title: 'Invite sent via e-mail.',
        description:
            'Invite sent to the e-mail address. The authorizer can cancel or resend the invite from the Permissions menu.',
      });
      await getItems();
    } catch (e) {
      console.error(e);
      window.decrementLoading();
      await errorDialogWithAPIError(e);
    }

    e.target.reset();
  };

  let onCancelInvitation = async function(datainvitation_id) {
    console.log(datainvitation_id);

    const result = await customDialog({
      title: `Are you sure you want to cancel?`,
      buttons: [
        {
          label: 'Yes, cancel.',
          positive: true,
        },
        {
          label: 'No, don’t cancel.',
          positive: false,
        },
      ],
    });

    if (result != 0) {
      return;
    }

    window.incrementLoading();
    try {
      await cancelInvitation({
        datainvitation_id,
      });
      window.decrementLoading();
      await alertDialog({
        title: 'Canceled\nThe invite is no longer valid.',
      });
      await getItems();
    } catch (e) {
      console.error(e);
      window.decrementLoading();
      await errorDialogWithAPIError(e);
    }
  };

  let onResendInvitation = async function(datainvitation_id, email) {
    console.log(datainvitation_id);

    const result = await customDialog({
      big_title: email,
      title: 'Do you want to resend the invite?',
      buttons: [
        {
          label: 'Resend',
          positive: true,
        },
        {
          label: 'Cancel',
          positive: false,
        },
      ],
    });

    if (result != 0) {
      return;
    }

    window.incrementLoading();
    try {
      await resendInvitation({
        datainvitation_id,
      });
      window.decrementLoading();
      await alertDialog({
        title: 'Invite sent via e-mail.',
        description:
            'Invite sent to the e-mail address. The authorizer can cancel or resend the invite from the Permissions menu.',
      });
      await getItems();
    } catch (e) {
      console.error(e);
      window.decrementLoading();
      await errorDialogWithAPIError(e);
    }
  };

  let onRemoveMember = async function(gigya_uid, name) {
    console.log(gigya_uid);

    if (
        !(await confirmDialog({
          title: `Are you sure you want to remove \n“${name}”?`,
        }))
    ) {
      return;
    }

    window.incrementLoading();
    try {
      await removeMember({
        gigya_uid,
      });
      window.decrementLoading();

      await alertDialog({
        title: 'Removed',
      });
      await getItems();
    } catch (e) {
      console.error(e);
      window.decrementLoading();
      await errorDialogWithAPIError(e);
    }
  };

  let onTransferAuthorityFormSubmitted = (e) => {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    let c = formData.get('candidate');
    console.log(c);
    let member = contributors.find((m) => (m.gigya_uid = c));
    if (!member) {
      return;
    }
    setTransferDialogIsOpen(false);
    onTransferAuthority(member);
    return false;
  };

  let onTransferAuthority = async function(member) {
    let gigya_uid = member.gigya_uid;
    console.log(gigya_uid);

    window.incrementLoading();
    try {
      await transferAutority({
        gigya_uid,
      });
      window.decrementLoading();
      await alertDialog({
        title: 'Transfer completed.',
        description:
            'You are no longer authorized to access the permissions menu. You will jump to the setting menu.',
      });
      window.location.reload();
    } catch (e) {
      console.error(e);
      window.decrementLoading();
      await errorDialogWithAPIError(e);
    }
  };

  const slot = Math.max(
      0,
      5 - contributors.length - (memberData.invitations || []).length,
  );

  return (
      <>
        <PageHeader/>
        <div className="permissions settings">
          <div className="permissions-contents settings-contents">
            <div className="settings-contents-inner">
              <h2>PERMISSIONS</h2>

              <section className="authorizer-section">
                <div className="authorizer-content">
                  <div className="image-area">
                    <div
                        className="nullable-photo"
                        style={{
                          backgroundImage:
                              (user.image_url && `url(${user.image_url})`) ||
                              undefined,
                        }}
                    />
                  </div>
                  <div className="info-area">
                    <div className="authorizer-label">Authorizer</div>
                    <div className="name">
                      {user.first_name} {user.last_name}
                    </div>
                  </div>
                </div>
                <div className="transfer-button-area">
                  <button
                      className={`light-button ${
                          contributors.length ? 'enabled' : 'disabled'
                      }`}
                      disabled={!contributors.length}
                      onClick={() => {
                        if (contributors.length) {
                          setTransferDialogIsOpen(true);
                        }
                      }}
                  >
                    Transfer the authorization
                  </button>
                  <LabelWithInfo
                      inline={true}
                      infoPosition={InfoLinePosition.RightTop}
                  >
                    <div>
                      Transfer your authorization to a selected contributor.
                      This
                      change will switch you to the contributor status once the
                      transfer is complete.
                    </div>
                  </LabelWithInfo>
                </div>
              </section>

              {contributors.length ? (
                  <section className="contributors-section">
                    <h3>CONTRIBUTORS</h3>
                    <div className="contributors-items">
                      {contributors.map((m) => {
                        return (
                            <div key={m.gigya_uid} className="member">
                              <div className="info-area">
                                <div className="info-area-contents">
                                  <div className="image-area">
                                    <div
                                        className="nullable-photo"
                                        style={{
                                          backgroundImage:
                                              (m.image_url &&
                                                  `url(${m.image_url})`) ||
                                              undefined,
                                        }}
                                    />
                                  </div>
                                  <div className="name">
                                    {`${m.email || ''}`}
                                  </div>
                                </div>
                                <button
                                    className="light-button"
                                    onClick={function(e) {
                                      onRemoveMember(
                                          m.gigya_uid,
                                          `${m.email || ''}`.trim(),
                                      );
                                    }}
                                >
                                  REMOVE
                                </button>
                              </div>
                              <div className="authority-area">
                                <div className="label">Authority level</div>
                                <form onSubmit={onSave}>
                                  <input
                                      type="hidden"
                                      value={m.gigya_uid}
                                      name="gigya_uid"
                                  />
                                  <div className="items">
                                    <label className="custom-radio">
                                      <input
                                          type="radio"
                                          name="permission_level"
                                          defaultChecked={m.permission_level ==
                                              3}
                                          value="3"
                                      />
                                      <i></i>
                                      <LabelWithInfo
                                          text={'Limited'}
                                          labelclassname={'radio-label'}
                                          inline={true}
                                          infoPosition={InfoLinePosition.RightTop}
                                      >
                                        <div>
                                          This permission is for accessing all
                                          areas
                                          but NOT downloading the reports.
                                        </div>
                                      </LabelWithInfo>
                                    </label>
                                    <label className="custom-radio">
                                      <input
                                          type="radio"
                                          labelclassname={'radio-label'}
                                          name="permission_level"
                                          defaultChecked={m.permission_level ==
                                              4}
                                          value="4"
                                      />
                                      <i></i>
                                      <LabelWithInfo
                                          text={'Full'}
                                          inline={true}
                                          infoPosition={InfoLinePosition.RightTop}
                                      >
                                        <div>
                                          This permission is for accessing all
                                          areas
                                          and downloading the reports.
                                        </div>
                                      </LabelWithInfo>
                                    </label>
                                  </div>
                                  <input
                                      type="submit"
                                      className="dark-button"
                                      value="Save changes"
                                  />
                                </form>
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </section>
              ) : (
                  ''
              )}

              {memberData.invitations && memberData.invitations.length ? (
                  <section className="invited-section">
                    <h3>INVITES SENT</h3>
                    <div className="invites-items">
                      {(memberData.invitations || []).map((invitation) => {
                        let at = moment(invitation.invite_datetime).format(
                            'YYYY/MM/DD',
                        );

                        return (
                            <div key={invitation.email} className="invitation">
                              <div className="info-area">
                                <div className="info-area-contents">
                                  <div className="image-area">
                                    <div
                                        className="nullable-photo"
                                        style={{
                                          backgroundImage:
                                              (invitation.image_url &&
                                                  `url(${invitation.image_url})`) ||
                                              undefined,
                                        }}
                                    />
                                  </div>
                                  <div className="info-text-area">
                                    <div
                                        className="email">{invitation.email}</div>
                                    <div className="invite_datetime_label">
                                      Date of invitation
                                    </div>
                                    <div className="invite_datetime">{at}</div>
                                  </div>
                                </div>
                              </div>

                              <div className="button-area">
                                <button
                                    className="light-button"
                                    onClick={function(e) {
                                      onCancelInvitation(
                                          invitation.datainvitation_id);
                                    }}
                                >
                                  Cancel
                                </button>
                                <button
                                    className="light-button"
                                    onClick={function(e) {
                                      onResendInvitation(
                                          invitation.datainvitation_id,
                                          invitation.email,
                                      );
                                    }}
                                >
                                  Resend
                                </button>
                              </div>
                            </div>
                        );
                      })}
                    </div>
                  </section>
              ) : (
                  ''
              )}

              <section className="invite-section">
                <h3>Invite teams as contributors.</h3>

                <div className="description">
                  Contributors can have different access levels based on the
                  authorizer’s permissions. (Pioneer DJ account is required to
                  sign up. )
                </div>

                <div className="number-of-people">
                  <span className="s1">Number of people left to invite</span>
                  <span className="s2">{slot}</span>
                  <span className="s3"> / 5</span>
                  <LabelWithInfo
                      inline={true}
                      infoPosition={InfoLinePosition.RightTop}
                  >
                    <div>
                      You have five contributors slots with the current
                      membership
                      tier.
                    </div>
                  </LabelWithInfo>
                </div>

                <form onSubmit={onInvite}>
                  <div className="invitation-form">
                    <div className="invitation-form-contents">
                      <div className="email-area">
                        <span>Email</span>
                        <input
                            type="email"
                            required={true}
                            name="email"
                            disabled={slot <= 0}
                        />
                      </div>
                      <div className="button-area">
                        <span>Level</span>
                        <div className="radio-items">
                          <label className="custom-radio">
                            <input
                                type="radio"
                                defaultChecked={true}
                                value="3"
                                name="permission_level"
                            />
                            <i></i>
                            <LabelWithInfo
                                text={'Limited'}
                                labelclassname={'radio-label'}
                                inline={true}
                                infoPosition={InfoLinePosition.RightTop}
                            >
                              <div>
                                This permission is for accessing all areas but
                                NOT
                                downloading the reports.
                              </div>
                            </LabelWithInfo>
                          </label>
                          <label className="custom-radio">
                            <input
                                type="radio"
                                defaultChecked={false}
                                value="4"
                                name="permission_level"
                            />
                            <i></i>
                            <LabelWithInfo
                                text={'Full'}
                                inline={true}
                                infoPosition={InfoLinePosition.RightTop}
                            >
                              <div>
                                This permission is for accessing all areas and
                                downloading the reports.
                              </div>
                            </LabelWithInfo>
                          </label>
                        </div>
                        <input
                            type="submit"
                            className={`dark-button ${slot ? '' : 'disabled'}`}
                            value="Invite"
                            disabled={slot <= 0}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
          <SettingsMenu/>
        </div>

        <ContentModal
            closeModal={() => {
              setTransferDialogIsOpen(false);
            }}
            isOpen={transferDialogIsOpen}
            transferdialog={1}
        >
          <div className="fixed-header">
            <h3>TRANSFER THE AUTHORIZATION</h3>
            <div className="scroll-header">
              <div className="scroll-header-columns">
                <div className="scroll-header-column">
                <span>
                  Select a new authorizer. After the authority is transferred,
                  the new authorizer will be able to remove your access.
                </span>
                </div>
              </div>
            </div>
          </div>
          <div className="transfer-dialog-contents">
            <form onSubmit={onTransferAuthorityFormSubmitted}>
              <div className="candidates">
                <table className="candidates-table">
                  <tbody>
                  {contributors.map((m, i) => {
                    return (
                        <tr key={i} className="tr">
                          <td>
                            <label className="custom-radio-big">
                              <input
                                  type="radio"
                                  name="candidate"
                                  defaultChecked={i == 0}
                                  value={m.gigya_uid}
                              />
                              <i></i>
                              <span className="name">
                              {`${m.email || ''}`}
                            </span>
                              <span className="level">
                              (
                                {m.permission_level == 4
                                    ? 'Full'
                                    : m.permission_level == 3
                                        ? 'Limited'
                                        : m.permission_level_str}
                                )
                            </span>
                            </label>
                          </td>
                        </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="buttons">
                <input type="submit" className="dark-button" value="Transfer"/>
              </div>
            </form>
          </div>
        </ContentModal>
      </>
  );
}
