import {useState, useEffect} from 'react';

import styled from 'styled-components';

import {useVenueReport} from '../../../contexts/VenueReportContext';
import BarGraph from '../../organisms/BarGraph';
import DBDPullDown from '../../atoms/DBDPullDown';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';

import moment from 'moment';

const GraphWrap = styled.div`
  position: relative;
`;

function DataGraph({type, roomID}) {
  const venueReport = useVenueReport();

  let values = [];
  console.log(type, roomID);
  if (venueReport.state.data) {
    const query = venueReport.state.fetched_query;

    const lastDay = parseInt(
        moment.utc(`${query.year}-${(query.month + '').padStart(2, '0')}-01`).
            add(1, 'month').
            add(-1, 'day').
            format('D'),
        10,
    );

    const rooms = venueReport.state.data.room_graphs[type] || [];
    const room =
        rooms.find((room) => room.id == roomID) || null;
    const data = (room && room.values) || [];
    const dayMap = new Map();
    data.forEach((row) => {
      dayMap.set(row.date, row);
    });

    let m = moment.utc(
        `${query.year}-${(query.month + '').padStart(2, '0')}-01`,
    );
    for (let i = 1; i <= lastDay; i++) {
      const dt = m.format('YYYY-MM-DD');
      let row = dayMap.get(dt);
      values.push({
        date: m.toDate(),
        value: (row && row.value) || 0,
        na: !row,
      });
      m.add(1, 'day');
    }
  }

  return (
      <div>
        <BarGraph values={values} unit={type}/>
      </div>
  );
}

export default function VenueReportGraph() {
  const venueReport = useVenueReport();
  const [type, setType] = useState('tracks');
  const [roomID, setRoomID] = useState(0);

  const onRoomPullDownChange = (newValue) => {
    setRoomID(parseInt(newValue, 10));
  };

  const onTypePullDownChange = (newValue) => {
    setType(newValue);
  };

  useEffect(() => {
    setRoomID(0);
  }, [venueReport.state.club_id, venueReport.state.query]);

  return (
      <>
        <GraphWrap>
          <DataGraph type={type} roomID={roomID}/>
          <div
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
              }}
          >
            <DBDPullDown
                value={roomID}
                onChange={onRoomPullDownChange}
                items={(
                    (venueReport.state.data &&
                        venueReport.state.data.room_graphs &&
                        venueReport.state.data.room_graphs.rooms) ||
                    []
                ).map((room) => {
                  return {
                    value: room.id,
                    label: room.detail_name.length > 20
                        ? room.detail_name.substring(0, 20) + '...'
                        : room.detail_name,
                  };
                })}
                style={{
                  marginRight: 20,
                }}
            ></DBDPullDown>

            <DBDPullDown
                value={type}
                onChange={onTypePullDownChange}
                items={[
                  {
                    value: 'tracks',
                    label: 'TRACKS',
                  },
                  {
                    value: 'hours',
                    label: 'HOURS',
                  },
                ]}
            ></DBDPullDown>
          </div>
          <LabelWithInfo
              text={'PLAYED (Room or Floor)'}
              infoPosition={InfoLinePosition.RightBottom}
              style={{
                position: 'absolute',
                left: 20,
                top: 20,
              }}
          >
            <div>
              You can see the activity chart in the selected period. Change the
              data source from the pulldown menu on the right top of the graph.
            </div>
          </LabelWithInfo>
        </GraphWrap>
      </>
  );
}
