import ContentModal from '../ContentModal';
import moment from 'moment';

export default function DownloadModal({
  title,
  query,
  isOpen,
  onRequestDownload,
  onClose,
}) {
  let d = new Date();
  d.setFullYear(query.year);
  d.setMonth(query.month - 1);

  const subTitle = moment(d).format('MMM YYYY').toUpperCase();
  const onSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let format = formData.get('format');
    console.log(format);
    onRequestDownload(format);
  };

  return (
      <ContentModal closeModal={onClose} isOpen={isOpen} dlmodal={1}>
        <div className="normal-header">
          <h3>{title}</h3>
        </div>
        <div className="dl-subtitle">
          <span>{subTitle}</span>
        </div>
        <form onSubmit={onSubmit}>
          <div className="radio-items">
            <div className="radio-item">
              <input
                  defaultChecked={true}
                  id="format-csv"
                  type="radio"
                  name="format"
                  value="csv"
              ></input>
              <label htmlFor="format-csv">
                <span>CSV</span>
              </label>
            </div>

            <div className="radio-item">
              <input
                  id="format-excel"
                  type="radio"
                  name="format"
                  value="excel"
              ></input>
              <label htmlFor="format-excel">
                <span>EXCEL</span>
              </label>
            </div>

            <div className="radio-item">
              <input
                  id="format-ddex"
                  type="radio"
                  name="format"
                  value="ddex"
                  disabled
              ></input>
              <label htmlFor="format-ddex">
                <span>DDEX</span>
              </label>
            </div>
          </div>
          <div className="radio-items">
            <div className="radio-item">
              <input
                  id="format-dsrf"
                  type="radio"
                  name="format"
                  value="dsrf"
                  disabled
              ></input>
              <label htmlFor="format-dsrf">
                <span>DSRF</span>
              </label>
            </div>
            <div className="radio-item">
              <input
                  id="format-ftp"
                  type="radio"
                  name="format"
                  value="ftp"
                  disabled
              ></input>
              <label htmlFor="format-ftp">
                <span>FTP</span>
              </label>
            </div>
          </div>

          <div className="button-area">
            <input
                type="submit"
                value="DOWNLOAD REPORT"
                className="dark-button"
            ></input>
          </div>
        </form>
      </ContentModal>
  );
}
