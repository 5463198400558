import {useState} from 'react';
import {useAuth} from '../../../contexts/UserContext';
import {SelectorItem} from '.';
import DBDPullDown from '../../atoms/DBDPullDown';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import VenueListModal from '../../organisms/VenueListModal';

export default function SummaryReportDisplayMenu() {
  const auth = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onTypePullDownChange = (newValue) => {
    if ('venue' == newValue) {
      openModal();
    }
  };

  return (
      <SelectorItem>
        <LabelWithInfo
            text={'DISPLAY'}
            infoPosition={InfoLinePosition.RightBottom}
            style={{
              marginBottom: 20,
            }}
        >
          <div>
            <p>Select content to display.</p>
            <p>
              Statistic Summary: <br/>A summary of your territory in the period
              chosen.
            </p>
            <p>
              Venue: <br/>
              An activity overview per venue.
            </p>
          </div>
        </LabelWithInfo>

        <DBDPullDown
            value={'summary'}
            onChange={onTypePullDownChange}
            items={[
              {
                value: 'summary',
                label: 'STATISTICS SUMMARY',
              },
              {
                value: 'venue',
                label: 'VENUE',
                disabled: !auth.hasClubLicense(),
              },
            ]}
        ></DBDPullDown>

        <VenueListModal closeModal={closeModal} isOpen={modalIsOpen}/>
      </SelectorItem>
  );
}
