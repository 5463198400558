import React, {useState, useEffect} from 'react';

import './style.scss';
import HappyNumberLabel from '../../atoms/HappyNumberLabel';

export function DefaultStatsLebel({text, ...props}) {
  return (
      <div className="DefaultStatsLebel" {...props}>
        <span>{text}</span>
      </div>
  );
}

export default function StatArea({
  labelWithInfo = '',
  delta = null,
  button = '',
  children,
  ...props
}) {
  let deltaElem = '';
  if (delta !== null) {
    deltaElem = (
        <div
            className={`delta ${
                delta > 0 ? 'positive' : delta < 0 ? 'negative' : 'zero'
            }`}
        >
          <HappyNumberLabel text={Math.abs(delta).toLocaleString('en-US')}/>
          <div className="delta-tooltip">Month over month</div>
        </div>
    );
  }

  return (
      <div className="StatArea" {...props}>
        <div className="stat-content">
          <div className="stat-conten-inner">{children}</div>
        </div>
        <div className="stat-action">{button}</div>

        <div className="stat-header">
          <div className="stat-label">{labelWithInfo}</div>
          {deltaElem}
        </div>
      </div>
  );
}
