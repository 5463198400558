import {useState} from 'react';
import {useAuth} from '../../../contexts/UserContext';
import styled from 'styled-components';
import {SelectorItem} from '.';
import {useNavigate} from 'react-router-dom';
import DBDPullDown from '../../atoms/DBDPullDown';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import VenueListModal from '../../organisms/VenueListModal';

const Wrap = styled.div`
  margin-top: -10px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-top: 10px;
  }

  @media print {
    .dark-button {
      display: none;
    }
  }
`;

export default function VenueReportDisplayMenu() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onTypePullDownChange = (newValue) => {
    if ('summary' == newValue) {
      navigate('/' + window.location.search);
    }
  };

  return (
      <SelectorItem>
        <LabelWithInfo
            text={'DISPLAY'}
            infoPosition={InfoLinePosition.RightBottom}
            style={{
              marginBottom: 20,
            }}
        >
          <div>
            <p>Select content to display.</p>
            <p>
              Statistic Summary: <br/>A summary of your territory in the period
              chosen.
            </p>
            <p>
              Venue: <br/>
              An activity overview per venue.
            </p>
          </div>
        </LabelWithInfo>

        <Wrap>
          <DBDPullDown
              value={'venue'}
              onChange={onTypePullDownChange}
              items={[
                {
                  value: 'summary',
                  label: 'STATISTICS SUMMARY',
                },
                {
                  value: 'venue',
                  label: 'VENUE',
                },
              ]}
              style={{
                marginRight: 20,
              }}
          ></DBDPullDown>

          <button
              className="dark-button"
              onClick={openModal}
              style={{
                height: 40,
                lineHeight: '38px',
              }}
          >
            VENUES LIST
          </button>
        </Wrap>

        <VenueListModal closeModal={closeModal} isOpen={modalIsOpen}/>
      </SelectorItem>
  );
}
