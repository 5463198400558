const config = {
  api: {
    host: 'https://dashboard-api.kuvo.com',
  },
  report: {
    min_year: 2017,
  },
};

export default config;
