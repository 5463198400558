import {useState, useRef, useEffect} from 'react';
import config from '../../../config';
import DBDPullDown from '../../atoms/DBDPullDown';

export default function ReportQuerySelector({query, onChange}) {
  let now = new Date();
  let nowYear = now.getFullYear();
  const [detached, setDetached] = useState(false);
  const changeTimerRef = useRef(null);
  const [type, setType] = useState(query.type);
  const [year, setYear] = useState(query.year);
  const [month, setMonth] = useState(query.month);

  useEffect(() => {
    setDetached(false);
    return () => {
      setDetached(true);
    };
  }, []);

  const years = () => {
    let yearItems = [];
    for (let y = config.report.min_year; y <= nowYear; y++) {
      yearItems.push({
        value: y,
        label: `${y}`,
      });
    }
    yearItems.reverse();
    return yearItems;
  };

  const months = () => {
    let strs = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return strs.map((s, i) => {
      return {
        value: i + 1,
        label: `${s}`.toUpperCase(),
      };
    });
  };

  const onYearChange = (val) => {
    setYear(val);
  };

  const onMonthChange = (val) => {
    setMonth(val);
  };

  useEffect(() => {
    if (detached) {
      return;
    }
    if (type == query.type && year == query.year && month == query.month) {
      return;
    }

    setType(query.type);
    setYear(query.year);
    setMonth(query.month);
  }, [query.type, query.year, query.month]);

  useEffect(() => {
    if (detached) {
      return;
    }
    if (type == query.type && year == query.year && month == query.month) {
      return;
    }

    if (changeTimerRef.current) {
      clearTimeout(changeTimerRef.current);
    }

    changeTimerRef.current = setTimeout(() => {
      if (detached) {
        return;
      }

      onChange({
        type,
        year,
        month,
      });
    }, 1000);
  }, [type, year, month]);

  const onChangeHandler = () => {
    /*
      if (detached) {
        return;
      }
      if (changeTimerRef.current) {
        clearTimeout(changeTimerRef.current);
      }
      changeTimerRef.current = setTimeout(() => {
        if (detached || !yearRef.current || !monthRef.current) {
          return;
        }
  
        const year = yearRef.current.value;
        const month = monthRef.current.value;
        onChange({
          type: "monthly",
          year: parseInt(year),
          month: parseInt(month),
        });
      }, 1000);
      */
  };

  return (
      <div>
        <DBDPullDown
            value={type}
            onChange={() => {}}
            items={[
              {
                value: 'daily',
                label: 'DAILY',
                disabled: true,
              },
              {
                value: 'weekly',
                label: 'WEEKLY',
                disabled: true,
              },
              {
                value: 'monthly',
                label: 'MONTHLY',
              },
              {
                value: 'quaeterly',
                label: 'QUARTERLY',
                disabled: true,
              },
              {
                value: 'yearly',
                label: 'YEARLY',
                disabled: true,
              },
              {
                value: 'hourly',
                label: 'HOURLY',
                disabled: true,
              },
            ]}
        ></DBDPullDown>

        <DBDPullDown
            value={year}
            onChange={onYearChange}
            items={years()}
            style={{
              marginLeft: '10px',
            }}
        ></DBDPullDown>
        <DBDPullDown
            value={month}
            onChange={onMonthChange}
            items={months()}
            style={{
              marginLeft: '10px',
            }}
        ></DBDPullDown>
      </div>
  );
}
