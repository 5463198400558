import {useEffect, useState, memo, useMemo, useRef, useCallback} from 'react';
import styled from 'styled-components';
import {useAnimationFrame} from '../../../libs/AnimationManager';

const HappyLabel = styled.span`
  white-space: nowrap;
  word-break: keep-all;
`;

function Char({char, pos, hash}) {
  const [displayChar, setDisplayChar] = useState('');
  const timestampRef = useRef(0);
  const animStartTimestampRef = useRef(0);
  const [animating, setAnimating] = useState(false);
  const fixTimerRef = useRef(0);

  useEffect(() => {
    clearTimeout(fixTimerRef.current);
    timestampRef.current = performance.now();
    animStartTimestampRef.current = performance.now();
    setAnimating(true);

    fixTimerRef.current = setTimeout(() => {
      setDisplayChar(char);
    }, 500);
    return () => {
      clearTimeout(fixTimerRef.current);
    };
  }, [char]);

  const animate = useCallback(
      (timestamp) => {
        if (!timestampRef.current) {
          timestampRef.current = timestamp;
          return;
        }
        if (!animStartTimestampRef.current) {
          animStartTimestampRef.current = timestamp;
          return;
        }
        let deltaFromStart = timestamp - animStartTimestampRef.current;
        let delta = timestamp - timestampRef.current;

        if (delta > 30) {
          timestampRef.current = timestamp;
          if (deltaFromStart > 300 + pos * Math.max(20, 150 - pos * 30)) {
            setDisplayChar(char);
            setAnimating(false);
          } else {
            setDisplayChar(Math.floor(Math.random() * 10) + '');
          }
        }
      },
      [char, pos, hash],
  );
  useAnimationFrame(animating, animate);

  return <span className="hc">{displayChar}</span>;
}

export default function HappyNumberLabel({text, ...props}) {
  const [charsInfo, setCharsInfo] = useState({
    chars: [],
    hash: 0,
  });

  useEffect(() => {
    let cs = [];
    for (let i = 0; i < text.length; i++) {
      cs.push(text[i]);
    }
    setCharsInfo({
      chars: cs,
      hash: Date.now(),
    });
  }, [text]);

  /*
  if (text == "0") {
    return (
      <HappyLabel className="happy" {...props}>
        <span>{text}</span>
      </HappyLabel>
    );
  }
  */

  const charsElms = () => {
    let pos = 0;

    //if (charsInfo.chars.length == 1 && charsInfo.chars[0] == "0") {
    //  return <span>{"0"}</span>;
    //}

    return charsInfo.chars.map((c, i) => {
      if (/[0-9]/.test(c)) {
        return <Char key={i} char={c} pos={pos++} hash={charsInfo.hash}/>;
      }
      return <span key={i}>{c}</span>;
    });
  };

  return (
      <HappyLabel className="happy" {...props}>
        {charsElms()}
      </HappyLabel>
  );
}
