import EventTargetPolyfill from './EventTarget';
import Cookies from 'js-cookie';

let instance = null;

//  this.dispatchEvent(new Event("login"));

export default class LoginManager extends EventTargetPolyfill {
  static getSharedInstance() {
    if (!instance) {
      instance = new LoginManager();
      instance.init();
    }
    return instance;
  }

  constructor() {
    super();
    if (this.accessToken) {
      this.keepLogin = !!(Cookies.get('keep_session') || null);
    } else {
      this.keepLogin = false;
      Cookies.remove('keep_session');
    }
  }

  init() {}

  isLoggedIn() {
    return !!this.accessToken;
  }

  get accessToken() {
    return Cookies.get('api_token') || null;
  }

  setKeepLogin(keep) {
    if (keep) {
      Cookies.set('keep_session', '1', {
        expires: 365 * 10,
        path: '/',
      });
      this.keepLogin = true;
    } else {
      Cookies.remove('keep_session');
      this.keepLogin = false;
    }
  }

  setAccessToken(expiresSecIn, token) {
    if (this.keepLogin) {
      Cookies.set('api_token', token, {
        expires: expiresSecIn / 3600 / 24,
        path: '/',
      });
    } else {
      Cookies.set('api_token', token, {
        path: '/',
      });
    }
  }

  removeAccessToken() {
    Cookies.remove('api_token');
    Cookies.remove('keep_session');
    this.keepLogin = false;
  }

  invalidTokenDetected() {
    this.removeAccessToken();
    this.dispatchEvent(new Event('invalid_token'));
  }
}
