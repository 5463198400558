import {useState, useRef, useEffect, useMemo} from 'react';

import {useSearchParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import clone from 'clone';

import {
  SummaryReportContextProvider,
  useSummaryReport,
} from '../../../contexts/SummaryReportContext';

import ContentHeader from '../../organisms/ContentHeader';
import SummaryReportGraph from './SummaryReportGraph';
import SummaryReportLocation from './SummaryReportLocation';
import SummaryReportTracks from './SummaryReportTracks';
import SummaryReportHours from './SummaryReportHours';
import SummaryReportVenues from './SummaryReportVenues';
import SummaryReportOffline from './SummaryReportOffline';
import SummaryDownloadButton from './SummaryDownloadButton';
import SummaryReportDisplayMenu from './SummaryReportDisplayMenu';
import ReportQuerySelector from '../../organisms/ReportQuerySelector';
import LabelWithInfo, {InfoLinePosition} from '../../atoms/LabelWithInfo';
import {useAuth} from '../../../contexts/UserContext';

const SelectorWrap = styled.div`
  display: flex;
`;

const PageBreak = styled.div`
  @media print {
    page-break-after: always;
  }
`;

export const SelectorItem = styled.div`
  margin-right: 20px;
`;

const StatsBlockWrap = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  @media print {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const StatsBlock = styled.div`
  flex: 1 1 50%;
  text-align: center;

  &:first-child {
    margin-right: 40px;

    @media print {
      margin-right: 10px;
    }
  }
`;

function getValidYearMonthRange() {
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;

  return {
    min: {
      year: 2017,
      month: 1,
      monthID: 2017 * 12 + 1,
    },
    max: {
      year,
      month,
      monthID: year * 12 + month,
    },
  };
}

function PeriodSelector({query, onChange}) {
  const onQueryChanged = (newQuery) => {
    onChange(clone(newQuery));
  };

  return (
      <SelectorItem>
        <LabelWithInfo
            text={'PERIOD'}
            infoPosition={InfoLinePosition.RightTop}
            style={{
              marginBottom: 20,
            }}
        >
          <div>Select the report term to display.</div>
        </LabelWithInfo>
        <ReportQuerySelector query={query} onChange={onQueryChanged}/>
      </SelectorItem>
  );
}

let defaultYear = new Date().getFullYear();
let defaultMonth = new Date().getMonth() + 1;
defaultMonth--;
if (defaultMonth == 0) {
  defaultMonth = 12;
  defaultYear--;
}

function DashboardPageContent() {
  const summaryReport = useSummaryReport();
  const auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  let [query, setQuery] = useState({
    type: 'monthly',
    year: defaultYear,
    month: defaultMonth,
    data: auth.getDataType(),
  });

  const changeTimerRef = useRef(null);

  let type = searchParams.get('type') || 'monthly';
  let year = searchParams.get('year') || 0;
  let month = searchParams.get('month') || 0;
  let data = auth.getDataType();

  if (type != 'monthly') {
    type = 'monthly';
  }
  if (year) {
    year = parseInt(year, 10);
    if (!year || year < 2017 || new Date().getFullYear() < year) {
      year = 0;
    }
  }
  if (month) {
    month = parseInt(month, 10);
    if (!month || month < 1 || 12 < month) {
      month = 0;
    }
  }
  let monthID = year * 12 + month;
  let validRange = getValidYearMonthRange();

  if (monthID < validRange.min.monthID) {
    year = defaultYear;
    month = defaultMonth;
  }

  if (query.type != type || query.year != year || query.month != month ||
      query.data != data) {
    setQuery({
      type,
      year,
      month,
      data,
    });
  }

  const updateParams = () => {
    if (
        query.year != searchParams.get('year') ||
        query.month != searchParams.get('month') ||
        query.data != auth.getDataType()
    ) {

      setSearchParams(
          {
            type: query.type,
            year: query.year,
            month: query.month,
            data: query.data,
          },
          {replace: !searchParams.get('year')},
      );
    }
  };
  useEffect(() => {
    updateParams();

    //console.log(summaryReport);
    if (changeTimerRef.current) {
      clearTimeout(changeTimerRef.current);
    }
    changeTimerRef.current = setTimeout(() => {
      summaryReport.doQuery(query);
    }, 100);
  }, [query]);

  useEffect(() => {
    document.title = 'Statistics Summary | Report | DASHBOARD';
  }, []);

  const onQueryChanged = (newQuery) => {
    console.log('new Query', newQuery);
    setSearchParams(
        {
          type: newQuery.type,
          year: newQuery.year,
          month: newQuery.month,
          data: query.data,
        },
        {replace: false},
    );
    setQuery({
      ...newQuery,
    });
  };

  return (
      <>
        <ContentHeader
            title={'REPORT'}
            subtitle={'STATISTICS SUMMARY'}
            rightTopElem={<SummaryDownloadButton/>}
            children={
              <SelectorWrap>
                <PeriodSelector query={query} onChange={onQueryChanged}/>
                <SummaryReportDisplayMenu/>
              </SelectorWrap>
            }
        />

        <SummaryReportGraph/>

        <StatsBlockWrap>
          <StatsBlock>
            <SummaryReportLocation/>
          </StatsBlock>
          <StatsBlock>
            <SummaryReportTracks/>
          </StatsBlock>
        </StatsBlockWrap>

        <StatsBlockWrap>
          <StatsBlock>
            <SummaryReportHours/>
          </StatsBlock>
          <StatsBlock>
            <SummaryReportVenues/>
          </StatsBlock>
        </StatsBlockWrap>

        <SummaryReportOffline/>
      </>
  );
}

export default function DashboardPage() {
  const auth = useAuth();

  if (!auth.state?.user?.cmo) {
    return '';
  }

  return (
      <SummaryReportContextProvider>
        <DashboardPageContent/>
      </SummaryReportContextProvider>
  );
}
